import React from "react";

export default function IconLop10() {
  return (
    <svg
      width="52"
      height="55"
      viewBox="0 0 52 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5 33.5089V21.2749C51.4998 18.6268 50.8075 16.0255 49.4927 13.7322C48.178 11.439 46.287 9.53466 44.0099 8.21063L33.4901 2.09362C31.213 0.768792 28.6297 0.0712891 26 0.0712891C23.3703 0.0712891 20.787 0.768792 18.5099 2.09362L7.99006 8.21063C5.71297 9.53466 3.82202 11.439 2.50725 13.7322C1.19249 16.0255 0.500212 18.6268 0.500002 21.2749V33.5089C0.498782 36.1573 1.19045 38.7592 2.50536 41.0527C3.82027 43.3462 5.71201 45.2503 7.99006 46.5732L18.5099 52.6973C20.7881 54.0187 23.371 54.7141 26 54.7141C28.629 54.7141 31.2119 54.0187 33.4901 52.6973L44.0099 46.5732C46.288 45.2503 48.1797 43.3462 49.4946 41.0527C50.8095 38.7592 51.5012 36.1573 51.5 33.5089Z"
        fill="url(#paint0_linear_2_31765)"
      />
      <g style={{ mixBlendMode: "screen" }} opacity="0.4">
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M13.7606 4.854L7.98978 8.21055C7.14392 8.70265 6.34853 9.27789 5.61523 9.92786L42.7343 47.3182L44.0097 46.5731C46.3367 45.2196 48.2575 43.2582 49.5691 40.8961L13.7606 4.854Z"
          fill="url(#paint1_linear_2_31765)"
        />
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M18.354 2.17871L15.7188 3.71151L50.5478 38.7814C50.947 37.7022 51.2235 36.5809 51.3722 35.439L18.354 2.17871Z"
          fill="url(#paint2_linear_2_31765)"
        />
      </g>
      <path
        d="M25.9999 42.223C34.1331 42.223 40.7263 35.5829 40.7263 27.3918C40.7263 19.2007 34.1331 12.5605 25.9999 12.5605C17.8667 12.5605 11.2734 19.2007 11.2734 27.3918C11.2734 35.5829 17.8667 42.223 25.9999 42.223Z"
        fill="white"
      />
      <path
        d="M22.3354 21.6888V34.0713H20.8359V23.2608H20.7634L17.7403 25.2681V23.7445L20.8359 21.6888H22.3354ZM29.9779 34.2406C29.067 34.2406 28.2911 33.9927 27.6502 33.4969C27.0093 32.9971 26.5195 32.2736 26.1809 31.3263C25.8424 30.3751 25.6731 29.2263 25.6731 27.88C25.6731 26.5418 25.8424 25.3991 26.1809 24.4519C26.5236 23.5006 27.0153 22.7751 27.6562 22.2753C28.3011 21.7714 29.075 21.5195 29.9779 21.5195C30.8808 21.5195 31.6527 21.7714 32.2936 22.2753C32.9385 22.7751 33.4303 23.5006 33.7689 24.4519C34.1115 25.3991 34.2828 26.5418 34.2828 27.88C34.2828 29.2263 34.1135 30.3751 33.7749 31.3263C33.4363 32.2736 32.9466 32.9971 32.3057 33.4969C31.6648 33.9927 30.8889 34.2406 29.9779 34.2406ZM29.9779 32.9104C30.8808 32.9104 31.5822 32.4751 32.082 31.6045C32.5818 30.7338 32.8317 29.4923 32.8317 27.88C32.8317 26.8079 32.7168 25.8949 32.4871 25.1411C32.2614 24.3874 31.9349 23.813 31.5076 23.418C31.0844 23.023 30.5745 22.8255 29.9779 22.8255C29.0831 22.8255 28.3838 23.2668 27.8799 24.1496C27.3761 25.0283 27.1241 26.2718 27.1241 27.88C27.1241 28.9522 27.237 29.8632 27.4627 30.6129C27.6885 31.3626 28.0129 31.933 28.4362 32.324C28.8634 32.7149 29.3773 32.9104 29.9779 32.9104Z"
        fill="#606060"
      />
      <g style={{ mixBlendMode: "screen" }}>
        <path
          style={{ mixBlendMode: "screen" }}
          d="M25.9992 51.519C23.9267 51.5191 21.8905 50.971 20.0945 49.9294L9.57459 43.8124C7.78166 42.7649 6.29286 41.2622 5.25677 39.4542C4.22067 37.6462 3.67352 35.5961 3.66992 33.5086V21.2746C3.67263 19.1878 4.21947 17.1384 5.25571 15.3314C6.29196 13.5243 7.78126 12.0231 9.57459 10.9779L20.0945 4.86085C21.897 3.83873 23.9306 3.30176 25.9992 3.30176C28.0677 3.30176 30.1014 3.83873 31.9038 4.86085L42.4237 10.9779C44.2171 12.0231 45.7064 13.5243 46.7426 15.3314C47.7788 17.1384 48.3257 19.1878 48.3284 21.2746V33.5086C48.3248 35.5961 47.7776 37.6462 46.7415 39.4542C45.7055 41.2622 44.2167 42.7649 42.4237 43.8124L31.9038 49.9294C30.1082 50.9719 28.0718 51.5201 25.9992 51.519V51.519ZM25.9992 3.97381C24.051 3.97466 22.137 4.48838 20.4468 5.46403L9.9269 11.5881C8.24109 12.5708 6.84099 13.9821 5.86662 15.6807C4.89224 17.3793 4.37773 19.3058 4.37454 21.2675V33.5015C4.3765 35.4644 4.89042 37.3923 5.86485 39.0923C6.83929 40.7923 8.24006 42.2046 9.9269 43.1879L20.4468 49.3049C22.1356 50.2844 24.0503 50.8 25.9992 50.8C27.948 50.8 29.8627 50.2844 31.5515 49.3049L42.0714 43.1879C43.7583 42.2046 45.159 40.7923 46.1335 39.0923C47.1079 37.3923 47.6218 35.4644 47.6238 33.5015V21.2746C47.6206 19.3129 47.1061 17.3864 46.1317 15.6878C45.1573 13.9892 43.7572 12.5779 42.0714 11.5952L31.5515 5.47113C29.8613 4.49548 27.9473 3.98176 25.9992 3.98091V3.97381Z"
          fill="url(#paint3_linear_2_31765)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_31765"
          x1="7.10929"
          y1="8.36675"
          x2="45.1587"
          y2="46.1472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B06AB3" />
          <stop offset="1" stopColor="#4568DC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_31765"
          x1="15612.3"
          y1="6318.86"
          x2="18354.2"
          y2="6318.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4568DC" />
          <stop offset="1" stopColor="#B06AB3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_31765"
          x1="13186.5"
          y1="5155.84"
          x2="14990.5"
          y2="5155.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4568DC" />
          <stop offset="1" stopColor="#B06AB3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_31765"
          x1="15737.3"
          y1="7262.4"
          x2="18567.7"
          y2="7262.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4568DC" />
          <stop offset="1" stopColor="#B06AB3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
