import moment from "moment";
import { SagaStore } from "../../../app/store";
// import { getAuthorize, signIn } from "../../features/auth/utils/authAPI";
import { commonActions } from "../../features/common/redux/actions";
import { TParamsSignIn } from "../../features/Default/layout/type";
import { genSignature, getNonce } from "../helpers/helpers";
import { getAuthorizeSSR, signInSSR } from "../../../pages/api/auth";
import { DebuggerMessage } from "../constants/constants";
import { getConfigServer } from "../constants/env";

// eslint-disable-next-line consistent-return
const actionSignIn = async (store, authCode) => {
  const CONFIG_SERVER: any = await getConfigServer();
  const headers = {
    Authorization: `Bearer ${authCode}`
  };
  const paramsSignatureSignIn: Partial<TParamsSignIn> = {
    
    app_version: process.env.NEXT_PUBLIC_VERSION || "",
    ci: CONFIG_SERVER.ci,
    language: CONFIG_SERVER.language,
    timezone: CONFIG_SERVER.timezone,
    timestamp: moment().unix(),
    nonce: getNonce(),
    platform: CONFIG_SERVER.platform,
    os_version: CONFIG_SERVER.os_version
  };
  const params = {
    ...paramsSignatureSignIn,
    signature: genSignature(paramsSignatureSignIn)
  };
  try {
    const response = await signInSSR(store, params, headers);
    if (response?.data) {
      const accessToken = response?.data?.data?.token?.access_token;
      // store.dispatch(commonActions.handleSaveAccessToken(accessToken));
      // store.dispatch(commonActions.handleSaveRefreshToken(response?.data?.data?.token?.ref_token));
      store.dispatch(commonActions.addUserInfos(response?.data?.data));
      return accessToken;
    }
  } catch (err) {}
};

const authService = async (store: SagaStore) => {
  const CONFIG_SERVER: any = await getConfigServer();

  let accessToken = "";
  try {
    const paramsSignature = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      ci: CONFIG_SERVER.ci,
      platform: CONFIG_SERVER.platform,
      app_version: process.env.NEXT_PUBLIC_VERSION
    };
    const params = {
      ...paramsSignature,
      signature: genSignature(paramsSignature)
    };
    const response = await getAuthorizeSSR(store, params);
    if (response?.data) {
      DebuggerMessage("getAuthorizeSSR SUCCESS", "red");
      accessToken = await actionSignIn(store, response?.data?.data?.auth_code);
      store.dispatch(commonActions.handleSaveAuthCode(response?.data?.data?.auth_code));
    }
  } catch (err) {
    store.dispatch({
      type: "GET_AUTHORIZE_FAILED",
      params: err?.data?.data?.error
    });
  }
  return {
    accessToken
  };
};

export default authService;
