/* eslint-disable default-param-last */

import { TStateRotation } from "../utils/types";
import { ACTION_ROTATION } from "./actionTypes";

/* eslint-disable no-unused-vars */
export const initialStateRotations: TStateRotation = {
  dataWheel: {
    id: 0,
    title: "",
    description: "",
    valid_from: 0,
    valid_to: 0,
    background: "",
    image_under: "",
    image_upper: "",
    image_arrow: "",
    display_from: 0,
    display_to: 0,
    num_of_turn_left: 0,
    items: [],
    turn_button: {
      text: "",
      background: "",
      color_text: "",
      font_family: "",
      font_size: "",
      font_weight: ""
    },
    media_zip: ""
  },
  isLoading: false,
  notifyError: {
    isOpenDialog: false,
    message: "",
    errorCode: ""
  },
  startResponseWheel: { id: 0, title: "", image: "", success_message: "", num_of_turn_left: 0 }
};

const rotationsReducer = (state = initialStateRotations, action) => {
  switch (action?.type) {
    case ACTION_ROTATION.GET_DATA_ROTATION_SUCCESS: {
      return {
        ...state,
        dataWheel: action?.params?.lucky_circle_data,
        isLoading: false
      };
    }
    case ACTION_ROTATION.GET_DATA_ROTATION: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_ROTATION.START_WHEEL_SUCCESS: {
      return {
        ...state,
        startResponseWheel: action?.params
      };
    }
    case ACTION_ROTATION.START_WHEEL_FAILED: {
      return {
        ...state,
        notifyError: {
          isOpenDialog: true,
          errorCode: action?.params?.code,
          message: action?.params?.message
        },
        startResponseWheel: {}
      };
    }
    case ACTION_ROTATION.CLOSE_NOTIFY: {
      return {
        ...state,
        notifyError: {
          isOpenDialog: false,
          message: "",
          errorCode: ""
        }
      };
    }
    case ACTION_ROTATION.GET_DATA_ROTATION_FAILED: {
      return {
        ...state,
        notifyError: {
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        },
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default rotationsReducer;
