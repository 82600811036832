/* eslint-disable default-param-last */
import { THomeState } from "../utils/types";
import { ACTION_TYPES_HOME } from "./actionTypes";

export const initialStateHome: THomeState = {
  giftCodes: [],
  notifyHome: {
    type: "",
    message: "",
    code: ""
  }
};

const homeReducer = (state = initialStateHome, action) => {
  switch (action?.type) {
    case ACTION_TYPES_HOME.GET_GIFT_CODE_SUCCESS: {
      return {
        ...state,
        giftCodes: action?.params?.gift_codes
      };
    }
    case ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE_SUCCESS: {
      return {
        ...state,
        notifyHome: {
          type: "HANDLE_TAKE_GIFT_CODE_SUCCESS",
          message: action?.params.success_message
        }
      };
    }
    case ACTION_TYPES_HOME.CLEAR_NOTIFY: {
      return {
        ...state,
        notifyHome: {
          type: "",
          message: ""
        }
      };
    }
    case ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE_FAILED: {
      return {
        ...state,
        notifyHome: {
          type: "HANDLE_TAKE_GIFT_CODE_FAILED",
          message: action?.params?.message,
          code: action?.params?.code
        }
      };
    }
    default:
      return state;
  }
};

export default homeReducer;
