import { ACTION_TYPES } from "./actionTypes";

const handleGetGiftItem = () => {
  return {
    type: ACTION_TYPES.GET_GIFT_ITEM,
  };
};

const handleGetGiftItemSuccess = (params: any) => {
  return {
    type: ACTION_TYPES.GET_GIFT_ITEM_SUCCESS,
    params
  }
}

const handleGetGiftItemDetail = (params: any) => {
  return {
    type: ACTION_TYPES.GET_GIFT_ITEM_DETAIL,
    params
  };
};

const handleGetGiftItemDetailSuccess = (params: any) => {
  return {
    type: ACTION_TYPES.GET_GIFT_ITEM_DETAIL_SUCCESS,
    params
  }
}

const handleGetGiftItemExchange = (params: any) => {
  return {
    type: ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE,
    params
  };
};

const handleGetGiftItemExchangeSuccess = (params: any) => {
  return {
    type: ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE_SUCCESS,
    params
  }
}

const handleGetGiftItemHistory = () => {
  return {
    type: ACTION_TYPES.GET_GIFT_ITEM_HISTORY,
  };
};

const handleClearNotify = () => {
  return {
    type: ACTION_TYPES.CLEAR_NOTIFY,
  };
};
const handleCleanGiftItemExchange = () => {
  return {
    type: ACTION_TYPES.CLEAN_GIFT_ITEM_EXCHANGE
  }
}
const handleCleanGiftItemDetail = () => {
  return {
    type: ACTION_TYPES.CLEAN_GIFT_ITEM_DETAIL
  }
}

export const giftAction = {
  handleGetGiftItem,
  handleGetGiftItemSuccess,
  handleGetGiftItemHistory,
  handleClearNotify, 
  handleGetGiftItemDetail,
  handleGetGiftItemDetailSuccess,
  handleGetGiftItemExchange,
  handleGetGiftItemExchangeSuccess,
  handleCleanGiftItemExchange,
  handleCleanGiftItemDetail
};
