/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
import * as locales from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ThemeProvider from "@mui/styles/ThemeProvider";
import { MathJaxContext } from "better-react-mathjax";
import { getCookie, setCookie } from "cookies-next";
import mediaQuery from "css-mediaquery"; // 2.
import isEmpty from "lodash/isEmpty";
import { DefaultSeo } from "next-seo";
import App from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { END } from "redux-saga";
import parser from "ua-parser-js";
import { wrapper } from "../app/store";
import "../components/features/Default/layout/style.module.scss";
import { configMathJax } from "../components/features/topics/utils/constants";
import Firebase from "../components/firebase/Firebase";
import LocaleProvider from "../components/utils/localeProvider/LocaleProvider";
import "../styles/common.scss";
import "../styles/globals.css";
// import { THEME } from "../components/utils/configs/setupTheme";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import { appMetaDescription, domainUrl } from "../components/utils/constants/env";
import authService from "../components/utils/auth/authService";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { accountActions } from "../components/features/account/redux/actions";
import { arenaRoomActions } from "../components/features/arena/redux/actions";
import { AuthProvider } from "../components/features/common/hooks/useAuth";
import { commonActions } from "../components/features/common/redux/actions";
import { questionAndAnswerAction } from "../components/features/questionAndAnswer/redux/actions";
import { quizActions } from "../components/features/topics/redux/actions";
import { theme } from "../components/utils/configs/setupTheme";
import { LOCALES, appName } from "../components/utils/constants/constants";

const CountdownRedirectPage = dynamic(() => import("../components/utils/components/CountdownRedirectPage"));

const WHITE_LIST_DOMAIN = ["chinhxac.vn", "thitot.vn"];

const MyApp = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);

  const { deviceType, pageProps, isRedirectPage, isSiteFake } = props;
  const router = useRouter();
  const mobile = useMediaQuery("(max-width:480px)");

  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: deviceType === "mobile" ? "320px" : "1100px"
    })
  });

  const muiTheme = createTheme(
    {
      ...theme,
      components: {
        ...theme.components,
        MuiUseMediaQuery: {
          defaultProps: {
            ssrMatchMedia
          }
        }
      }
    },
    locales[LOCALES]
  );

  const app = (
    <ThemeProvider theme={muiTheme}>
      <MathJaxContext config={configMathJax}>
        <AuthProvider>
          <DefaultSeo
            openGraph={{
              type: "website",
              url: "thitot.vn",
              site_name: appName,
              title: `${appName} - Học, Thi Đấu online`,
              description: appMetaDescription
            }}
          />
          <Component {...pageProps} key={router.route} />
          <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar
            closeOnClick
            pauseOnHover
            draggable
            className={`h-6 w-max whitespace-nowrap ${mobile && "w-[100%] right-0 left-0 text-[13px]"}`}
            bodyClassName="text-center p-0 m-0"
            toastClassName="bg-[#2E7D32] text-white px-2 py-1 h-10 flex items-center"
            limit={1}
          />
        </AuthProvider>
      </MathJaxContext>
    </ThemeProvider>
  );

  if (isRedirectPage) {
    return <CountdownRedirectPage />;
  }
  if (isSiteFake) {
    return <h2>Website giả mạo</h2>;
  }

  return (
    <Provider store={store}>
      <LocaleProvider>
        <Firebase>
          {typeof window === "undefined" ? (
            app
          ) : (
            <PersistGate loading={null} persistor={store.__persistor}>
              {app}
            </PersistGate>
          )}
        </Firebase>
      </LocaleProvider>
    </Provider>
  );
};
MyApp.getInitialProps = wrapper.getInitialPageProps((store) => async (context: any) => {
  const { ctx } = context;
  const { req, res } = ctx;
  const deviceType = parser(ctx?.req?.headers["user-agent"])?.device?.type || "desktop";
  const pageProps = {
    // https://nextjs.org/docs/advanced-features/custom-app#caveats
    ...(await App.getInitialProps(context as any)).pageProps
  };

  const accessTokenCookies = JSON.parse((getCookie("accessToken", { req, res }) as string) || "{}");
  const refreshTokenCookies = JSON.parse((getCookie("refreshToken", { req, res }) as string) || "{}");
  const userInfo = JSON.parse((getCookie("userInfo", { req, res }) as string) || "{}");
  const ServerError = JSON.parse((getCookie("ServerError", { req, res }) as string) || "{}");

  // clear all notify
  store.dispatch(commonActions.deleteNotify());
  store.dispatch(arenaRoomActions.handleDeleteNotify());
  store.dispatch(accountActions.handleClearNotify());
  store.dispatch(quizActions.handleCleanNotify());
  store.dispatch(questionAndAnswerAction.handleCloseNotify());

  if (userInfo !== store?.getState()?.commonNew?.userInfos) {
    store.dispatch(commonActions.addUserInfos(userInfo));
  }

  if (!isEmpty(userInfo?.token?.access_token)) {
    setCookie("accessToken", JSON.stringify(userInfo?.token?.access_token), { req, res });
    setCookie("refreshToken", JSON.stringify(userInfo?.token?.ref_token), { req, res });
    store.dispatch(commonActions.handleSaveAccessToken(userInfo?.token?.access_token));
    store.dispatch(commonActions.handleSaveRefreshToken(userInfo?.token?.ref_token));
  } else if (!isEmpty(accessTokenCookies) && isEmpty(ServerError)) {
    store.dispatch(commonActions.handleSaveAccessToken(accessTokenCookies));
    store.dispatch(commonActions.handleSaveRefreshToken(refreshTokenCookies));
  } else {
    setCookie("ServerError", "{}");
    Promise.all([await authService(store)]).then((value: any) => {
      setCookie("accessToken", JSON.stringify(store?.getState()?.commonNew?.accessToken), { req, res });
      setCookie("userInfo", JSON.stringify(store?.getState()?.commonNew?.userInfos), { req, res });
    });
  }

  const gradeCookie = JSON.parse((ctx?.req?.cookies.grade as string) || "{}");
  if (!isEmpty(gradeCookie) && gradeCookie !== store?.getState()?.commonNew?.grade) {
    store.dispatch(commonActions.handleChooseGrade(gradeCookie));
  }
  if (isEmpty(gradeCookie)) {
    setCookie("grade", JSON.stringify(store?.getState()?.commonNew?.grade), { req, res });
  }

  if (context?.ctx?.req) {
    store.dispatch(END);
    await store?.sagaTask.toPromise();
  }

  if (req?.headers?.host?.includes("chinhxac.vn")) {
    return {
      ...pageProps,
      isRedirectPage: true
    };
  }
  // check site fake
  if (
    req &&
    !WHITE_LIST_DOMAIN.some((e) => req?.headers?.host?.includes(e)) &&
    !req?.headers?.host?.includes("localhost")
  ) {
    console.log("req?.headers?.host", req?.headers?.host);

    return {
      ...pageProps,
      isSiteFake: true
    };
  }
  return {
    ...pageProps,
    deviceType
  };
});
export default wrapper.withRedux(MyApp);
