import api from "../../../utils/helpers/api";

const getInvitationFriend = () => {
  const option = {
    method: "get",
    url: "friends/invite"
  };
  return api(option);
};
const searchFriend = (params) => {
  const option = {
    method: "get",
    url: "friends/search",
    params
  };
  return api(option);
};

const inviteFriend = (data) => {
  return api({
    method: "post",
    url: `friends/${data?.member_hid}/invite`,
    data
  });
};
const deleteInvitation = (data) => {
  return api({
    method: "delete",
    url: `friends/${data?.member_hid}/invite`,
    data
  });
};
const acceptFriend = (data) => {
  return api({
    method: "post",
    url: `friends/${data?.invitation_id}/accept`,
    data
  });
};
const declineFriendRequest = (data) => {
  return api({
    method: "delete",
    url: `friends/${data?.invitation_id}/accept`,
    data
  });
};
const removeFriend = (data) => {
  return api({
    method: "delete",
    url: `friends/${data?.member_hid}/remove`,
    data
  });
};
const followFriend = (data) => {
  return api({
    method: "post",
    url: `friends/${data?.member_hid}/follow`,
    data
  });
};
const deleteFollow = (data) => {
  return api({
    method: "delete",
    url: `friends/${data?.member_hid}/follow`,
    data
  });
};
const getListFriend = (params) => {
  return api({
    method: "get",
    url: `members/${params.member_hid}/friends`,
    params
  });
};
export const friendServices = {
  getInvitationFriend,
  searchFriend,
  inviteFriend,
  deleteInvitation,
  followFriend,
  deleteFollow,
  acceptFriend,
  removeFriend,
  declineFriendRequest,
  getListFriend
};
