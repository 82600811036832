/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plusplus */
import { TypeListSubject } from "./type";

export const getItemDuplicate = (arr: any[]) => {
  return arr.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  );
};
export const getListSubject = (listSubjects: TypeListSubject) => {
  const listSubject = listSubjects?.map((item) => item?.subject);
  let listBook: any = [];
  if (listSubject?.length > 0) {
    listBook = getItemDuplicate(listSubject);
  }
  return listBook;
};

export const ACCESS_LEVEL = {
  NOT_LOGIN: 0,
  LOGIN: 1,
  PREMIUM: [2, 3]
};

export const splitFirstTwoWordsSlug = (slug) => {
  return slug?.split("-")?.slice(0, 2)?.join("-");
};