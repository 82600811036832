import React from "react";

export default function IconLop7() {
  return (
    <svg
      width="52"
      height="55"
      viewBox="0 0 52 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5 33.5099V21.2755C51.4998 18.6274 50.8079 16.0259 49.4939 13.7326C48.1798 11.4393 46.2899 9.53495 44.0141 8.21087L33.5 2.09368C31.2242 0.768807 28.6423 0.0712891 26.0141 0.0712891C23.3858 0.0712891 20.8039 0.768807 18.5282 2.09368L8.01408 8.21087C5.73301 9.53135 3.83747 11.4342 2.51834 13.7277C1.19921 16.0213 0.503063 18.6246 0.500002 21.2755V33.5099C0.498783 36.1584 1.19007 38.7604 2.50425 41.0539C3.81844 43.3475 5.70913 45.2516 7.98592 46.5746L18.5 52.6918C20.7758 54.0166 23.3577 54.7141 25.9859 54.7141C28.6142 54.7141 31.1961 54.0166 33.4718 52.6918L43.9859 46.5746C46.2679 45.2552 48.1643 43.3526 49.4835 41.0588C50.8028 38.765 51.4984 36.1611 51.5 33.5099V33.5099Z"
        fill="url(#paint0_linear_2_31739)"
      />
      <g style={{ mixBlendMode: "screen" }} opacity="0.4">
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M13.7815 4.85449L8.01386 8.21115C7.16814 8.70274 6.37314 9.27803 5.64062 9.9285L42.7533 47.32L44.0209 46.5748C46.3475 45.2225 48.2675 43.2607 49.5772 40.8976L13.7815 4.85449Z"
          fill="url(#paint1_linear_2_31739)"
        />
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M18.3721 2.18604L15.7383 3.71888L50.5481 38.7898C50.9511 37.7094 51.2276 36.5853 51.3721 35.4403L18.3721 2.18604Z"
          fill="url(#paint2_linear_2_31739)"
        />
      </g>
      <path
        d="M26.0132 42.2235C34.1419 42.2235 40.7315 35.5831 40.7315 27.3918C40.7315 19.2004 34.1419 12.5601 26.0132 12.5601C17.8845 12.5601 11.2949 19.2004 11.2949 27.3918C11.2949 35.5831 17.8845 42.2235 26.0132 42.2235Z"
        fill="white"
      />
      <path
        d="M22.8051 34.0713L28.3434 23.1157V23.0189H21.9586V21.6888H29.8912V23.0915L24.3771 34.0713H22.8051Z"
        fill="#606060"
      />
      <g style={{ mixBlendMode: "screen" }}>
        <path
          style={{ mixBlendMode: "screen" }}
          d="M26.0142 51.5209C23.9428 51.5211 21.9078 50.9729 20.1128 49.9313L9.59868 43.8141C7.80674 42.7666 6.31876 41.2638 5.28323 39.4557C4.24771 37.6477 3.70086 35.5976 3.69727 33.51V21.2756C3.69997 19.1888 4.24651 17.1393 5.28218 15.3322C6.31785 13.5251 7.80633 12.0238 9.59868 10.9786L20.1128 4.8614C21.9142 3.83924 23.9468 3.30225 26.0142 3.30225C28.0816 3.30225 30.1141 3.83924 31.9156 4.8614L42.4297 10.9786C44.222 12.0238 45.7105 13.5251 46.7462 15.3322C47.7818 17.1393 48.3284 19.1888 48.3311 21.2756V33.51C48.3275 35.5976 47.7806 37.6477 46.7451 39.4557C45.7096 41.2638 44.2216 42.7666 42.4297 43.8141L31.9156 49.91C30.123 50.96 28.0876 51.5156 26.0142 51.5209V51.5209ZM26.0142 3.97433C24.0667 3.97186 22.1529 4.48582 20.4649 5.46459L9.95079 11.5889C8.26584 12.5733 6.86664 13.9859 5.89288 15.6857C4.91912 17.3855 4.40487 19.3129 4.40149 21.2756V33.51C4.40487 35.4727 4.91912 37.4001 5.89288 39.0999C6.86664 40.7996 8.26584 42.2123 9.95079 43.1967L20.4649 49.3139C22.1528 50.2934 24.0664 50.809 26.0142 50.809C27.962 50.809 29.8756 50.2934 31.5635 49.3139L42.0494 43.1967C43.7343 42.2123 45.1335 40.7996 46.1073 39.0999C47.0811 37.4001 47.5953 35.4727 47.5987 33.51V21.2756C47.5953 19.3129 47.0811 17.3855 46.1073 15.6857C45.1335 13.9859 43.7343 12.5733 42.0494 11.5889L31.5353 5.4717C29.856 4.4968 27.9522 3.98293 26.0142 3.98143V3.97433Z"
          fill="url(#paint3_linear_2_31739)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_31739"
          x1="7.1338"
          y1="8.36699"
          x2="45.1841"
          y2="46.1264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BE3CC" />
          <stop offset="1" stopColor="#4551B6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_31739"
          x1="10783.3"
          y1="10639.5"
          x2="13524.1"
          y2="10639.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4551B6" />
          <stop offset="1" stopColor="#5BE3CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_31739"
          x1="9267.69"
          y1="8880.06"
          x2="11070.8"
          y2="8880.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4551B6" />
          <stop offset="1" stopColor="#5BE3CC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_31739"
          x1="10829.2"
          y1="12168.4"
          x2="13658.1"
          y2="12168.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4551B6" />
          <stop offset="1" stopColor="#5BE3CC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
