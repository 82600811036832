/* eslint-disable default-param-last */
import { HYDRATE } from "next-redux-wrapper";
import isEmpty from "lodash/isEmpty";
import { ACTION_TYPES } from "./actionTypes";
import { IQuizState } from "../utils/type";

export const initialStateQuiz: IQuizState = {
  isLoading: false,
  quizStartTime: 0,
  action: {
    type: ""
  },
  notify: {
    status: "error",
    message: "",
    errorCode: "",
    isOpenNotify: false,
    isOpenDialog: false
  },
  practice: {
    id: 0,
    title: "",
    subject: {
      id: 0,
      title: ""
    },
    opening_view_type: "",
    quiz_info_token: "",
    count: 0,
    time: 0,
    quiz_question_group_id: 0,
    items: [
      {
        id: 0,
        questions: [
          {
            content: ""
          }
        ],
        options: [
          {
            id: 0,
            items: [
              {
                content: ""
              }
            ]
          }
        ]
      }
    ]
  },
  lessonData: {
    id: 0,
    title: "",
    subject: {
      id: 0,
      title: ""
    },
    opening_view_type: "",
    items: [
      {
        content: ""
      }
    ],
    topicId: ""
  },
  quizInfoStart: {
    time: 0,
    opening_view_type: "",
    question_ids: [],
    quiz_start_token: ""
  },
  quizInfoSubmit: {
    spent_time: 0,
    progress: {
      finished_quiz: 0,
      correct_quiz: 0,
      ignored_quiz: 0,
      skipped_quiz: 0,
      spent_time: 0
    },
    answers: [
      {
        question_id: 0,
        correct: false
      }
    ]
  },
  quizInfoSolution: {
    analytics: {
      finished: 0,
      correct: 0,
      skipped: 0,
      incorrect: 0
    },
    solutions: [
      {
        quiz_question_id: 0,
        items: []
      }
    ],
    results: [
      {
        quiz_question_id: 0,
        answer_type: 0,
        correct: 0,
        skipped: 0,
        correct_options: []
      }
    ],
    is_show_solution: false
  },
  dataAnalysis: {},
  answerTotal: [],
  congratulation: {
    success_message: ""
  }
};

const initErrorNotify = (action: any) => {
  return {
    status: "error",
    message: action?.params?.message || null,
    errorCode: action?.params?.code || null,
    isOpenNotify: false,
    isOpenDialog: true
  };
};
const quizReducer = (state = initialStateQuiz, action: any) => {
  switch (action?.type) {
    case ACTION_TYPES.GET_LESSON_DETAIL: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_LESSON_DETAIL_SUCCESS: {
      return {
        ...state,
        lessonData: { ...action?.params?.latest_data, topicId: action?.params?.topicId },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_LESSON_DETAIL_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "GET_LESSON_DETAIL_FAILED"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_EXAM_DETAIL: {
      return {
        ...state,
        isLoading: true,
        progress: {},
        quizInfoSubmit: [],
        quizInfoSolution: [],
        practice: [],
        dataAnalysis: {},
        notify: initialStateQuiz.notify
      };
    }
    case ACTION_TYPES.GET_EXAM_DETAIL_SUCCESS: {
      return {
        ...state,
        practice: action?.params?.latest_data || null,
        progress: action?.params?.progress
          ? { ...action?.params?.progress, topicId: action?.params?.latest_data.id }
          : null,
        action: {
          type: "GET_QUIZ_QUESTIONS_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.GET_EXAM_DETAIL_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "GET_EXAM_DETAIL_FAILED"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_PRACTICE_DETAIL: {
      return {
        ...state,
        isLoading: true,
        progress: [],
        quizInfoSubmit: [],
        quizInfoSolution: [],
        practice: [],
        dataAnalysis: {},
        notify: initialStateQuiz.notify
      };
    }
    case ACTION_TYPES.GET_PRACTICE_DETAIL_SUCCESS: {
      return {
        ...state,
        practice: action?.params?.latest_data,
        progress: action?.params?.progress
          ? { ...action?.params?.progress, topicId: action?.params?.latest_data.id }
          : null,
        isLoading: false,
        action: {
          type: "GET_QUIZ_QUESTIONS_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.GET_PRACTICE_DETAIL_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "GET_PRACTICE_DETAIL_FAILED"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.POST_QUIZ_START_CORE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.POST_QUIZ_START_CORE_SUCCESS: {
      return {
        ...state,
        quizInfoStart: action?.params || initialStateQuiz.quizInfoStart,
        action: {
          type: "POST_QUIZ_START_CORE_SUCCESS"
        },
        quizInfoSolution: initialStateQuiz.quizInfoSolution
      };
    }
    case ACTION_TYPES.POST_QUIZ_START_CORE_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "POST_QUIZ_START_CORE_FAILED"
        }
      };
    }
    case ACTION_TYPES.PATCH_QUIZ_START_SUCCESS: {
      return {
        ...state,
        quizInfoStart: action?.params || initialStateQuiz.quizInfoStart
      };
    }
    case ACTION_TYPES.PATCH_QUIZ_START_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "PATCH_QUIZ_START_FAILED"
        }
      };
    }
    case ACTION_TYPES.PUT_QUIZ_START_SUCCESS: {
      return {
        ...state,
        quizInfoStart: action?.params || initialStateQuiz.quizInfoStart,
        action: {
          type: "PUT_QUIZ_START_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.PUT_QUIZ_START_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "PUT_QUIZ_START_FAILED"
        }
      };
    }
    case ACTION_TYPES.QUIZ_SUBMIT: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_QUIZ_SOLUTION: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.QUIZ_SUBMIT_SUCCESS: {
      return {
        ...state,
        quizInfoSubmit: action?.params,
        progress: action?.params?.progress ? { ...action?.params?.progress, topicId: action?.topicId } : null,
        congratulation: action?.params?.congratulation,
        answerTotal: [],
        quizStartTime: 0,
        notify: {
          type: "SUBMIT_QUIZ_SUCCESS"
        },
        action: {
          type: "SUBMIT_QUIZ_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.QUIZ_SUBMIT_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "QUIZ_SUBMIT_FAILED"
        }
      };
    }
    case ACTION_TYPES.GET_QUIZ_SOLUTION_SUCCESS: {
      return {
        ...state,
        quizInfoSolution: action?.params,
        action: {
          type: "GET_QUIZ_SOLUTION_SUCCESS"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_QUIZ_SOLUTION_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "GET_QUIZ_SOLUTION_FAILED"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.UPDATE_QUIZ_INFO_SOLUTION: {
      return {
        ...state,
        quizInfoSolution: action?.params
      };
    }
    case ACTION_TYPES.GET_QUIZ_ANALYSIS_SUCCESS: {
      return {
        ...state,
        dataAnalysis: action?.params
      };
    }
    case ACTION_TYPES.CLEAN_QUIZ_INFO: {
      return {
        ...state,
        quizInfoStart: initialStateQuiz.quizInfoStart,
        quizInfoSubmit: initialStateQuiz.quizInfoSubmit,
        quizInfoSolution: initialStateQuiz.quizInfoSolution,
        dataAnalysis: initialStateQuiz.dataAnalysis,
        progress: null,
        answerTotal: initialStateQuiz.answerTotal,
        // practice: {},
        notify: initialStateQuiz.notify
      };
    }
    case ACTION_TYPES.SAVE_ANSWER_TOTAL: {
      return {
        ...state,
        answerTotal: action?.params || []
      };
    }
    case ACTION_TYPES.CLEAN_NOTIFY: {
      return {
        ...state,
        notify: initialStateQuiz.notify
      };
    }
    case ACTION_TYPES.SAVE_QUIZ_START_TIME: {
      return {
        ...state,
        quizStartTime: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.CLEAN_CONGRATULATION: {
      return {
        ...state,
        congratulation: {}
      };
    }
    case ACTION_TYPES.REPORT_QUIZ: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ACTION_TYPES.REPORT_QUIZ_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "REPORT_QUIZ_SUCCESS"
        },
        action: {
          type: "REPORT_QUIZ_SUCCESS"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.REPORT_QUIZ_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "REPORT_QUIZ_FAILED"
        },
        isLoading: false
      };
    }
    case HYDRATE: {
      const serverState = action?.payload?.quizCore;
      return {
        ...state,
        practice: serverState?.practice,
        progress: !isEmpty(serverState?.action?.type) ? serverState.progress : state?.progress || null,
        notify: !isEmpty(serverState?.notify?.errorCode) ? serverState?.notify : state.notify,
        lessonData: serverState?.lessonData,
        action: !isEmpty(serverState?.action?.type) ? serverState?.action : state?.action
      };
    }
    default:
      return state;
  }
};
export default quizReducer;
