import { TSearchScoreState } from "../utils/types";
import { ACTION_TYPES } from "./actionTypes";

/* eslint-disable default-param-last */
export const initialStateSearchScore: TSearchScoreState = {
  examineeScore: null,
  error: null
};

const searchScoreReducer = (state = initialStateSearchScore, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CANDIDATE_SCORE_SUCCESS:
      return {
        ...state,
        examineeScore: action.params,
        error: null
      };
    case ACTION_TYPES.GET_CANDIDATE_SCORE_FAILED:
      return {
        ...state,
        examineeScore: null,
        error: action.params
      };
    default:
      return state;
  }
};

export default searchScoreReducer;
