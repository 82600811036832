/* eslint-disable default-param-last */
import { HYDRATE } from "next-redux-wrapper";
import { TNewsState } from "../utils/type";
import { ACTION_TYPES_NEW } from "./actionType";

export const initialStateNews: TNewsState = {
  newsDetail: {
    id: 0,
    title: "",
    content: "",
    description: "",
    image_url: "",
    post_url: "",
    category: "",
    tags: [],
    references: [],
    slug: ""
  },
  isLoading: false,
  notify: {
    isOpenDialog: false,
    message: "",
    errorCode: ""
  }
};

const newsReducer = (state = initialStateNews, action) => {
  switch (action?.type) {
    case ACTION_TYPES_NEW.GET_NEWS_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newsDetail: action.params?.post || null
      };
    case ACTION_TYPES_NEW.GET_NEWS_DETAIL:
      return {
        ...state,
        isLoading: true
      };
    case ACTION_TYPES_NEW.GET_NEWS_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        notify: {
          isOpenDialog: true,
          message: action?.params?.message || null,
          errorCode: action?.params?.code || null
        }
      };
    case ACTION_TYPES_NEW.CLEAR_NOTIFY:
      return {
        ...state,
        notify: {
          isOpenDialog: false,
          message: "",
          errorCode: ""
        }
      };
    case HYDRATE: {
      return {
        ...state,
        newsDetail: action?.payload?.newsState?.newsDetail || null,
        notify: action?.payload?.newsState?.notify || null
      };
    }
    default:
      return state;
  }
};

export default newsReducer;
