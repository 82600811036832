import api from "../../../utils/helpers/api";

const getTopicBySearch = (params) => {
  return api({
    method: "get",
    url: "search/topic",
    params
  });
};

export const searchService = {
  getTopicBySearch
};
