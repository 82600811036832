import { call, put, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "./actionTypes";
import { searchScoreServices } from "./services";

function* getCandidateScore(action) {
  try {
    const response = yield call(searchScoreServices.getCandidateScore, action.params);
    yield put({
      type: ACTION_TYPES.GET_CANDIDATE_SCORE_SUCCESS,
      params: response.data.data
    });
  } catch (error) {
    yield put({
      type: ACTION_TYPES.GET_CANDIDATE_SCORE_FAILED,
      params: error?.data?.error
    });
  }
}

function* searchScoreSaga() {
  yield takeLatest(ACTION_TYPES.GET_CANDIDATE_SCORE, getCandidateScore);
}
export default searchScoreSaga;
