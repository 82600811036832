/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { OAuthProvider } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import "firebase/compat/firestore";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { FIREBASE_INFO } from "../utils/constants/env";

// const firebaseConfig = { ...FIREBASE_INFO };
type TParamsEvent = {
  [key: string]: any;
};
const EVENT_LOGIN = "login";
export const app = firebase.initializeApp(FIREBASE_INFO);
export const analytics: Analytics = (typeof window !== "undefined" && getAnalytics(app)) as Analytics;
export const auth = firebase.auth();
export const providerGoogle = new firebase.auth.GoogleAuthProvider();
export const eventTracking = (nameEvent: string, params?: TParamsEvent, fn?: Function) => {
  if (fn) {
    fn();
  }
  typeof window !== "undefined" && logEvent(analytics, `web_${nameEvent}`, params);
};
providerGoogle.setCustomParameters({ prompt: "select_account" });
// export const signInWithGoogle = () =>
//   auth.signInWithPopup(providerGoogle).then((result) => {
//     auth.onAuthStateChanged((user) => {
//       user?.getIdToken()?.then((ele) => {
//         window.location.reload();
//         eventTracking(EVENT_LOGIN, { provider: "Google" }, () => {
//           store.dispatch(commonActions.handleSaveIdToken(ele));
//         });
//       });
//     });
//   });

export const providerFacebook = new firebase.auth.FacebookAuthProvider();
providerFacebook.setCustomParameters({ prompt: "select_account" });
// export const signInWithFacebook = () =>
//   auth.signInWithPopup(providerFacebook).then((result) => {
//     auth.onAuthStateChanged((user) => {
//       user?.getIdToken()?.then((ele) => {
//         eventTracking(EVENT_LOGIN, { provider: "Facebook" }, () => {
//           store.dispatch(commonActions.handleSaveIdToken(ele));
//         });
//         window.location.reload();
//       });
//     });
//   });

export const providerApple = new OAuthProvider("apple.com");
providerApple.addScope("email");
providerApple.addScope("name");
// export const signInWithApple = () =>
//   auth.signInWithPopup(providerApple).then((result) => {
//     auth.onAuthStateChanged((user) => {
//       user?.getIdToken()?.then((ele) => {
//         eventTracking(EVENT_LOGIN, { provider: "Apple" }, () => {
//           store.dispatch(commonActions.handleSaveIdToken(ele));
//         });
//         window.location.reload();
//       });
//     });
//   });

export default firebase;
