import React from "react";

function IconOnThiTHPT() {
  return (
    <div>
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M59.5 39.5079V27.2739C59.4998 24.6259 58.8075 22.0245 57.4927 19.7313C56.178 17.438 54.287 15.5337 52.0099 14.2097L41.4901 8.09265C39.213 6.76782 36.6297 6.07031 34 6.07031C31.3703 6.07031 28.787 6.76782 26.5099 8.09265L15.9901 14.2097C13.713 15.5337 11.822 17.438 10.5073 19.7313C9.19249 22.0245 8.50021 24.6259 8.5 27.2739V39.5079C8.49878 42.1563 9.19045 44.7582 10.5054 47.0517C11.8203 49.3452 13.712 51.2493 15.9901 52.5722L26.5099 58.6963C28.7881 60.0177 31.371 60.7132 34 60.7132C36.629 60.7132 39.2119 60.0177 41.4901 58.6963L52.0099 52.5722C54.288 51.2493 56.1797 49.3452 57.4946 47.0517C58.8095 44.7582 59.5012 42.1563 59.5 39.5079Z"
          fill="url(#paint0_diamond_9431_108501)"
        />
        <g style={{ mixBlendMode: "screen" }} opacity="0.4">
          <path
            style={{ mixBlendMode: "screen" }}
            opacity="0.4"
            d="M21.7625 10.8438L15.9917 14.2003C15.1459 14.6924 14.3505 15.2676 13.6172 15.9176L50.7363 53.308L52.0116 52.5629C54.3387 51.2094 56.2595 49.248 57.571 46.8858L21.7625 10.8438Z"
            fill="url(#paint1_linear_9431_108501)"
          />
          <path
            style={{ mixBlendMode: "screen" }}
            opacity="0.4"
            d="M26.354 8.17188L23.7188 9.70468L58.5478 44.7746C58.9469 43.6953 59.2235 42.5741 59.3722 41.4322L26.354 8.17188Z"
            fill="url(#paint2_linear_9431_108501)"
          />
        </g>
        <path
          d="M33.9999 48.225C42.1331 48.225 48.7263 41.5848 48.7263 33.3937C48.7263 25.2027 42.1331 18.5625 33.9999 18.5625C25.8667 18.5625 19.2734 25.2027 19.2734 33.3937C19.2734 41.5848 25.8667 48.225 33.9999 48.225Z"
          fill="white"
        />
        <path
          d="M23.4922 30.4119V29.7479H28.1286V30.4119H26.1847V35.9297H25.4361V30.4119H23.4922ZM29.4114 35.9297V29.7479H30.16V32.5007H33.4561V29.7479H34.2047V35.9297H33.4561V33.1648H30.16V35.9297H29.4114ZM35.8309 35.9297V29.7479H37.9197C38.4046 29.7479 38.8011 29.8354 39.1089 30.0105C39.4188 30.1835 39.6482 30.418 39.7971 30.7138C39.9461 31.0096 40.0205 31.3396 40.0205 31.7038C40.0205 32.0681 39.9461 32.3991 39.7971 32.6969C39.6503 32.9947 39.4229 33.2322 39.115 33.4093C38.8071 33.5843 38.4127 33.6719 37.9317 33.6719H36.4346V33.0078H37.9076C38.2396 33.0078 38.5063 32.9505 38.7075 32.8358C38.9087 32.7211 39.0546 32.5661 39.1452 32.3709C39.2377 32.1737 39.284 31.9513 39.284 31.7038C39.284 31.4563 39.2377 31.235 39.1452 31.0398C39.0546 30.8446 38.9077 30.6916 38.7045 30.581C38.5012 30.4683 38.2316 30.4119 37.8955 30.4119H36.5795V35.9297H35.8309ZM41.0158 30.4119V29.7479H45.6522V30.4119H43.7083V35.9297H42.9597V30.4119H41.0158Z"
          fill="#475467"
        />
        <g style={{ mixBlendMode: "screen" }}>
          <g style={{ mixBlendMode: "screen" }}>
            <path
              d="M33.9933 57.5219C31.9208 57.5221 29.8846 56.9739 28.0886 55.9323L17.5687 49.8153C15.7758 48.7679 14.287 47.2651 13.2509 45.4571C12.2148 43.6491 11.6677 41.599 11.6641 39.5115V27.2775C11.6668 25.1908 12.2136 23.1413 13.2499 21.3343C14.2861 19.5273 15.7754 18.026 17.5687 16.9808L28.0886 10.8638C29.8911 9.84166 31.9247 9.30469 33.9933 9.30469C36.0619 9.30469 38.0955 9.84166 39.898 10.8638L50.4179 16.9808C52.2112 18.026 53.7005 19.5273 54.7367 21.3343C55.773 23.1413 56.3198 25.1908 56.3225 27.2775V39.5115C56.3189 41.599 55.7718 43.6491 54.7357 45.4571C53.6996 47.2651 52.2108 48.7679 50.4179 49.8153L39.898 55.9323C38.1024 56.9749 36.0659 57.5231 33.9933 57.5219ZM33.9933 9.97674C32.0452 9.97759 30.1311 10.4913 28.4409 11.467L17.921 17.5911C16.2352 18.5738 14.8351 19.985 13.8608 21.6836C12.8864 23.3822 12.3719 25.3087 12.3687 27.2704V39.5044C12.3706 41.4673 12.8846 43.3953 13.859 45.0952C14.8334 46.7952 16.2342 48.2075 17.921 49.1909L28.4409 55.3079C30.1298 56.2874 32.0444 56.8029 33.9933 56.8029C35.9422 56.8029 37.8568 56.2874 39.5457 55.3079L50.0656 49.1909C51.7524 48.2075 53.1532 46.7952 54.1276 45.0952C55.102 43.3953 55.616 41.4673 55.6179 39.5044V27.2775C55.6147 25.3158 55.1002 23.3893 54.1258 21.6907C53.1515 19.9921 51.7514 18.5809 50.0656 17.5982L39.5457 11.4741C37.8555 10.4984 35.9414 9.98469 33.9933 9.98384V9.97674Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <radialGradient
            id="paint0_diamond_9431_108501"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(34 33.3909) rotate(45.2032) scale(26.8107 26.8101)"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#16009E" />
          </radialGradient>
          <linearGradient
            id="paint1_linear_9431_108501"
            x1="15620.3"
            y1="6324.85"
            x2="18362.2"
            y2="6324.85"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4568DC" />
            <stop offset="1" stopColor="#B06AB3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_9431_108501"
            x1="13194.5"
            y1="5161.83"
            x2="14998.5"
            y2="5161.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4568DC" />
            <stop offset="1" stopColor="#B06AB3" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
export default React.memo(IconOnThiTHPT);
