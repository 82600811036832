import { call, put, takeEvery } from "redux-saga/effects";
import { ACTION_TYPES } from "./actionTypes";
import { subjectServices } from "./services";

function* getListTopic(action) {
  try {
    const response = yield call(subjectServices.getTopics, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_LIST_TOPIC_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.GET_LIST_TOPIC_FAILED,
      params: error?.data?.error
    });
  }
}
function* getListSubject(action) {
  try {
    const response = yield call(subjectServices.getSubjects, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_LIST_SUBJECTS_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.GET_LIST_SUBJECTS_FAILED,
      params: error?.data?.error
    });
  }
}
function* subjectSaga() {
  yield takeEvery(ACTION_TYPES.GET_LIST_TOPIC, getListTopic);
  yield takeEvery(ACTION_TYPES.GET_LIST_SUBJECTS, getListSubject);
}
export default subjectSaga;
