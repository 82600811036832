/* eslint-disable default-param-last */
import { HYDRATE } from "next-redux-wrapper";
import { TCourseState } from "../utils/types";
import { ACTION_TYPES } from "./actionTypes";

export const initialCourseState: TCourseState = {
  listCourse: [],
  notify: null
};

const courseReducer = (state = initialCourseState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_COURSE_SUCCESS: {
      return {
        ...state,
        listCourse: action.payload
      };
    }
    case ACTION_TYPES.VIEW_COURSE_DETAIL_SUCCESS: {
      return {
        ...state,
        notify: action.payload
      };
    }
    case ACTION_TYPES.VIEW_COURSE_DETAIL_FAILED: {
      return {
        ...state,
        notify: action.payload
      };
    }
    case HYDRATE: {
      return {
        ...state,
        listCourse: action.payload.course.listCourse || null
      };
    }
    default:
      return state;
  }
};
export default courseReducer;
