import ACTION_TYPES from "./actionType";


const handleGetInvitationFriend = () => {
  return {
    type: ACTION_TYPES.GET_INVITATION_FRIEND
  };
};
const handleSearchFriend = (params) => {
  return {
    type: ACTION_TYPES.SEARCH_FRIEND,
    params
  };
};
const handleCleanListSearchFriend = () => {
  return {
    type: ACTION_TYPES.CLEAN_LIST_FRIEND_SEARCH
  };
};

const handleInviteFriend = (params) => {
  return {
    type: ACTION_TYPES.INVITE_FRIEND,
    params
  };
};
const handleDeleteInvitation = (params) => {
  return {
    type: ACTION_TYPES.DELETE_INVITATION,
    params
  };
};
const handleAcceptFriend = (params) => {
  return {
    type: ACTION_TYPES.ACCEPT_FRIEND,
    params
  };
};
const handleRemoveFriend = (params) => {
  return {
    type: ACTION_TYPES.REMOVE_FRIEND,
    params
  };
};
const handleFollowFriend = (params) => {
  return {
    type: ACTION_TYPES.FOLLOW_FRIEND,
    params
  };
};
const handleDeleteFollow = (params) => {
  return {
    type: ACTION_TYPES.DELETE_FOLLOW,
    params
  };
};
const handleDeclineFriendRequest = (params) => {
  return {
    type: ACTION_TYPES.DECLINE_FRIEND_REQUEST,
    params
  };
};
const handleGetListFriend = (params) => {
  return {
    type: ACTION_TYPES.GET_LIST_FRIEND,
    params
  }
}
const handleCleanListFriend = () => {
  return {
    type: ACTION_TYPES.CLEAN_LIST_FRIEND
  }
}
const handleClearNotify = () => {
  return {
    type: ACTION_TYPES.CLEAR_NOTIFY
  };
};
const handleSetSearchKeyFriend = (params) => {
  return {
    type: ACTION_TYPES.SET_SEARCH_KEY_FRIEND,
    params
  }
}
const handleSetIsLoading = (params) => {
  return {
    type: ACTION_TYPES.SET_IS_LOADING,
    params
  }
}
export const friendActions = {
  handleGetInvitationFriend,
  handleSearchFriend,
  handleCleanListSearchFriend,
  handleInviteFriend,
  handleAcceptFriend,
  handleDeleteFollow,
  handleDeleteInvitation,
  handleRemoveFriend,
  handleFollowFriend,
  handleDeclineFriendRequest,
  handleGetListFriend,
  handleCleanListFriend,
  handleClearNotify,
  handleSetSearchKeyFriend,
  handleSetIsLoading
};
