/* eslint-disable no-param-reassign */
import {createSlice } from '@reduxjs/toolkit';

export const initialState = {
  locale: 'vi',
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeLocale: (state) => {
      state.locale = state.locale === 'vi' ? 'en' : 'vi';
    },
  },
});

export const { changeLocale } = localeSlice.actions;

export default localeSlice.reducer;
