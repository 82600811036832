export const ACTION_TYPES = {
  GET_MEMBER_SHIP: "GET_MEMBER_SHIP",
  GET_MEMBER_SHIP_SUCCESS: "GET_MEMBER_SHIP_SUCCESS",
  GET_MEMBER_SHIP_FAILED: "GET_MEMBER_SHIP_FAILED",
  GET_VIP: "GET_VIP",
  GET_VIP_SUCCESS: "GET_VIP_SUCCESS",
  GET_VIP_FAILED: "GET_VIP_FAILED",
  CLEAR_NOTIFY: "CLEAR_NOTIFY",
  GET_PURCHASES: "GET_PURCHASES",
  GET_PURCHASES_SUCCESS: "GET_PURCHASES_SUCCESS",
  GET_PURCHASES_FAILED: "GET_PURCHASES_FAILED"
};
