/* eslint-disable no-unused-vars */
import { ACTION_TYPES } from "./actionTypes";

const handleGetDetailRoom = (params, successCallback = (roomDetailResponse) => {}) => {
  return {
    type: ACTION_TYPES.GET_DETAIL_ROOM,
    params,
    successCallback
  };
};
const handleChangePassword = (params) => {
  return {
    type: ACTION_TYPES.CHANGE_PASSWORD,
    params
  };
};
const handleGetMemberOfRoom = (params) => {
  return {
    type: ACTION_TYPES.GET_MEMBER,
    params
  };
};
const handleLeaveRoom = (params) => {
  return {
    type: ACTION_TYPES.LEAVE_ROOM,
    params
  };
};
const handleJoinRoom = (params, successCallback = () => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.JOIN_ROOM,
    params,
    successCallback,
    errorCallback
  };
};
const handleJoinRoomFailed = (params) => {
  return {
    type: ACTION_TYPES.JOIN_ROOM_FAILED,
    params
  };
};
const handleCloseNotification = () => {
  return {
    type: ACTION_TYPES.CLOSE_NOTIFICATION
  };
};
const handleFindMember = (params) => {
  return {
    type: ACTION_TYPES.FIND_MEMBER,
    params
  };
};
const handleChangeMemberHid = (params) => {
  return {
    type: ACTION_TYPES.CHANGE_MEMBER_ID,
    params
  };
};
const handleAddMember = (params) => {
  return {
    type: ACTION_TYPES.ADD_MEMBER,
    params
  };
};
const handleDeleteNotify = () => {
  return {
    type: ACTION_TYPES.DELETE_NOTIFY
  };
};
const handleGetListRoom = (params) => {
  return {
    type: ACTION_TYPES.GET_LIST_ROOM,
    params
  };
};
const handleCreateRoom = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.CREATE_ROOM,
    params,
    successCallback
  };
};
const handleGetCreationInfo = (params) => {
  return {
    type: ACTION_TYPES.GET_CREATION_INFO,
    params
  };
};
const handleUpdateRoom = (params) => {
  return {
    type: ACTION_TYPES.UPDATE_ROOM,
    params
  };
};
const handleDeleteRoom = (params) => {
  return {
    type: ACTION_TYPES.DELETE_ROOM,
    params
  };
};
const handleCloseModal = () => {
  return {
    type: ACTION_TYPES.CLOSE_MODAL
  };
};
const deleteMember = (params) => {
  return {
    type: ACTION_TYPES.DELETE_MEMBER,
    params
  };
};
const handleFilterRankForDate = (params) => {
  return {
    type: ACTION_TYPES.CHOOSE_DATE,
    params
  };
};
const handleChooseSubject = (params) => {
  return {
    type: ACTION_TYPES.CHOOSE_SUBJECT_ARENA,
    params
  };
};
const handleGetGoldBoard = (params) => {
  return {
    type: ACTION_TYPES.GET_GOLD_BOARD,
    params
  };
};
const handleSort = (params) => {
  return {
    type: ACTION_TYPES.SORT_ROOM_HISTORY,
    params
  };
};
const handleSortToSubjects = (params) => {
  return {
    type: ACTION_TYPES.SORT_TO_SUBJECT,
    params
  };
};
const handleGetHistoryRoom = (params) => {
  return {
    type: ACTION_TYPES.GET_HISTORY_ROOM,
    params
  };
};
const handleGetQuizQuestions = (params) => {
  return {
    type: ACTION_TYPES.GET_QUIZ_QUESTIONS,
    params
  };
};
const handlePostQuizStart = (params) => {
  return {
    type: ACTION_TYPES.POST_QUIZ_START,
    params
  };
};
const handleSubmitQuiz = (params) => {
  return {
    type: ACTION_TYPES.SUBMIT_QUIZ,
    params
  };
};
const handleGetQuizSolutions = (params) => {
  return {
    type: ACTION_TYPES.GET_QUIZ_SOLUTIONS,
    params
  };
};
const handleDeleteQuizSolutions = () => {
  return {
    type: ACTION_TYPES.DELETE_QUIZ_INFO_SOLUTIONS
  };
};
const handlePatchQuizStart = (params) => {
  return {
    type: ACTION_TYPES.PATCH_QUIZ_START,
    params
  };
};
const handleGetAnalysis = (params) => {
  return {
    type: ACTION_TYPES.GET_ANALYSIS,
    params
  };
};
const deleteQuizInfoToken = () => {
  return {
    type: ACTION_TYPES.DELETE_QUIZ_INFO_TOKEN
  };
};
const handleSaveAnswerTotal = (params) => {
  return {
    type: ACTION_TYPES.SAVE_ANSWER_TOTAL,
    params
  };
};
const handleShareRoom = (params) => {
  return {
    type: ACTION_TYPES.SHARE_ROOM_TO_ASK,
    params
  };
};
const handleUpdateQuizSolution = (params) => {
  return {
    type: ACTION_TYPES.UPDATE_QUIZ_SOLUTION_ARENA,
    params
  };
};
const handleOpenModalCreatePost = (params) => {
  return {
    type: ACTION_TYPES.OPEN_MODAL_CREATE_POST,
    params
  };
};
const handleOpenModalConfirmJoinRoom = (params: boolean) => {
  return {
    type: ACTION_TYPES.OPEN_MODAL_CONFIRM_JOIN_ROOM,
    params
  };
};
const handleGetListSubjectsArena = (params) => {
  return {
    type: ACTION_TYPES.GET_LIST_SUBJECT_ARENA,
    params
  };
};
const handleOpenModalGiftDiamond = (params: boolean) => {
  return {
    type: ACTION_TYPES.HANDLE_OPEN_MODAL_GIFT_DIAMOND,
    params
  };
};
const handleChooseSubjectFilter = (params: number[]) => {
  return {
    type: ACTION_TYPES.HANDLE_CHOOSE_SUBJECT_FILTER,
    params
  };
};
const handleDeleteSuccessMessage = () => {
  return {
    type: ACTION_TYPES.HANDLE_DELETE_SUCCESS_MESSAGE
  };
};
const handleSaveIdQuestion = (params: string) => {
  return {
    type: ACTION_TYPES.SAVE_QUESTION_ID_SCROLL,
    params
  };
};
const handleClearIdQuestion = () => {
  return {
    type: ACTION_TYPES.CLEAR_QUESTION_ID_SCROLL
  };
};
const handleChangePage = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_PAGE,
    params
  };
};
const handleClearPage = () => {
  return {
    type: ACTION_TYPES.HANDLE_CLEAR_PAGE
  };
};
const handleChangeTab = (tab: any) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_TAB,
    params: tab
  };
};

const handleSearchRoom = (params) => {
  return {
    type: ACTION_TYPES.SEARCH_ARENA_ROOM,
    params
  };
};

export const arenaRoomActions = {
  handleGetDetailRoom,
  handleChangePassword,
  handleGetMemberOfRoom,
  handleLeaveRoom,
  handleJoinRoom,
  handleCloseNotification,
  handleFindMember,
  handleChangeMemberHid,
  handleAddMember,
  handleDeleteNotify,
  handleGetListRoom,
  handleCreateRoom,
  handleGetCreationInfo,
  handleUpdateRoom,
  handleDeleteRoom,
  handleCloseModal,
  deleteMember,
  handleFilterRankForDate,
  handleChooseSubject,
  handleGetGoldBoard,
  handleSort,
  handleSortToSubjects,
  handleGetHistoryRoom,
  handleGetQuizQuestions,
  handlePostQuizStart,
  handleSubmitQuiz,
  handleGetQuizSolutions,
  handleDeleteQuizSolutions,
  handlePatchQuizStart,
  handleGetAnalysis,
  deleteQuizInfoToken,
  handleSaveAnswerTotal,
  handleShareRoom,
  handleUpdateQuizSolution,
  handleOpenModalCreatePost,
  handleOpenModalConfirmJoinRoom,
  handleGetListSubjectsArena,
  handleOpenModalGiftDiamond,
  handleChooseSubjectFilter,
  handleDeleteSuccessMessage,
  handleSaveIdQuestion,
  handleClearIdQuestion,
  handleChangePage,
  handleClearPage,
  handleChangeTab,
  handleJoinRoomFailed,
  handleSearchRoom
};
