import { call, takeEvery, put } from "redux-saga/effects";
import { ACTION_TYPES_NEW } from "./actionType";
import { newServices } from "./services";

function* getNewsDetail(action) {
  try {
    const res = yield call(newServices.getNewsDetail, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES_NEW.GET_NEWS_DETAIL_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES_NEW.GET_NEWS_DETAIL_ERROR,
      params: err?.data?.error
    });
  }
}

export default function* newsSaga() {
  yield takeEvery(ACTION_TYPES_NEW.GET_NEWS_DETAIL, getNewsDetail);
}
