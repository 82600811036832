import api from '../../../utils/helpers/api';

const getMemberships = () => {
  const option = {
    method: 'get',
    url: `memberships`,
  };
  return api(option);
};
const getVip = (data?: any) => {
  const option = {
    method: 'post',
    url: `vip`,
    data,
  };
  return api(option);
};
const getPurchase = () => {
  const option = {
    method: 'get',
    url: 'purchases',
  };
  return api(option);
}


export const changePointService = {
  getMemberships,
  getVip,
  getPurchase,
};
