/* eslint-disable default-param-last */
import { TFriendState } from "../utils/types";
import ACTION_TYPES from "./actionType";

export const initialStateFriend: TFriendState = {
  isLoading: false,
  notify: {
    message: "",
    type: ""
  },
  listInvitation: [],
  listFriendSearch: [],
  latestDataSearchFriend: {},
  friendHid: "",
  listFriend: {
    list_following: [],
    list_friend: []
  },
  searchKey: "",
  action: ""
};

const friendReducer = (state = initialStateFriend, action) => {
  switch (action?.type) {
    case ACTION_TYPES.GET_INVITATION_FRIEND: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_INVITATION_FRIEND_SUCCESS: {
      return {
        ...state,
        listInvitation: action?.params?.items,
        isLoading: false,
        action: "GET_INVITATION_FRIEND_SUCCESS"
      };
    }
    case ACTION_TYPES.GET_INVITATION_FRIEND_FAILED: {
      return {
        ...state,
        isLoading: false
      };
    }
    case ACTION_TYPES.SEARCH_FRIEND: {
      return {
        ...state,
        isLoading: true,
        latestDataSearchFriend: action?.params,
        action: "SEARCH_FRIEND"
      };
    }
    case ACTION_TYPES.SEARCH_FRIEND_SUCCESS: {
      return {
        ...state,
        listFriendSearch: action?.params?.items,
        isLoading: false,
        action: "SEARCH_FRIEND_SUCCESS"
      };
    }
    case ACTION_TYPES.SEARCH_FRIEND_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          message: action?.params?.message,
          type: "SEARCH_FRIEND_FAILED",
          description: action?.params?.description,
          errorCode: action?.params?.code
        },
        isLoading: false,
        listFriendSearch: [],
        action: "SEARCH_FRIEND_FAILED"
      };
    }
    case ACTION_TYPES.CLEAN_LIST_FRIEND_SEARCH: {
      return {
        ...state,
        listFriendSearch: [],
        isLoading: false
      };
    }
    case ACTION_TYPES.ACCEPT_FRIEND: {
      return {
        ...state,
        friendHid: action?.params?.member_hid
      };
    }
    case ACTION_TYPES.ACCEPT_FRIEND_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "ACCEPT_FRIEND_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.ACCEPT_FRIEND_FAILED: {
      return {
        ...state,
        notify: {
          type: "ACCEPT_FRIEND_FAILED",
          isOpenNotify: false,
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.DELETE_FOLLOW: {
      return {
        ...state,
        friendHid: action?.params?.member_hid
      };
    }
    case ACTION_TYPES.DELETE_FOLLOW_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "DELETE_FOLLOW_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.DELETE_FOLLOW_FAILED: {
      return {
        ...state,
        notify: {
          type: "DELETE_FOLLOW_FAILED",
          isOpenNotify: false,
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.FOLLOW_FRIEND: {
      return {
        ...state,
        friendHid: action?.params?.member_hid
      };
    }
    case ACTION_TYPES.FOLLOW_FRIEND_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "FOLLOW_FRIEND_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.FOLLOW_FRIEND_FAILED: {
      return {
        ...state,
        notify: {
          type: "FOLLOW_FRIEND_FAILED",
          isOpenNotify: false,
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.REMOVE_FRIEND: {
      return {
        ...state,
        friendHid: action?.params?.member_hid
      };
    }
    case ACTION_TYPES.REMOVE_FRIEND_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "REMOVE_FRIEND_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.REMOVE_FRIEND_FAILED: {
      return {
        ...state,
        notify: {
          type: "REMOVE_FRIEND_FAILED",
          isOpenNotify: false,
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.DECLINE_FRIEND_REQUEST: {
      return {
        ...state,
        friendHid: action?.params?.member_hid
      };
    }
    case ACTION_TYPES.DECLINE_FRIEND_REQUEST_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "DECLINE_FRIEND_REQUEST_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.DECLINE_FRIEND_REQUEST_FAILED: {
      return {
        ...state,
        notify: {
          type: "DECLINE_FRIEND_REQUEST_FAILED",
          isOpenNotify: false,
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.INVITE_FRIEND: {
      return {
        ...state,
        friendHid: action?.params?.member_hid
      };
    }
    case ACTION_TYPES.INVITE_FRIEND_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "INVITE_FRIEND_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.INVITE_FRIEND_FAILED: {
      return {
        ...state,
        notify: {
          type: "INVITE_FRIEND_FAILED",
          isOpenNotify: false,
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.DELETE_INVITATION: {
      return {
        ...state,
        friendHid: action?.params?.member_hid
      };
    }
    case ACTION_TYPES.DELETE_INVITATION_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "DELETE_INVITATION_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.DELETE_INVITATION_FAILED: {
      return {
        ...state,
        notify: {
          type: "DELETE_INVITATION_FAILED",
          isOpenNotify: false,
          isOpenDialog: true,
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.GET_LIST_FRIEND: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_LIST_FRIEND_SUCCESS: {
      return {
        ...state,
        listFriend: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_LIST_FRIEND_FAILED: {
      return {
        ...state,
        isLoading: false,
        notify: {
          message: action?.params?.message,
          errorCode: action?.params?.code,
          type: "GET_LIST_FRIEND_FAILED"
        }
      };
    }
    case ACTION_TYPES.CLEAN_LIST_FRIEND: {
      return {
        ...state,
        listFriend: {
          list_following: [],
          list_friend: []
        },
        listInvitation: [],
        listFriendSearch: []
      };
    }
    case ACTION_TYPES.CLEAR_NOTIFY: {
      return {
        ...state,
        notify: {
          isOpenDialog: false,
          isOpenNotify: false,
          message: "",
          errorCode: "",
          description: ""
        }
      };
    }
    case ACTION_TYPES.SET_SEARCH_KEY_FRIEND: {
      return {
        ...state,
        searchKey: action?.params
      };
    }
    case ACTION_TYPES.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action?.params
      };
    }
    default:
      return state;
  }
};
export default friendReducer;
