/* eslint-disable react/require-default-props */
import React from "react";

// eslint-disable-next-line no-unused-vars
type IconStatisticActive = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export default function IconStatisticActive({
  className,
  fill,
  width,
  height
}: IconStatisticActive) {
  return (
    <svg
      className={className}
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 14.6667H7.33876C6.23057 14.6667 5.3335 15.6443 5.3335 16.852V26.6667H12.0002V14.6667Z"
        fill="#38B000"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6148 5.33337H14.4063C13.0765 5.33337 12 6.34081 12 7.58524V26.6667H20V7.58524C20 6.34081 18.9446 5.33337 17.6148 5.33337Z"
        fill="#38B000"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6614 17.3334H20V26.6667H26.6667V19.6718C26.6491 18.3795 25.752 17.3334 24.6614 17.3334Z"
        fill="#38B000"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
