import { TNotify } from "../utils/type";
import { ACTION_TYPES } from "./actionTypes";

const handleGetGrade = () => {
  return {
    type: ACTION_TYPES.GET_GRADE
  };
};
const handleGetTop = (params: any) => {
  return {
    type: ACTION_TYPES.GET_TOP,
    params
  };
};
const handleGetStatisticData = (params: any) => {
  return {
    type: ACTION_TYPES.GET_STATIC_DATA,
    params
  };
};
const handleCompleteMission = (params: any) => {
  return {
    type: ACTION_TYPES.COMPLETE_MISSION,
    params
  };
};
const deleteCongratulation = () => {
  return {
    type: ACTION_TYPES.DELETE_CONGRATULATION
  };
};
const deleteNotify = () => {
  return {
    type: ACTION_TYPES.DELETE_NOTIFY
  };
};
const handleChooseGrade = (params: any) => {
  return {
    type: ACTION_TYPES.CHOOSE_GRADE,
    params
  };
};
const handleGetListSubject = (params: any) => {
  return {
    type: ACTION_TYPES.GET_SUBJECTS,
    params
  };
};
const handleSaveAccessToken = (params: string) => {
  return {
    type: ACTION_TYPES.SAVE_ACCESS_TOKEN,
    params
  };
};
const handleSaveRefreshToken = (params: string) => {
  return {
    type: ACTION_TYPES.SAVE_REFRESH_TOKEN,
    params
  };
};
const handleSaveIdToken = (params: string) => {
  return {
    type: ACTION_TYPES.SAVE_ID_TOKEN,
    params
  };
};
const handleSaveProviderId = (params: string) => {
  return {
    type: ACTION_TYPES.SAVE_PROVIDER_ID,
    params
  };
};
const handleSaveGradeLocalStorage = (params: string) => {
  return {
    type: ACTION_TYPES.SAVE_GRADE,
    params
  };
};
const handleSaveAdsFirebaseRemote = (params: any) => {
  return {
    type: ACTION_TYPES.SAVE_ADS_FIREBASE_REMOTE,
    params
  };
};
const handleCleanAction = () => {
  return {
    type: ACTION_TYPES.CLEAN_ACTION
  };
};
const handleGetNotification = () => {
  return {
    type: ACTION_TYPES.GET_NOTIFICATION
  };
};
const setActionLoginSuccess = () => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS
  };
};
const handleShowDirectApp = (params) => {
  return {
    type: ACTION_TYPES.SHOW_DIRECT_APP,
    params
  };
};
const handleGetSuggestedFriend = () => {
  return {
    type: ACTION_TYPES.GET_SUGGESTED_FRIEND
  };
};
const handleSaveLocationPermission = (params) => {
  return {
    type: ACTION_TYPES.SAVE_LOCATION_PERMISSION,
    params
  };
};
const handleUpdateLocation = (params) => {
  return {
    type: ACTION_TYPES.UPDATE_LOCATION,
    params
  };
};
const handleServerError = (params) => {
  return {
    type: ACTION_TYPES.SERVER_ERROR,
    params
  };
};
const handleChooseBook = (params) => {
  return {
    type: ACTION_TYPES.CHOOSE_BOOK,
    params
  };
};
const handleChangeSubjects = (params) => {
  return {
    type: ACTION_TYPES.CHANGE_SUBJECT,
    params
  };
};
const handleOpenModalLogin = (params: { isOpen: boolean; isRequired?: boolean }) => {
  return {
    type: ACTION_TYPES.OPEN_MODAL_LOGIN,
    params
  };
};
const handleGetLearningHistory = () => {
  return {
    type: ACTION_TYPES.GET_LEARNING_HISTORY
  };
};
const handleOpenModalConfirmLogin = (params: boolean) => {
  return {
    type: ACTION_TYPES.OPEN_MODAL_CONFIRM_LOGIN,
    params
  };
};
const handleDirectUrl = (params: string) => {
  return {
    type: ACTION_TYPES.HANDLE_DIRECT_URL,
    params
  };
};
const handleSaveAuthCode = (params: string) => {
  return {
    type: ACTION_TYPES.SAVE_AUTH_CODE,
    params
  };
};
const handleSaveCongratulation = (params: any) => {
  return {
    type: ACTION_TYPES.SAVE_CONGRATULATION,
    params
  };
};
const handleSaveNotify = (params: TNotify) => {
  return {
    type: ACTION_TYPES.SAVE_NOTIFY,
    params
  };
};
const handleOpenModalConfirmPremium = (params) => {
  return {
    type: ACTION_TYPES.OPEN_MODAL_CONFIRM_PREMIUM,
    params
  };
};
const handleGetNews = () => {
  return {
    type: ACTION_TYPES.GET_NEWS
  };
};
const handleOpenModalChangeAvatar = (params) => {
  return {
    type: ACTION_TYPES.OPEN_MODAL_CHANGE_AVATAR,
    params
  };
};
const handleDirectAddDiamondPage = (params) => {
  return {
    type: ACTION_TYPES.CHANGE_TAB,
    params
  };
};
const getTimeServer = () => {
  return {
    type: ACTION_TYPES.GET_TIME_SERVER
  };
};
const getTimeServerSuccess = (params) => {
  return {
    type: ACTION_TYPES.GET_TIME_SERVER_SUCCESS,
    params
  };
};
const handleSaveIsUpdateInfo = (params) => {
  return {
    type: ACTION_TYPES.SAVE_IS_UPDATE_INFO,
    params
  };
};
const handleDeleteKeywordsFromList = (words: string) => {
  return {
    type: ACTION_TYPES.DELETE_KEYWORD,
    params: words
  };
};
const handleAddKeywordsFormListSearched = (words: string) => {
  return {
    type: ACTION_TYPES.ADD_KEYWORD,
    params: words
  };
};
const handleSearch = (text: string) => {
  return {
    type: ACTION_TYPES.SEARCH,
    params: text
  };
};
const handleSetIsNewMember = (params) => {
  return {
    type: ACTION_TYPES.SET_IS_NEW_MEMBER,
    params
  };
};
const handleSetIsEmptyName = (params) => {
  return {
    type: ACTION_TYPES.SET_IS_EMPTY_NAME,
    params
  };
};
const handleLogOut = () => {
  return {
    type: ACTION_TYPES.HANDLE_LOGOUT
  };
};
const addUserInfos = (params) => {
  return {
    type: ACTION_TYPES.ADD_USER_INFOS,
    params
  };
};
const addFireBaseInfos = (params) => {
  return {
    type: ACTION_TYPES.ADD_FIRE_BASE_INFOS,
    params
  };
};
const handleGetTopSuccess = (params) => {
  return {
    type: ACTION_TYPES.GET_TOP_SUCCESS,
    params
  };
};
const handleGetTopExtraSuccess = (params) => {
  return {
    type: ACTION_TYPES.GET_TOP_EXTRA_SUCCESS,
    params
  };
};
const handleGetTopExtra = (params) => {
  return {
    type: ACTION_TYPES.GET_TOP_EXTRA,
    params
  };
};
const handleGetNewsSuccess = (params) => {
  return {
    type: ACTION_TYPES.GET_NEWS_SUCCESS,
    params
  };
};
const handlePostActionClickAds = (params) => {
  return {
    type: ACTION_TYPES.POST_ACTION_CLICK_ADS_IN_HOUSE,
    params
  };
};
const handleSaveIsShowDialogAdsInHouseFirstTime = (params) => {
  return {
    type: ACTION_TYPES.SAVE_IS_SHOW_DIALOG_ADS_IN_HOUSE_FIRST_TIME,
    params
  };
};
const handleGetAdsInHouse = (params) => {
  return {
    type: ACTION_TYPES.GET_ADS_IN_HOUSE,
    params
  };
};
const handleClearAdsInHouse = () => {
  return {
    type: ACTION_TYPES.CLEAR_ADS_IN_HOUSE
  };
};
const handleCleanInvalidToken = () => {
  return {
    type: ACTION_TYPES.CLEAN_INVALID_TOKEN
  };
};
const handleCalculateDiffTimeSeconds = (params) => {
  return {
    type: ACTION_TYPES.CALCULATE_DIFF_TIME_SECONDS,
    params
  };
};
const handleGetUserInfo = () => {
  return {
    type: ACTION_TYPES.GET_USER_INFO
  };
};

const handleGetSurvey = (params) => {
  return {
    type: ACTION_TYPES.GET_SURVEY,
    params
  };
};
const handleSetRouterHistory = (params) => {
  return {
    type: ACTION_TYPES.SET_ROUTER_HISTORY,
    params
  };
};
const handleSubmitSurvey = (params) => {
  return {
    type: ACTION_TYPES.SUBMIT_SURVEY,
    params
  };
};
const handleOpenDialogAdsInHouse = (params) => {
  return {
    type: ACTION_TYPES.OPEN_DIALOG_ADS_IN_HOUSE,
    params
  };
};
const handleHideDialogSurvey = (params) => {
  return {
    type: ACTION_TYPES.HIDE_DIALOG_SURVEY,
    params
  };
};
const handleSaveTimeShowSurvey = (params) => {
  return {
    type: ACTION_TYPES.SAVE_TIME_SHOW_SURVEY,
    params
  };
};

const getDailyReport = (params = {}, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_DAILY_REPORT,
    params,
    successCallback
  };
};

const closeDailyReportDialog = () => {
  return {
    type: ACTION_TYPES.CLOSE_DAILY_REPORT_DIALOG
  };
};

const setIsOpenTeacherAccountInfoDialog = (params) => {
  return {
    type: ACTION_TYPES.SET_IS_OPEN_TEACHER_ACCOUNT_INFO_DIALOG,
    params
  };
};

const setIsOpenTeacherBenefitsDialog = (params) => {
  return {
    type: ACTION_TYPES.SET_IS_OPEN_TEACHER_BENEFITS_DIALOG,
    params
  };
};
const setIsOpenTeacherAccountFormDialog = (params) => {
  return {
    type: ACTION_TYPES.SET_IS_OPEN_TEACHER_ACCOUNT_FORM_DIALOG,
    params
  };
};

const setOpenClassesDialog = (params) => {
  return {
    type: ACTION_TYPES.SET_OPEN_CLASSES_DIALOG,
    params
  };
};

export const commonActions = {
  handleGetGrade,
  handleGetTop,
  handleGetStatisticData,
  handleCompleteMission,
  deleteCongratulation,
  deleteNotify,
  handleChooseGrade,
  handleGetListSubject,
  handleSaveAccessToken,
  handleSaveRefreshToken,
  handleSaveIdToken,
  handleSaveProviderId,
  handleSaveGradeLocalStorage,
  handleSaveAdsFirebaseRemote,
  handleCleanAction,
  handleGetNotification,
  setActionLoginSuccess,
  handleShowDirectApp,
  handleGetSuggestedFriend,
  handleSaveLocationPermission,
  handleUpdateLocation,
  handleServerError,
  handleChooseBook,
  handleChangeSubjects,
  handleOpenModalLogin,
  handleGetLearningHistory,
  handleOpenModalConfirmLogin,
  handleOpenModalConfirmPremium,
  handleGetNews,
  handleDirectUrl,
  handleSaveAuthCode,
  handleSaveCongratulation,
  handleSaveNotify,
  handleOpenModalChangeAvatar,
  handleDirectAddDiamondPage,
  getTimeServer,
  handleSaveIsUpdateInfo,
  handleDeleteKeywordsFromList,
  handleAddKeywordsFormListSearched,
  handleSearch,
  handleSetIsNewMember,
  handleSetIsEmptyName,
  handleLogOut,
  addUserInfos,
  addFireBaseInfos,
  handleGetTopSuccess,
  handleGetNewsSuccess,
  handlePostActionClickAds,
  handleSaveIsShowDialogAdsInHouseFirstTime,
  handleGetAdsInHouse,
  handleClearAdsInHouse,
  handleGetTopExtraSuccess,
  handleGetTopExtra,
  handleCleanInvalidToken,
  handleCalculateDiffTimeSeconds,
  handleGetUserInfo,
  handleGetSurvey,
  handleSetRouterHistory,
  handleSubmitSurvey,
  handleOpenDialogAdsInHouse,
  handleHideDialogSurvey,
  handleSaveTimeShowSurvey,
  getDailyReport,
  closeDailyReportDialog,
  getTimeServerSuccess,
  setIsOpenTeacherAccountInfoDialog,
  setIsOpenTeacherBenefitsDialog,
  setIsOpenTeacherAccountFormDialog,
  setOpenClassesDialog
};
