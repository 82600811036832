import moment from "moment";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import { friendActions } from "../../friends/redux/actions";
import api from "../../../utils/helpers/api";
import { accountActions } from "../../account/redux/actions";
// import { getInformationAccount } from "../../account/redux/services";
import { commonActions } from "../redux/actions";
import { ADS_IN_HOUSE_ACTION_CLICK } from "./constant";
import { clientKeyAdsInHouse, secretKeyAdsInHouse } from "../../../utils/constants/env";

export const getTopInfos = (params?: any) => {
  const option = {
    method: "get",
    url: "top",
    params
  };
  return api(option);
};
export const getTopExtraInfos = (params?: any) => {
  const option = {
    method: "get",
    url: "top-extra",
    params
  };
  return api(option);
};
export const getNewsHomePage = () => {
  return api({
    method: "get",
    url: "news"
  });
};

export const getExamLessonHistoryList = (params?: any) => {
  const option = {
    method: "get",
    url: "learning-history",
    params
  };
  return api(option);
};

export const getListSubjects = (params?: any) => {
  const option = {
    method: "get",
    url: `grades/${params.grade}/subjects`
  };
  return api(option);
};

export const getListRanking = (params?: any) => {
  const option = {
    method: "get",
    url: `arena/${params.grade}/rankings`
  };
  return api(option);
};

export const getGrades = () => {
  const option = {
    method: "get",
    url: `grades`
  };
  return api(option);
};

export const getNotifications = () => {
  const option = {
    method: "get",
    url: `notifications`
  };
  return api(option);
};

// export const getUserInfo = async () => {
//   try {
//     const res = await getInformationAccount();
//     const userInfo = store.getState().commonNew.userInfos;
//     if (res?.data) {
//       store.dispatch(commonActions.addUserInfos({ ...userInfo, member: res?.data?.data }));
//     }
//   } catch (error) {}
// };

export const handleAcceptFriend = (store, invitationId, memberHid) => {
  const paramsPre = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    invitation_id: invitationId
  };
  const params = {
    ...paramsPre,
    signature: genSignature(paramsPre),
    member_hid: memberHid
  };
  store.dispatch(friendActions.handleAcceptFriend(params));
};

export const handleDeclineFriendRequest = (store, invitationId, memberHid) => {
  const paramsPre = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    invitation_id: invitationId
  };
  const params = {
    ...paramsPre,
    signature: genSignature(paramsPre),
    member_hid: memberHid
  };
  store.dispatch(friendActions.handleDeclineFriendRequest(params));
};

export const handleDeleteInvitation = (store, memberHid) => {
  const paramsPre = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    member_hid: memberHid
  };
  const params = {
    ...paramsPre,
    signature: genSignature(paramsPre)
  };
  store.dispatch(friendActions.handleDeleteInvitation(params));
};

export const handleInviteFriend = (store, memberHid) => {
  const paramsPre = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    member_hid: memberHid
  };
  const params = {
    ...paramsPre,
    signature: genSignature(paramsPre)
  };
  store.dispatch(friendActions.handleInviteFriend(params));
};

export const handleDeleteFollow = (store, memberHid) => {
  const paramsPre = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    member_hid: memberHid
  };
  const params = {
    ...paramsPre,
    signature: genSignature(paramsPre)
  };
  store.dispatch(friendActions.handleDeleteFollow(params));
};

export const handleRemoveFriend = (store, memberHid) => {
  const paramsPre = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    member_hid: memberHid
  };
  const params = {
    ...paramsPre,
    signature: genSignature(paramsPre)
  };
  store.dispatch(friendActions.handleRemoveFriend(params));
};

export const handleFollowFriend = (store, memberHid) => {
  const paramsPre = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    member_hid: memberHid
  };
  const params = {
    ...paramsPre,
    signature: genSignature(paramsPre)
  };
  store.dispatch(friendActions?.handleFollowFriend(params));
};

export const handleGetAccountDetail = (store, memberHid) => {
  store.dispatch(accountActions.handleGetDetailAccount(memberHid));
};

export const handlePostActionClickAdsInHouse = (store, activityUid) => {
  const dataUserInfos = store.getState().commonNew?.userInfos;
  const paramsClickAds = {
    timestamp: moment().unix(),
    nonce: getNonce(),
    activity_uid: activityUid,
    action: ADS_IN_HOUSE_ACTION_CLICK,
    platform: "web",
    device_cd: dataUserInfos?.device?.device_cd,
    extra_info: navigator?.userAgent
  };
  const dataClickAds = {
    ...paramsClickAds,
    signature: genSignature(paramsClickAds, clientKeyAdsInHouse, secretKeyAdsInHouse)
  };
  store.dispatch(commonActions.handlePostActionClickAds(dataClickAds));
};

export const getTimeServer = () => {
  return api({
    method: "get",
    url: "check"
  });
};
