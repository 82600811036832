import api from "../../../utils/helpers/api";

export const getAuthorize = (store, data = {}) => {
  return api(
    {
      method: "post",
      url: "authorize",
      data,
      noAuthentication: true
    },
    undefined,
    store
  );
};

export const signIn = (store, data = {}, headers = {}) => {
  return api(
    {
      method: "post",
      url: "sign-in",
      data,
      headers,
      noAuthentication: true
    },
    undefined,
    store
  );
};

export const signOutApi = (store, data = {}) => {
  return api(
    {
      method: "post",
      url: "sign-out",
      data
    },
    undefined,
    store
  );
};

export const getUserInfos = (
  store,
  data: {
    signature: string;
    timestamp: number;
    nonce: string;
    platform: string;
    app_version: string | undefined;
  }
) => {
  return api(
    {
      method: "post",
      url: "auth",
      data
    },
    undefined,
    store
  );
};

export const getTokens = (data = {}, headers = {}) => {
  return api({
    method: "post",
    url: "token",
    data,
    headers
  });
};
