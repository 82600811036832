import api from "../../components/utils/helpers/api";

export const getAuthorizeSSR = (store, data = {}) => {
  return api(
    {
      method: "post",
      url: "authorize",
      data,
      noAuthentication: true
    },
    undefined,
    store
  );
};

export const signInSSR = (store, data = {}, headers = {}) => {
  return api(
    {
      method: "post",
      url: "sign-in",
      data,
      headers,
      noAuthentication: true
    },
    undefined,
    store
  );
};
