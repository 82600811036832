/* eslint-disable import/no-cycle */
import { call, put, takeEvery } from "redux-saga/effects";
import { toastSuccess } from "../../common/components/toastify";
import { ACTION_TYPES_HOME } from "./actionTypes";
import { homeServices } from "./sevices";

function* getGiftCode() {
  try {
    const res = yield call(homeServices.getListGiftCodes);
    if (res) {
      yield put({ type: ACTION_TYPES_HOME.GET_GIFT_CODE_SUCCESS, params: res.data?.data });
    }
  } catch (err) {}
}
function* takeGiftCode(action) {
  try {
    const res = yield call(homeServices?.takeGiftCode, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE_SUCCESS,
        params: res.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE_FAILED,
      params: err?.data?.error
    });
  }
}

function* homeSagas() {
  yield takeEvery(ACTION_TYPES_HOME.GET_GIFT_CODE, getGiftCode);
  yield takeEvery(ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE, takeGiftCode);
}

export default homeSagas;
