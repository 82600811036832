import api from "../../../utils/helpers/api";

const getGiftItem = () => {
  const option = {
    method: "get",
    url: "gift-items"
  };
  return api(option);
};

const getGiftItemDetail = (params) => {
  return api({ method: "get", url: `gift-items/${params}`, params });
};

const giftItemExchange = (data) => {
  return api({
    method: "post",
    url: `gift-items/${data?.gift_id}`,
    data
  });
};

const getGiftItemHistory = () => {
  const option = {
    method: 'get',
    url: 'gift-items-history'
  };
  return api(option);
};

export const giftService = {
  getGiftItem,
  getGiftItemDetail,
  giftItemExchange,
  getGiftItemHistory
};
