export const domainUrl = process.env.NEXT_PUBLIC_SERVER_NAME;
export const thitotDomainUrl = process.env.NEXT_PUBLIC_THITOT_SERVER_NAME;
export const facebookLink = process.env.NEXT_PUBLIC_FACEBOOK_LINK;
export const youtubeLink = process.env.NEXT_PUBLIC_YOUTUBE_LINK;
export const appVersion = process.env.NEXT_PUBLIC_VERSION;
export const nodeEnv = process.env.NODE_ENV;
export const googleAdsClient = process.env.NEXT_PUBLIC_GOOGLE_AD_ID;
export const recaptchaSiteKey = process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY_CAPTCHA;
export const recaptchaSecretKey = process.env.NEXT_PUBLIC_GOOGLE_SECRET_KEY_CAPTCHA;
export const isDebuggerMessage = process.env.NEXT_PUBLIC_DEBUGGER;
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION;
export const secretKeyAdsInHouse = process.env.NEXT_PUBLIC_APP_CLIENT_SECRET_KEY_ADS_IN_HOUSE;
export const clientKeyAdsInHouse = process.env.NEXT_PUBLIC_APP_CLIENT_KEY_ADS_IN_HOUSE;
export const uidAdsInHouse = process.env.NEXT_PUBLIC_UID_ADS_IN_HOUSE;
export const appMetaDescription = process.env.NEXT_PUBLIC_META_DESCRIPTION;
export const ENABLE_INPUT_DOCX = process.env.NEXT_PUBLIC_ENABLE_INPUT_DOCX === 'true';

const clientInfo = {
  timezone: "Asia/Ho_Chi_Minh",
  device_name: "web",
  app_version: appVersion,
  primary_language: "vi-VN",
  platform: "web"
};

// ENV Local
export const getConfigServer = async () => {
  const zlib = (await import("react-zlib-js")).default;
  return {
    ci: zlib.gzipSync(JSON.stringify(clientInfo)).toString("base64"),
    language: "vi_VN",
    timezone: "Asia/Ho_Chi_Minh",
    platform: "web",
    os_version: appVersion
  };
};
// export const CONFIG_SERVER: any = getConfigServer();

export const FIREBASE_INFO = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL
};
