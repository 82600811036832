import React from "react";

export default function IconExamsOnline({ width, height, fill }: any) {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.68018 14.6934V21.32C5.68018 23.7467 5.68018 23.7467 7.97351 25.2934L14.2802 28.9334C15.2268 29.48 16.7735 29.48 17.7202 28.9334L24.0268 25.2934C26.3202 23.7467 26.3202 23.7467 26.3202 21.32V14.6934C26.3202 12.2667 26.3202 12.2667 24.0268 10.72L17.7202 7.08004C16.7735 6.53338 15.2268 6.53338 14.2802 7.08004L7.97351 10.72C5.68018 12.2667 5.68018 12.2667 5.68018 14.6934Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3332 10.1733V6.66663C23.3332 3.99996 21.9998 2.66663 19.3332 2.66663H12.6665C9.99984 2.66663 8.6665 3.99996 8.6665 6.66663V10.08"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8399 14.6533L17.5999 15.8399C17.7199 16.0266 17.9865 16.2133 18.1865 16.2666L19.5465 16.6133C20.3865 16.8266 20.6132 17.5466 20.0665 18.2133L19.1732 19.2933C19.0399 19.4666 18.9332 19.7733 18.9465 19.9866L19.0265 21.3866C19.0799 22.2533 18.4665 22.6933 17.6665 22.3733L16.3599 21.8533C16.1599 21.7733 15.8265 21.7733 15.6265 21.8533L14.3199 22.3733C13.5199 22.6933 12.9065 22.2399 12.9599 21.3866L13.0399 19.9866C13.0532 19.7733 12.9465 19.4533 12.8132 19.2933L11.9199 18.2133C11.3732 17.5466 11.5999 16.8266 12.4399 16.6133L13.7999 16.2666C14.0132 16.2133 14.2799 16.0133 14.3865 15.8399L15.1465 14.6533C15.6265 13.9333 16.3732 13.9333 16.8399 14.6533Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
