import { ACTION_TYPES } from "./actionTypes";

const handleGetLessonDetail = (params: any) => {
  return {
    type: ACTION_TYPES.GET_LESSON_DETAIL,
    params
  };
};
const handleGetPracticeDetail = (params: any) => {
  return {
    type: ACTION_TYPES.GET_PRACTICE_DETAIL,
    params
  };
};
const handleGetExamsDetail = (params: any) => {
  return {
    type: ACTION_TYPES.GET_EXAM_DETAIL,
    params
  };
};
const handlePostQuizStart = (params: any) => {
  return {
    type: ACTION_TYPES.POST_QUIZ_START_CORE,
    params
  };
};
const handlePutQuizStart = (params: any) => {
  return {
    type: ACTION_TYPES.PUT_QUIZ_START,
    params
  };
};
const handlePatchQuizStart = (params: any) => {
  return {
    type: ACTION_TYPES.PATCH_QUIZ_START,
    params
  };
};
const handleQuizSubmit = (params: any) => {
  return {
    type: ACTION_TYPES.QUIZ_SUBMIT,
    params
  };
};

const handleGetQuizSolution = (params: any) => {
  return {
    type: ACTION_TYPES.GET_QUIZ_SOLUTION,
    params
  };
};
const handleGetAnalysis = (params: any) => {
  return {
    type: ACTION_TYPES.GET_QUIZ_ANALYSIS,
    params
  };
};
const handleCleanQuizInfo = () => {
  return {
    type: ACTION_TYPES.CLEAN_QUIZ_INFO
  };
};
const handleSaveAnswerTotal = (params: any) => {
  return {
    type: ACTION_TYPES.SAVE_ANSWER_TOTAL,
    params
  };
};
const handleUpdateQuizInfoSolution = (params: any) => {
  return {
    type: ACTION_TYPES.UPDATE_QUIZ_INFO_SOLUTION,
    params
  };
};
const handleCleanNotify = () => {
  return {
    type: ACTION_TYPES.CLEAN_NOTIFY
  };
};
const handleSaveQuizTime = (params: any) => {
  return {
    type: ACTION_TYPES.SAVE_QUIZ_START_TIME,
    params
  };
};
const handleCleanCongratulation = () => {
  return {
    type: ACTION_TYPES.CLEAN_CONGRATULATION
  };
};

const handleReportQuiz = (params: any) => {
  return {
    type: ACTION_TYPES.REPORT_QUIZ,
    params
  };
};

export const quizActions = {
  handleGetLessonDetail,
  handleGetPracticeDetail,
  handleGetExamsDetail,
  handlePostQuizStart,
  handlePutQuizStart,
  handlePatchQuizStart,
  handleQuizSubmit,
  handleGetQuizSolution,
  handleGetAnalysis,
  handleCleanQuizInfo,
  handleSaveAnswerTotal,
  handleCleanNotify,
  handleSaveQuizTime,
  handleCleanCongratulation,
  handleUpdateQuizInfoSolution,
  handleReportQuiz,
};
