export const ACTION_TYPES = {
  GET_LIST_POST: "GET_LIST_POST",
  GET_LIST_POST_SUCCESS: "GET_LIST_POST_SUCCESS",
  GET_LIST_POST_FAILED: "GET_LIST_POST_FAILED",
  GET_DETAIL_POST: "GET_DETAIL_POST",
  GET_DETAIL_POST_SUCCESS: "GET_DETAIL_POST_SUCCESS",
  GET_DETAIL_POST_FAILED: "GET_DETAIL_POST_FAILED",
  HANDLE_CHANGE_TAB: "HANDLE_CHANGE_TAB",
  HANDLE_VOTE: "HANDLE_VOTE",
  HANDLE_VOTE_SUCCESS: "HANDLE_VOTE_SUCCESS",
  HANDLE_VOTE_FAILED: "HANDLE_VOTE_FAILED",
  HANDLE_SAVE_POST: "HANDLE_SAVE_POST",
  HANDLE_SAVE_POST_SUCCESS: "HANDLE_SAVE_POST_SUCCESS",
  HANDLE_SAVE_POST_FAILED: "HANDLE_SAVE_POST_FAILED",
  GET_CREATION_INFO_POST: "GET_CREATION_INFO_POST",
  GET_CREATION_INFO_POST_SUCCESS: "GET_CREATION_INFO_POST_SUCCESS",
  GET_CREATION_INFO_POST_FAILED: "GET_CREATION_INFO_POST_FAILED",
  CHOOSE_POST_TYPE: "CHOOSE_POST_TYPE",
  CHOOSE_GRADE_FILTER: "CHOOSE_GRADE_FILTER",
  CHOOSE_SUBJECT: "CHOOSE_SUBJECT",
  UPDATE_DEFAULT_VALUE_LIST_GRADE_CHOOSE: "UPDATE_DEFAULT_VALUE_LIST_GRADE_CHOOSE",
  CLEAR_FILTER: "CLEAR_FILTER",
  HANDLE_CLOSE_POST: "HANDLE_CLOSE_POST",
  HANDLE_CLOSE_POST_SUCCESS: "HANDLE_CLOSE_POST_SUCCESS",
  HANDLE_CLOSE_POST_FAILED: "HANDLE_CLOSE_POST_FAILED",
  HANDLE_DELETE_POST: "HANDLE_DELETE_POST",
  HANDLE_DELETE_POST_SUCCESS: "HANDLE_DELETE_POST_SUCCESS",
  HANDLE_DELETE_POST_FAILED: "HANDLE_DELETE_POST_FAILED",
  CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
  HANDLE_COMPLAIN: "HANDLE_COMPLAIN",
  HANDLE_COMPLAIN_SUCCESS: "HANDLE_COMPLAIN_SUCCESS",
  HANDLE_COMPLAIN_FAILED: "HANDLE_COMPLAIN_FAILED",
  SAVE_SUBSCRIBE_SUBJECTS: "SAVE_SUBSCRIBE_SUBJECTS",
  GET_CREATION_INFO_SUBSCRIBE: "GET_CREATION_INFO_SUBSCRIBE",
  GET_CREATION_INFO_SUBSCRIBE_SUCCESS: "GET_CREATION_INFO_SUBSCRIBE_SUCCESS",
  GET_CREATION_INFO_SUBSCRIBE_FAILED: "GET_CREATION_INFO_SUBSCRIBE_FAILED",
  POST_SUBSCRIBE_TOPIC: "POST_SUBSCRIBE_TOPIC",
  POST_SUBSCRIBE_TOPIC_SUCCESS: "POST_SUBSCRIBE_TOPIC_SUCCESS",
  POST_SUBSCRIBE_TOPIC_FAILED: "POST_SUBSCRIBE_TOPIC_FAILED",
  GET_QA_RANKINGS: "GET_QA_RANKINGS",
  GET_QA_RANKINGS_SUCCESS: "GET_QA_RANKINGS_SUCCESS",
  GET_QA_RANKINGS_FAILED: "GET_QA_RANKINGS_FAILED",
  CREATE_POST: "CREATE_POST",
  CREATE_POST_SUCCESS: "CREATE_POST_SUCCESS",
  CREATE_POST_FAILED: "CREATE_POST_FAILED",
  EDIT_POST: "EDIT_POST",
  EDIT_POST_SUCCESS: "EDIT_POST_SUCCESS",
  EDIT_POST_FAILED: Symbol("EDIT_POST_FAILED"),
  GET_POST_DETAIL: "GET_POST_DETAIL",
  GET_POST_DETAIL_SUCCESS: "GET_POST_DETAIL_SUCCESS",
  GET_POST_DETAIL_FAILED: "GET_POST_DETAIL_FAILED",
  HANDLE_VOTE_COMMENT: "HANDLE_VOTE_COMMENT",
  HANDLE_VOTE_COMMENT_SUCCESS: "HANDLE_VOTE_COMMENT_SUCCESS",
  HANDLE_VOTE_COMMENT_FAILED: "HANDLE_VOTE_COMMENT_FAILED",
  HANDLE_COMMENT: "HANDLE_COMMENT",
  HANDLE_COMMENT_SUCCESS: "HANDLE_COMMENT_SUCCESS",
  HANDLE_COMMENT_FAILED: "HANDLE_COMMENT_FAILED",
  DELETE_COMMENT: "DELETE_COMMENT",
  DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
  DELETE_COMMENT_FAILED: "DELETE_COMMENT_FAILED",
  EDIT_COMMENT: "EDIT_COMMENT",
  EDIT_COMMENT_SUCCESS: "EDIT_COMMENT_SUCCESS",
  EDIT_COMMENT_FAILED: "EDIT_COMMENT_FAILED",
  CLEAN_CHOOSE_FILTER: "CLEAN_CHOOSE_FILTER",
  UNSET_CLEAN_FILTER: "UNSET_CLEAN_FILTER",
  ACCEPT_COMMENT: "ACCEPT_COMMENT",
  ACCEPT_COMMENT_SUCCESS: "ACCEPT_COMMENT_SUCCESS",
  ACCEPT_COMMENT_FAILED: "ACCEPT_COMMENT_FAILED",
  HANDLE_OPEN_MODAL_EDIT_POST: "HANDLE_OPEN_MODAL_EDIT_POST",
  HANDLE_SAVE_OLD_FILTER: "HANDLE_SAVE_OLD_FILTER",
  HANDLE_CHOOSE_PARENT_SUBJECT_ID: Symbol("HANDLE_CHOOSE_PARENT_SUBJECT_ID"),
  HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND: Symbol("HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND"),
  HANDLE_OPEN_MENU_ACTION: Symbol("HANDLE_OPEN_MENU_ACTION"),
  HANDLE_CHANGE_PAGE: Symbol("HANDLE_CHANGE_PAGE"),
  HANDLE_CLEAR_PAGE: Symbol("HANDLE_CLEAR_PAGE"),
  HANDLE_CHOOSE_TIME: Symbol("HANDLE_CHOOSE_TIME"),
  HANDLE_CLEAR_OLD_FILTER: Symbol("HANDLE_CLEAR_OLD_FILTER"),
  HANDLE_SAVE_IS_FILTER: Symbol("HANDLE_SAVE_IS_FILTER"),
  CLEAN_FILTER_WHEN_DIRECT_PAGE: Symbol("CLEAN_FILTER_WHEN_DIRECT_PAGE")
};
