/* eslint-disable react/require-default-props */
import React from "react";


export default function IconHome({width, height, fill}: any) {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0266 3.78672L4.83996 9.38672C3.63996 10.3201 2.66663 12.3067 2.66663 13.8134V23.6934C2.66663 26.7867 5.18663 29.3201 8.27996 29.3201H23.72C26.8133 29.3201 29.3333 26.7867 29.3333 23.7067V14.0001C29.3333 12.3867 28.2533 10.3201 26.9333 9.40005L18.6933 3.62672C16.8266 2.32005 13.8266 2.38672 12.0266 3.78672Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23.9866V19.9866"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}


