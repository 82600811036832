import { call, debounce, put } from "redux-saga/effects";
import { DEBOUNCE_TIMES_API } from "../../common/utils/constant";
import ACTION_TYPES_SEARCH from "./actionTypes";
import { searchService } from "./services";

function* getTopicBySearch(action) {
  try {
    const res = yield call(searchService.getTopicBySearch, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES_SEARCH.GET_TOPIC_BY_SEARCH_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES_SEARCH.GET_TOPIC_BY_SEARCH_FAILED,
      params: err?.data?.error
    });
  }
}

export function* searchSaga() {
  yield debounce(
    DEBOUNCE_TIMES_API,
    ACTION_TYPES_SEARCH.GET_TOPIC_BY_SEARCH,
    getTopicBySearch
  );
}
