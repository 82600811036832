import { call, put, takeLatest } from "redux-saga/effects";
import { ACTION_TYPES } from "./actionTypes";
import { courseServices } from "./services";

function* viewCourseDetail(action) {
  try {
    const res = yield call(courseServices.viewCourseDetail, action.payload);
    if (res) {
      yield put({
        type: ACTION_TYPES.VIEW_COURSE_DETAIL_SUCCESS,
        payload: res?.data?.data
      });
      action.callback();
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.VIEW_COURSE_DETAIL_FAILED,
      payload: err.data?.error
    });
  }
}

function* courseSaga() {
  yield takeLatest(ACTION_TYPES.VIEW_COURSE_DETAIL, viewCourseDetail);
}
export default courseSaga;
