import React from "react";

export default function IconLop12() {
  return (
    <svg
      width="52"
      height="55"
      viewBox="0 0 52 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5 33.5089V21.2749C51.4998 18.6268 50.8075 16.0255 49.4927 13.7322C48.178 11.439 46.287 9.53466 44.0099 8.21063L33.4901 2.09362C31.213 0.768792 28.6297 0.0712891 26 0.0712891C23.3703 0.0712891 20.787 0.768792 18.5099 2.09362L7.99006 8.21063C5.71297 9.53466 3.82202 11.439 2.50725 13.7322C1.19249 16.0255 0.500212 18.6268 0.500002 21.2749V33.5089C0.498782 36.1573 1.19045 38.7592 2.50536 41.0527C3.82027 43.3462 5.71201 45.2503 7.99006 46.5732L18.5099 52.6973C20.7881 54.0187 23.371 54.7141 26 54.7141C28.629 54.7141 31.2119 54.0187 33.4901 52.6973L44.0099 46.5732C46.288 45.2503 48.1797 43.3462 49.4946 41.0527C50.8095 38.7592 51.5012 36.1573 51.5 33.5089Z"
        fill="url(#paint0_linear_2_31791)"
      />
      <g style={{ mixBlendMode: "screen" }} opacity="0.4">
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M13.7606 4.854L7.98978 8.21055C7.14392 8.70265 6.34853 9.27789 5.61523 9.92786L42.7343 47.3182L44.0097 46.5731C46.3367 45.2196 48.2575 43.2582 49.5691 40.8961L13.7606 4.854Z"
          fill="url(#paint1_linear_2_31791)"
        />
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M18.354 2.17871L15.7188 3.71151L50.5478 38.7814C50.9469 37.7022 51.2235 36.5809 51.3722 35.439L18.354 2.17871Z"
          fill="url(#paint2_linear_2_31791)"
        />
      </g>
      <path
        d="M25.9999 42.223C34.1331 42.223 40.7263 35.5829 40.7263 27.3918C40.7263 19.2007 34.1331 12.5605 25.9999 12.5605C17.8667 12.5605 11.2734 19.2007 11.2734 27.3918C11.2734 35.5829 17.8667 42.223 25.9999 42.223Z"
        fill="white"
      />
      <path
        d="M22.5016 21.6888V34.0713H21.0022V23.2608H20.9296L17.9066 25.2681V23.7445L21.0022 21.6888H22.5016ZM26.1054 34.0713V32.983L30.1926 28.5088C30.6722 27.9848 31.0672 27.5294 31.3776 27.1424C31.688 26.7514 31.9177 26.3846 32.0669 26.042C32.22 25.6954 32.2966 25.3326 32.2966 24.9537C32.2966 24.5184 32.1918 24.1415 31.9822 23.8231C31.7767 23.5046 31.4945 23.2588 31.1358 23.0854C30.777 22.9121 30.3739 22.8255 29.9265 22.8255C29.4509 22.8255 29.0357 22.9242 28.681 23.1217C28.3304 23.3152 28.0583 23.5873 27.8648 23.938C27.6754 24.2886 27.5806 24.6998 27.5806 25.1714H26.1537C26.1537 24.4458 26.321 23.809 26.6556 23.2608C26.9901 22.7126 27.4456 22.2853 28.022 21.979C28.6024 21.6727 29.2534 21.5195 29.9749 21.5195C30.7004 21.5195 31.3433 21.6727 31.9036 21.979C32.4639 22.2853 32.9033 22.6985 33.2217 23.2185C33.5401 23.7384 33.6993 24.3168 33.6993 24.9537C33.6993 25.4092 33.6167 25.8546 33.4514 26.2899C33.2902 26.7212 33.0081 27.2029 32.605 27.7349C32.2059 28.263 31.6517 28.9079 30.9423 29.6697L28.1611 32.6444V32.7411H33.917V34.0713H26.1054Z"
        fill="#606060"
      />
      <g style={{ mixBlendMode: "screen" }}>
        <path
          style={{ mixBlendMode: "screen" }}
          d="M26.0011 51.519C23.9286 51.5191 21.8924 50.971 20.0964 49.9294L9.57655 43.8124C7.78362 42.7649 6.29481 41.2622 5.25872 39.4542C4.22263 37.6462 3.67547 35.5961 3.67188 33.5086V21.2746C3.67458 19.1878 4.22142 17.1384 5.25767 15.3314C6.29391 13.5243 7.78321 12.0231 9.57655 10.9779L20.0964 4.86085C21.8989 3.83873 23.9325 3.30176 26.0011 3.30176C28.0697 3.30176 30.1033 3.83873 31.9058 4.86085L42.4257 10.9779C44.219 12.0231 45.7083 13.5243 46.7445 15.3314C47.7808 17.1384 48.3276 19.1878 48.3303 21.2746V33.5086C48.3267 35.5961 47.7796 37.6462 46.7435 39.4542C45.7074 41.2622 44.2186 42.7649 42.4257 43.8124L31.9058 49.9294C30.1102 50.9719 28.0737 51.5201 26.0011 51.519V51.519ZM26.0011 3.97381C24.053 3.97466 22.1389 4.48838 20.4487 5.46403L9.92885 11.5881C8.24305 12.5708 6.84295 13.9821 5.86857 15.6807C4.89419 17.3793 4.37968 19.3058 4.37649 21.2675V33.5015C4.37845 35.4644 4.89237 37.3923 5.86681 39.0923C6.84125 40.7923 8.24201 42.2046 9.92885 43.1879L20.4487 49.3049C22.1376 50.2844 24.0522 50.8 26.0011 50.8C27.95 50.8 29.8646 50.2844 31.5535 49.3049L42.0734 43.1879C43.7602 42.2046 45.161 40.7923 46.1354 39.0923C47.1098 37.3923 47.6238 35.4644 47.6257 33.5015V21.2746C47.6225 19.3129 47.108 17.3864 46.1336 15.6878C45.1593 13.9892 43.7592 12.5779 42.0734 11.5952L31.5535 5.47113C29.8633 4.49548 27.9492 3.98176 26.0011 3.98091V3.97381Z"
          fill="url(#paint3_linear_2_31791)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_31791"
          x1="7.10929"
          y1="8.36675"
          x2="45.1587"
          y2="46.1472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B06AB3" />
          <stop offset="1" stopColor="#4568DC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_31791"
          x1="15612.3"
          y1="6318.86"
          x2="18354.2"
          y2="6318.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4568DC" />
          <stop offset="1" stopColor="#B06AB3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_31791"
          x1="13186.5"
          y1="5155.84"
          x2="14990.5"
          y2="5155.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4568DC" />
          <stop offset="1" stopColor="#B06AB3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_31791"
          x1="15737.3"
          y1="7262.4"
          x2="18567.7"
          y2="7262.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4568DC" />
          <stop offset="1" stopColor="#B06AB3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
