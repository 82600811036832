const ACTION_TYPES = {
  GET_ACHIEVEMENTS: "GET_ACHIEVEMENTS",
  GET_ACHIEVEMENTS_SUCCESS: "GET_ACHIEVEMENTS_SUCCESS",
  GET_ACHIEVEMENTS_FAILED: Symbol("GET_ACHIEVEMENTS_FAILED"),
  GET_INFO_ACCOUNT: "GET_INFO_ACCOUNT",
  GET_INFO_ACCOUNT_SUCCESS: "GET_INFO_ACCOUNT_SUCCESS",
  GET_DIAMOND_HISTORY: "GET_DIAMOND_HISTORY",
  GET_DIAMOND_HISTORY_SUCCESS: "GET_DIAMOND_HISTORY_SUCCESS",
  GET_DIAMOND_HISTORY_FAILED: "GET_DIAMOND_HISTORY_FAILED",
  UPDATE_INFO_ACCOUNT: "UPDATE_INFO_ACCOUNT",
  UPDATE_INFO_ACCOUNT_SUCCESS: "UPDATE_INFO_ACCOUNT_SUCCESS",
  UPDATE_INFO_ACCOUNT_FAILED: "UPDATE_INFO_ACCOUNT_FAILED",
  CLEAR_NOTIFY: "CLEAR_NOTIFY",
  GET_EXAM_HISTORY: "GET_EXAM_HISTORY",
  GET_EXAM_HISTORY_SUCCESS: "GET_EXAM_HISTORY_SUCCESS",
  GET_EXAM_HISTORY_FAILED: "GET_EXAM_HISTORY_FAILED",
  POST_GIFT_CODE: "POST_GIFT_CODE",
  POST_GIFT_CODE_SUCCESS: "POST_GIFT_CODE_SUCCESS",
  POST_GIFT_CODE_FAILED: "POST_GIFT_CODE_FAILED",
  GET_LIST_GIFT_CODE: "GET_LIST_GIFT_CODE",
  GET_LIST_GIFT_CODE_SUCCESS: "GET_LIST_GIFT_CODE_SUCCESS",
  GET_LIST_GIFT_CODE_FAILED: "GET_LIST_GIFT_CODE_FAILED",
  GET_LIST_MISSIONS: "GET_MISSIONS",
  GET_LIST_MISSIONS_SUCCESS: "GET_LIST_MISSIONS_SUCCESS",
  GET_LIST_MISSIONS_FAILED: "GET_LIST_MISSIONS_FAILED",
  POST_MISSIONS: "POST_MISSIONS",
  POST_MISSIONS_SUCCESS: "POST_MISSIONS_SUCCESS",
  POST_MISSIONS_FAILED: "POST_MISSIONS_FAILED",
  POST_REF_CODE: "POST_REF_CODE",
  POST_REF_CODE_SUCCESS: "POST_REF_CODE_SUCCESS",
  POST_REF_CODE_FAILED: "POST_REF_CODE_FAILED",
  CHANGE_REF_CODE: "CHANGE_REF_CODE",
  POST_CONFIG_NOTIFICATIONS: "POST_CONFIG_NOTIFICATIONS",
  POST_CONFIG_NOTIFICATIONS_SUCCESS: "POST_CONFIG_NOTIFICATIONS_SUCCESS",
  POST_CONFIG_NOTIFICATIONS_FAILED: "POST_CONFIG_NOTIFICATIONS_FAILED",
  GET_GOALS: "GET_GOALS",
  GET_GOALS_SUCCESS: "GET_GOALS_SUCCESS",
  CHANGE_TARGET: "CHANGE_TARGET",
  POST_GOALS: "POST_GOALS",
  POST_GOALS_SUCCESS: "POST_GOALS_SUCCESS",
  POST_GOALS_FAILED: "POST_GOALS_FAILED",
  GET_DETAIL_ACCOUNT: "GET_DETAIL_ACCOUNT",
  GET_DETAIL_ACCOUNT_SUCCESS: Symbol("GET_DETAIL_ACCOUNT_SUCCESS"),
  GET_DETAIL_ACCOUNT_FAILED: Symbol("GET_DETAIL_ACCOUNT_FAILED"),
  GET_EXTRA_INFO: "GET_EXTRA_INFO",
  GET_EXTRA_INFO_SUCCESS: Symbol("GET_EXTRA_INFO_SUCCESS"),
  GET_EXTRA_INFO_FAILED: Symbol("GET_EXTRA_INFO_FAILED"),
  GIFT_DIAMOND: "GIFT_DIAMOND",
  GIFT_DIAMOND_SUCCESS: Symbol("GIFT_DIAMOND_SUCCESS"),
  GIFT_DIAMOND_FAILED: Symbol("GIFT_DIAMOND_FAILED"),
  CLEAR_DATA_ACCOUNT_INFO: Symbol("CLEAR_DATA_ACCOUNT_INFO"),
  GET_INFO_ACCOUNT_FAILED: Symbol("GET_INFO_ACCOUNT_FAILED"),
  CLEAN_ACCOUNT_STATE: Symbol("CLEAN_ACCOUNT_STATE"),
  GET_ACTIVITIES_INFO: "GET_ACTIVITIES_INFO",
  GET_ACTIVITIES_INFO_SUCCESS: "GET_ACTIVITIES_INFO_SUCCESS",
  GET_ACTIVITIES_INFO_FAILED: "GET_ACTIVITIES_INFO_FAILED",
  CREATE_TEACHER_INFO: "CREATE_TEACHER_INFO",
  CREATE_TEACHER_INFO_SUCCESS: "CREATE_TEACHER_INFO_SUCCESS",
  CREATE_TEACHER_INFO_FAILED: "CREATE_TEACHER_INFO_FAILED",
  GET_TEACHER_INFO: "GET_TEACHER_INFO",
  GET_TEACHER_INFO_SUCCESS: "GET_TEACHER_INFO_SUCCESS",
  GET_TEACHER_INFO_FAILED: "GET_TEACHER_INFO_FAILED",
  GET_GROUP_OF_MEMBER: "GET_GROUP_OF_MEMBER",
  GET_GROUP_OF_MEMBER_SUCCESS: "GET_GROUP_OF_MEMBER_SUCCESS",
  GET_GROUP_OF_MEMBER_FAILED: "GET_GROUP_OF_MEMBER_FAILED",
  GET_GROUP_OF_TEACHER: "GET_GROUP_OF_TEACHER",
  GET_GROUP_OF_TEACHER_SUCCESS: "GET_GROUP_OF_TEACHER_SUCCESS",
  GET_GROUP_OF_TEACHER_FAILED: "GET_GROUP_OF_TEACHER_FAILED",
  UPDATE_GROUP: "UPDATE_GROUP",
  UPDATE_GROUP_SUCCESS: "UPDATE_GROUP_SUCCESS",
  UPDATE_GROUP_FAILED: "UPDATE_GROUP_FAILED",
  GET_GROUP_DETAIL: "GET_GROUP_DETAIL",
  GET_GROUP_DETAIL_SUCCESS: "GET_GROUP_DETAIL_SUCCESS",
  GET_GROUP_DETAIL_FAILED: "GET_GROUP_DETAIL_FAILED",
  JOIN_GROUP: "JOIN_GROUP",
  JOIN_GROUP_SUCCESS: "JOIN_GROUP_SUCCESS",
  JOIN_GROUP_FAILED: "JOIN_GROUP_FAILED"
};
export default ACTION_TYPES;
