import api from "../../../utils/helpers/api";

const getRotationData = () => {
  return api({
    method: "get",
    url: "/lucky-draw"
  });
};
const startWheel = (data) => {
  return api({ method: "post", url: `/lucky-draw/${data?.lucky_circle_id}/start`, data });
};

export const rotationServices = {
  getRotationData,
  startWheel
};
