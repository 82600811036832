export const ACTION_TYPES: { [x: string]: any } = {
  GET_GRADE: "GET_GRADE",
  GET_GRADE_SUCCESS: "GET_GRADE_SUCCESS",
  GET_TOP: "GET_TOP",
  GET_TOP_SUCCESS: Symbol("GET_TOP_SUCCESS"),
  GET_STATIC_DATA: "GET_STATIC_DATA",
  GET_STATIC_DATA_SUCCESS: Symbol("GET_STATIC_DATA_SUCCESS"),
  COMPLETE_MISSION: "COMPLETE_MISSION",
  COMPLETE_MISSION_SUCCESS: "COMPLETE_MISSION_SUCCESS",
  COMPLETE_MISSION_FAILED: "COMPLETE_MISSION_FAILED",
  DELETE_CONGRATULATION: "DELETE_CONGRATULATION",
  DELETE_NOTIFY: Symbol("DELETE_NOTIFY"),
  CHOOSE_GRADE: "CHOOSE_GRADE",
  GET_SUBJECTS: "GET_SUBJECTS",
  GET_SUBJECTS_SUCCESS: "GET_SUBJECTS_SUCCESS",
  SAVE_ACCESS_TOKEN: "SAVE_ACCESS_TOKEN",
  SAVE_REFRESH_TOKEN: "SAVE_REFRESH_TOKEN",
  SAVE_PROVIDER_ID: "SAVE_PROVIDER_ID",
  SAVE_ID_TOKEN: "SAVE_ID_TOKEN",
  SAVE_GRADE: "SAVE_GRADE",
  SAVE_ADS_FIREBASE_REMOTE: "SAVE_ADS_FIREBASE_REMOTE",
  CLEAN_ACTION: "CLEAN_ACTION",
  GET_NOTIFICATION: "GET_NOTIFICATION",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SHOW_DIRECT_APP: "SHOW_DIRECT_APP",
  GET_SUGGESTED_FRIEND: "GET_SUGGESTED_FRIEND",
  GET_SUGGESTED_FRIEND_SUCCESS: "GET_SUGGESTED_FRIEND_SUCCESS",
  GET_SUGGESTED_FRIEND_FAILED: "GET_SUGGESTED_FRIEND_FAILED",
  SAVE_LOCATION_PERMISSION: "SAVE_LOCATION_PERMISSION",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  UPDATE_LOCATION_SUCCESS: "UPDATE_LOCATION_SUCCESS",
  SERVER_ERROR: Symbol("SERVER_ERROR"),
  CHOOSE_BOOK: Symbol("CHOOSE_BOOK"),
  CHANGE_SUBJECT: Symbol("CHANGE_SUBJECT"),
  OPEN_MODAL_LOGIN: Symbol("OPEN_MODAL_LOGIN"),
  GET_LEARNING_HISTORY: "GET_LEARNING_HISTORY",
  GET_LEARNING_HISTORY_SUCCESS: Symbol("GET_LEARNING_HISTORY_SUCCESS"),
  GET_LEARNING_HISTORY_FAILED: Symbol("GET_LEARNING_HISTORY_FAILED"),
  OPEN_MODAL_CONFIRM_LOGIN: Symbol("OPEN_MODAL_CONFIRM_LOGIN"),
  HANDLE_DIRECT_URL: Symbol("HANDLE_DIRECT_URL"),
  SAVE_AUTH_CODE: Symbol("SAVE_AUTH_CODE"),
  SAVE_CONGRATULATION: Symbol("SAVE_CONGRATULATION"),
  SAVE_NOTIFY: Symbol("SAVE_NOTIFY"),
  OPEN_MODAL_CONFIRM_PREMIUM: Symbol("OPEN_MODAL_CONFIRM_PREMIUM"),
  GET_NEWS: "GET_NEWS",
  GET_NEWS_SUCCESS: Symbol("GET_NEWS_SUCCESS"),
  GET_NEWS_FAILED: Symbol("GET_NEWS_FAILED"),
  OPEN_MODAL_CHANGE_AVATAR: Symbol("OPEN_MODAL_CHANGE_AVATAR"),
  CHANGE_TAB: Symbol("CHANGE_TAB"),
  GET_TIME_SERVER: "GET_TIME_SERVER",
  GET_TIME_SERVER_SUCCESS: "GET_TIME_SERVER_SUCCESS",
  SAVE_IS_UPDATE_INFO: "SAVE_IS_UPDATE_INFO",
  DELETE_KEYWORD: Symbol("DELETE_KEYWORD"),
  ADD_KEYWORD: Symbol("ADD_KEYWORD"),
  SEARCH: Symbol("SEARCH"),
  SET_IS_NEW_MEMBER: Symbol("SET_IS_NEW_MEMBER"),
  SET_IS_EMPTY_NAME: Symbol("SET_IS_EMPTY_NAME"),
  HANDLE_LOGOUT: Symbol("HANDLE_LOGOUT"),
  ADD_USER_INFOS: Symbol("ADD_USER_INFOS"),
  ADD_FIRE_BASE_INFOS: Symbol("ADD_FIRE_BASE_INFOS"),
  GET_TOP_FAILED: Symbol("GET_TOP_FAILED"),
  POST_ACTION_CLICK_ADS_IN_HOUSE: Symbol("POST_ACTION_CLICK_ADS_IN_HOUSE"),
  POST_ACTION_CLICK_ADS_IN_HOUSE_SUCCESS: Symbol("POST_ACTION_CLICK_ADS_IN_HOUSE_SUCCESS"),
  POST_ACTION_CLICK_ADS_IN_HOUSE_FAILED: Symbol("POST_ACTION_CLICK_ADS_IN_HOUSE_FAILED"),
  SAVE_IS_SHOW_DIALOG_ADS_IN_HOUSE_FIRST_TIME: Symbol("SAVE_IS_SHOW_DIALOG_ADS_IN_HOUSE_FIRST_TIME"),
  GET_ADS_IN_HOUSE: "GET_ADS_IN_HOUSE",
  GET_ADS_IN_HOUSE_SUCCESS: Symbol("GET_ADS_IN_HOUSE_SUCCESS"),
  GET_ADS_IN_HOUSE_FAILED: Symbol("GET_ADS_IN_HOUSE_FAILED"),
  CLEAR_ADS_IN_HOUSE: Symbol("CLEAR_ADS_IN_HOUSE"),
  GET_TOP_EXTRA: Symbol("GET_TOP_EXTRA"),
  GET_TOP_EXTRA_SUCCESS: Symbol("GET_TOP_EXTRA_SUCCESS"),
  GET_TOP_EXTRA_FAILED: Symbol("GET_TOP_EXTRA_FAILED"),
  CLEAN_INVALID_TOKEN: Symbol("CLEAN_INVALID_TOKEN"),
  CALCULATE_DIFF_TIME_SECONDS: Symbol("CALCULATE_DIFF_TIME_SECONDS"),
  GET_USER_INFO: Symbol("GET_USER_INFO"),
  GET_USER_INFO_SUCCESS: Symbol("GET_USER_INFO_SUCCESS"),
  GET_SURVEY: Symbol("GET_SURVEY"),
  GET_SURVEY_SUCCESS: Symbol("GET_SURVEY_SUCCESS"),
  SET_ROUTER_HISTORY: Symbol("SET_ROUTER_HISTORY"),
  SUBMIT_SURVEY: Symbol("SUBMIT_SURVEY"),
  SUBMIT_SURVEY_SUCCESS: Symbol("SUBMIT_SURVEY_SUCCESS"),
  SUBMIT_SURVEY_FAILED: Symbol("SUBMIT_SURVEY_FAILED"),
  OPEN_DIALOG_ADS_IN_HOUSE: Symbol("OPEN_DIALOG_ADS_IN_HOUSE"),
  HIDE_DIALOG_SURVEY: Symbol("HIDE_DIALOG_SURVEY"),
  SAVE_TIME_SHOW_SURVEY: Symbol("SAVE_TIME_SHOW_SURVEY"),
  GET_DAILY_REPORT: Symbol("GET_DAILY_REPORT"),
  GET_DAILY_REPORT_SUCCESS: Symbol("GET_DAILY_REPORT_SUCCESS"),
  GET_DAILY_REPORT_FAILED: Symbol("GET_DAILY_REPORT_FAILED"),
  CLOSE_DAILY_REPORT_DIALOG: Symbol("CLOSE_DAILY_REPORT_DIALOG"),
  SET_IS_OPEN_TEACHER_ACCOUNT_INFO_DIALOG: "SET_IS_OPEN_TEACHER_ACCOUNT_INFO_DIALOG",
  SET_IS_OPEN_TEACHER_BENEFITS_DIALOG: "SET_IS_OPEN_TEACHER_BENEFITS_DIALOG",
  SET_IS_OPEN_TEACHER_ACCOUNT_FORM_DIALOG: "SET_IS_OPEN_TEACHER_ACCOUNT_FORM_DIALOG",
  SET_OPEN_CLASSES_DIALOG: "SET_OPEN_CLASSES_DIALOG"
};
