import { call, put, takeEvery } from 'redux-saga/effects';
import { changePointAction } from './action';
import { ACTION_TYPES } from './actionTypes';
import { changePointService } from './services';

function* handleGetMemberShip() {
  try {
    const res = yield call(changePointService.getMemberships);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_MEMBER_SHIP_SUCCESS,
        params: res?.data?.data,
      });
    }
  } catch (err) {}
}
function* handleGetVip(action) {
  try {
    const res = yield call(changePointService.getVip, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_VIP_SUCCESS,
        params: res?.data?.data,
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_VIP_FAILED,
      params: err?.data?.error,
    });
  }
}
function * handleGetPurchases() {
  try {
    const res = yield call(changePointService.getPurchase);
    if(res?.data?.data){
      yield put(changePointAction.handleGetPurchasesSuccess(res?.data?.data))
    }
  }catch(err: any){
    yield put({
      type: ACTION_TYPES.GET_PURCHASES_FAILED,
      params: err?.data?.error
    })
  }
}

function* changePointSaga() {
  yield takeEvery(ACTION_TYPES.GET_MEMBER_SHIP, handleGetMemberShip);
  yield takeEvery(ACTION_TYPES.GET_VIP, handleGetVip);
  yield takeEvery(ACTION_TYPES.GET_PURCHASES, handleGetPurchases)
}

export default changePointSaga;
