import React from "react";

export default function IconLop2() {
  return (
    <svg
      width="52"
      height="55"
      viewBox="0 0 52 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5 33.5099V21.2755C51.4991 18.6259 50.805 16.0232 49.4876 13.7296C48.1702 11.4361 46.2761 9.53259 43.9959 8.21087L33.476 2.09368C31.1989 0.768814 28.6156 0.0712891 25.9859 0.0712891C23.3562 0.0712891 20.7729 0.768814 18.4959 2.09368L7.99005 8.21087C5.71248 9.53439 3.82115 11.4386 2.50631 13.7321C1.19147 16.0255 0.499496 18.6272 0.5 21.2755V33.5099C0.499496 36.1582 1.19147 38.7599 2.50631 41.0533C3.82115 43.3468 5.71248 45.251 7.99005 46.5746L18.5099 52.6917C20.787 54.0166 23.3703 54.7141 26 54.7141C28.6297 54.7141 31.213 54.0166 33.4901 52.6917L43.9959 46.5746C46.2761 45.2528 48.1702 43.3494 49.4876 41.0558C50.805 38.7622 51.4991 36.1596 51.5 33.5099V33.5099Z"
        fill="url(#paint0_linear_2_31843)"
      />
      <g style={{ mixBlendMode: "screen" }} opacity="0.4">
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M13.7676 4.854L7.98978 8.21065C7.14359 8.70225 6.34815 9.27754 5.61523 9.928L42.7414 47.3195L44.0097 46.6098C46.3361 45.2555 48.2567 43.2942 49.5691 40.9326L13.7676 4.854Z"
          fill="url(#paint1_linear_2_31843)"
        />
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M18.354 2.18555L15.7188 3.71129L50.5479 38.7893C50.9501 37.7081 51.2291 36.5844 51.3793 35.4398L18.354 2.18555Z"
          fill="url(#paint2_linear_2_31843)"
        />
      </g>
      <path
        d="M25.9999 42.2171C34.1331 42.2171 40.7263 35.5767 40.7263 27.3854C40.7263 19.1941 34.1331 12.5537 25.9999 12.5537C17.8667 12.5537 11.2734 19.1941 11.2734 27.3854C11.2734 35.5767 17.8667 42.2171 25.9999 42.2171Z"
        fill="white"
      />
      <path
        d="M22.4244 32.8569V31.7686L26.5116 27.2945C26.9912 26.7705 27.3862 26.315 27.6966 25.9281C28.007 25.5371 28.2367 25.1703 28.3859 24.8277C28.539 24.481 28.6156 24.1182 28.6156 23.7394C28.6156 23.304 28.5108 22.9272 28.3012 22.6087C28.0956 22.2903 27.8135 22.0444 27.4548 21.8711C27.096 21.6978 26.6929 21.6111 26.2455 21.6111C25.7699 21.6111 25.3547 21.7099 25 21.9074C24.6493 22.1008 24.3773 22.3729 24.1838 22.7236C23.9943 23.0743 23.8996 23.4854 23.8996 23.957H22.4727C22.4727 23.2315 22.64 22.5946 22.9746 22.0464C23.3091 21.4982 23.7646 21.071 24.341 20.7646C24.9214 20.4583 25.5724 20.3051 26.2939 20.3051C27.0194 20.3051 27.6623 20.4583 28.2226 20.7646C28.7829 21.071 29.2222 21.4841 29.5407 22.0041C29.8591 22.5241 30.0183 23.1025 30.0183 23.7394C30.0183 24.1948 29.9357 24.6402 29.7704 25.0755C29.6092 25.5068 29.327 25.9885 28.924 26.5206C28.5249 27.0486 27.9707 27.6935 27.2613 28.4553L24.48 31.43V31.5268H30.236V32.8569H22.4244Z"
        fill="#606060"
      />
      <g style={{ mixBlendMode: "screen" }}>
        <path
          style={{ mixBlendMode: "screen" }}
          d="M25.9992 51.5134C23.9274 51.5151 21.8914 50.9694 20.0945 49.9309L9.57459 43.8066C7.78125 42.7613 6.29195 41.26 5.2557 39.453C4.21946 37.6459 3.67263 35.5964 3.66992 33.5096V21.2752C3.67878 19.1944 4.22853 17.1522 5.26447 15.3519C6.3004 13.5516 7.7864 12.0559 9.57459 11.0137L20.0945 4.88937C21.898 3.87053 23.9313 3.33545 25.9992 3.33545C28.0671 3.33545 30.1003 3.87053 31.9038 4.88937L42.4237 11.0137C44.2171 12.0589 45.7064 13.5602 46.7426 15.3673C47.7789 17.1744 48.3257 19.2239 48.3284 21.3107V33.5096C48.3257 35.5964 47.7789 37.6459 46.7426 39.453C45.7064 41.26 44.2171 42.7613 42.4237 43.8066L31.9038 49.9309C30.1077 50.9713 28.0712 51.5171 25.9992 51.5134V51.5134ZM25.9992 3.96683C24.0506 3.96436 22.1357 4.47832 20.4468 5.45709L9.92689 11.5743C8.24101 12.5587 6.84103 13.9713 5.86674 15.6711C4.89244 17.3709 4.37792 19.2983 4.37454 21.261V33.5096C4.37792 35.4722 4.89244 37.3997 5.86674 39.0995C6.84103 40.7992 8.24101 42.2119 9.92689 43.1963L20.4468 49.3348C22.1356 50.3143 24.0503 50.8299 25.9992 50.8299C27.948 50.8299 29.8627 50.3143 31.5515 49.3348L42.0714 43.2176C43.7573 42.2331 45.1573 40.8205 46.1316 39.1207C47.1059 37.421 47.6204 35.4935 47.6238 33.5309V21.2752C47.6204 19.3125 47.1059 17.3851 46.1316 15.6853C45.1573 13.9855 43.7573 12.5729 42.0714 11.5885L31.5515 5.47129C29.863 4.49158 27.9478 3.97755 25.9992 3.98102V3.96683Z"
          fill="url(#paint3_linear_2_31843)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_31843"
          x1="7.10928"
          y1="8.36699"
          x2="45.1598"
          y2="46.1475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9BF3E3" />
          <stop offset="1" stopColor="#4AC29A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_31843"
          x1="5962.68"
          y1="2223.25"
          x2="8704.53"
          y2="2223.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AC29A" />
          <stop offset="1" stopColor="#BDFFF3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_31843"
          x1="5360.16"
          y1="1625.56"
          x2="7164.94"
          y2="1625.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AC29A" />
          <stop offset="1" stopColor="#BDFFF3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_31843"
          x1="5932.97"
          y1="2609.76"
          x2="8763.43"
          y2="2609.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AC29A" />
          <stop offset="1" stopColor="#BDFFF3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
