export const ACTION_TYPES_HOME = {
  GET_ADS_IN_HOUSE: "GET_ADS_IN_HOUSE",
  GET_ADS_IN_HOUSE_SUCCESS: Symbol("GET_ADS_IN_HOUSE_SUCCESS"),
  GET_ADS_IN_HOUSE_FAILED: Symbol("GET_ADS_IN_HOUSE_FAILED"),
  GET_GIFT_CODE: Symbol("GET_GIFT_CODE"),
  GET_GIFT_CODE_SUCCESS: Symbol("GET_GIFT_CODE_SUCCESS"),
  HANDLE_TAKE_GIFT_CODE: Symbol("HANDLE_TAKE_GIFT_CODE"),
  HANDLE_TAKE_GIFT_CODE_SUCCESS: Symbol("HANDLE_TAKE_GIFT_CODE_SUCCESS"),
  HANDLE_TAKE_GIFT_CODE_FAILED: Symbol("HANDLE_TAKE_GIFT_CODE_FAILED"),
  CLEAR_NOTIFY: Symbol("CLEAR_NOTIFY"),
};
