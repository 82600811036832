export const ACTION_TYPES = {
  GET_GIFT_ITEM: "GET_GIFT_ITEM",
  GET_GIFT_ITEM_SUCCESS: 'GET_GIFT_ITEM_SUCCESS',
  GET_GIFT_ITEM_FAILED: 'GET_GIFT_ITEM_FAILED',
  GET_GIFT_ITEM_DETAIL: "GET_GIFT_ITEM_DETAIL",
  GET_GIFT_ITEM_DETAIL_SUCCESS: "GET_GIFT_ITEM_DETAIL_SUCCESS",
  GET_GIFT_ITEM_DETAIL_FAILED: "GET_GIFT_ITEM_DETAIL_FAILED",
  GET_GIFT_ITEM_EXCHANGE: "GET_GIFT_ITEM_EXCHANGE",
  GET_GIFT_ITEM_EXCHANGE_SUCCESS: "GET_GIFT_ITEM_EXCHANGE_SUCCESS",
  GET_GIFT_ITEM_EXCHANGE_FAILED: "GET_GIFT_ITEM_EXCHANGE_FAILED",
  GET_GIFT_ITEM_HISTORY: "GET_GIFT_ITEM_HISTORY",
  GET_GIFT_ITEM_HISTORY_SUCCESS: "GET_GIFT_ITEM_HISTORY_SUCCESS",
  GET_GIFT_ITEM_HISTORY_FAILED: "GET_GIFT_ITEM_HISTORY_FAILED",
  CLEAR_NOTIFY: 'CLEAR_NOTIFY',
  CLEAN_GIFT_ITEM_EXCHANGE: "CLEAN_GIFT_ITEM_EXCHANGE",
  CLEAN_GIFT_ITEM_DETAIL: "CLEAN_GIFT_ITEM_DETAIL",
};
