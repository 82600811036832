/* eslint-disable react/require-default-props */
import React from "react";

// eslint-disable-next-line no-unused-vars
type IconStatistic = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export default function IconStatistic({
  className,
  fill,
  width,
  height
}: IconStatistic) {
  return (
    <svg
      className={className}
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4456 14.4028H7.47266C6.29041 14.4028 5.33337 15.3598 5.33337 16.5421V26.1502H12.4456V14.4028V14.4028Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4211 5.86401H14.5686C13.3864 5.86401 12.4293 6.8211 12.4293 8.00336V26.1313H19.5416V8.00336C19.5416 6.8211 18.6033 5.86401 17.4211 5.86401Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5274 17.5928H19.5544V26.1314H26.6668V19.7321C26.648 18.5499 25.6909 17.5928 24.5274 17.5928Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
