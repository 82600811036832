/* eslint-disable no-unused-vars */
import ACTION_TYPES from "./actionType";

const handleGetAchievements = (params) => {
  return {
    type: ACTION_TYPES.GET_ACHIEVEMENTS,
    params
  };
};
const handleGetInfoAccount = () => {
  return {
    type: ACTION_TYPES.GET_INFO_ACCOUNT
  };
};
const handleGetdiamondHistory = (params) => {
  return {
    type: ACTION_TYPES.GET_DIAMOND_HISTORY,
    params
  };
};
const handleUpdateInfoAccount = (params, callback = () => {}) => {
  return {
    type: ACTION_TYPES.UPDATE_INFO_ACCOUNT,
    params,
    callback
  };
};
const handleClearNotify = () => {
  return {
    type: ACTION_TYPES.CLEAR_NOTIFY
  };
};
const handleGetExamHistory = () => {
  return {
    type: ACTION_TYPES.GET_EXAM_HISTORY
  };
};
const handlePostGiftCode = (params) => {
  return {
    type: ACTION_TYPES.POST_GIFT_CODE,
    params
  };
};
const handleGetListGiftCode = () => {
  return {
    type: ACTION_TYPES.GET_LIST_GIFT_CODE
  };
};
const handleGetMissions = () => {
  return {
    type: ACTION_TYPES.GET_LIST_MISSIONS
  };
};
const handlePostMissions = (params) => {
  return {
    type: ACTION_TYPES.POST_MISSIONS,
    params
  };
};
const handlePostRefCode = (params) => {
  return {
    type: ACTION_TYPES.POST_REF_CODE,
    params
  };
};
const handleChangeRefCode = (params: string) => {
  return {
    type: ACTION_TYPES.CHANGE_REF_CODE,
    params
  };
};
const handleUpdateConfigNotifications = (params) => {
  return {
    type: ACTION_TYPES.POST_CONFIG_NOTIFICATIONS,
    params
  };
};
const handleGetGoals = (params) => {
  return {
    type: ACTION_TYPES.GET_GOALS,
    params
  };
};
const handleChangeTarget = (name, value) => {
  return {
    type: ACTION_TYPES.CHANGE_TARGET,
    params: {
      name,
      value
    }
  };
};
const handlePostGoals = (params) => {
  return {
    type: ACTION_TYPES.POST_GOALS,
    params
  };
};
const handleGetDetailAccount = (params) => {
  return {
    type: ACTION_TYPES.GET_DETAIL_ACCOUNT,
    params
  };
};
const handleGetExtraInfo = (params) => {
  return {
    type: ACTION_TYPES.GET_EXTRA_INFO,
    params
  };
};
const handleGiftDiamond = (params) => {
  return {
    type: ACTION_TYPES.GIFT_DIAMOND,
    params
  };
};
const handleClearDataAccountInfo = () => {
  return {
    type: ACTION_TYPES.CLEAR_DATA_ACCOUNT_INFO
  };
};
const handleCleanAccountState = () => {
  return {
    type: ACTION_TYPES.CLEAN_ACCOUNT_STATE
  };
};

const handleCreateTeacherInfo = (params, callback = () => {}) => {
  return {
    type: ACTION_TYPES.CREATE_TEACHER_INFO,
    params,
    callback
  };
};

const handleGetTeacherInfo = (successCallback = () => {}, errorCallback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_TEACHER_INFO,
    successCallback,
    errorCallback
  };
};

const handleUpdateGroup = (params, successCallback = () => {}) => {
  return {
    type: ACTION_TYPES.UPDATE_GROUP,
    params,
    successCallback
  };
};

const handleGetGroupOfTeacher = () => {
  return {
    type: ACTION_TYPES.GET_GROUP_OF_TEACHER
  };
};

const handleJoinGroup = (params, successCallback = () => {}, errorCallback = (error) => {}) => {
  return {
    type: ACTION_TYPES.JOIN_GROUP,
    params,
    successCallback,
    errorCallback
  };
};

const handleJoinGroupFailed = (params) => {
  return {
    type: ACTION_TYPES.JOIN_GROUP_FAILED,
    params
  };
};

export const accountActions = {
  handleGetAchievements,
  handleGetInfoAccount,
  handleGetdiamondHistory,
  handleUpdateInfoAccount,
  handleClearNotify,
  handleGetExamHistory,
  handlePostGiftCode,
  handleGetListGiftCode,
  handleGetMissions,
  handlePostMissions,
  handlePostRefCode,
  handleChangeRefCode,
  handleUpdateConfigNotifications,
  handleGetGoals,
  handleChangeTarget,
  handleGetDetailAccount,
  handleGetExtraInfo,
  handlePostGoals,
  handleGiftDiamond,
  handleClearDataAccountInfo,
  handleCleanAccountState,
  handleCreateTeacherInfo,
  handleGetTeacherInfo,
  handleUpdateGroup,
  handleGetGroupOfTeacher,
  handleJoinGroup,
  handleJoinGroupFailed
};
