import React from "react";

export default function IconExamsOnlineActive({ width, height, fill }: any) {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2467 7.31996V8.30663L19.5268 5.57329C17.7401 4.54663 15.2468 4.54663 13.4734 5.57329L8.75342 8.31996V7.31996C8.75342 4.31996 10.3934 2.66663 13.3934 2.66663H19.6067C22.6067 2.66663 24.2467 4.31996 24.2467 7.31996Z"
        fill="#38B000"
      />
      <path
        d="M24.2865 10.6266L24.0998 10.5333L22.2865 9.49328L18.5265 7.31995C17.3798 6.65328 15.6198 6.65328 14.4732 7.31995L10.7132 9.47995L8.89984 10.5466L8.65984 10.6666C6.3265 12.2399 6.1665 12.5333 6.1665 15.0533V21.0799C6.1665 23.5999 6.3265 23.8933 8.71317 25.5066L14.4732 28.8266C15.0465 29.1733 15.7665 29.3199 16.4998 29.3199C17.2198 29.3199 17.9532 29.1599 18.5265 28.8266L24.3398 25.4666C26.6865 23.8933 26.8332 23.6133 26.8332 21.0799V15.0533C26.8332 12.5333 26.6732 12.2399 24.2865 10.6266ZM20.2198 17.9999L19.4065 18.9999C19.2732 19.1466 19.1798 19.4266 19.1932 19.6266L19.2732 20.9066C19.3265 21.6933 18.7665 22.0933 18.0332 21.8133L16.8465 21.3333C16.6598 21.2666 16.3532 21.2666 16.1665 21.3333L14.9798 21.7999C14.2465 22.0933 13.6865 21.6799 13.7398 20.8933L13.8198 19.6133C13.8332 19.4133 13.7398 19.1333 13.6065 18.9866L12.7798 17.9999C12.2732 17.3999 12.4998 16.7333 13.2598 16.5333L14.4998 16.2133C14.6998 16.1599 14.9265 15.9733 15.0332 15.8133L15.7265 14.7466C16.1532 14.0799 16.8332 14.0799 17.2732 14.7466L17.9665 15.8133C18.0732 15.9866 18.3132 16.1599 18.4998 16.2133L19.7398 16.5333C20.4998 16.7333 20.7265 17.3999 20.2198 17.9999Z"
        fill="#38B000"
      />
    </svg>
  );
}
