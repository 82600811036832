/* eslint-disable default-param-last */
import { HYDRATE } from "next-redux-wrapper";
import isEmpty from "lodash/isEmpty";
import { TAccountState } from "../utils/types";
import ACTION_TYPES from "./actionType";

export const initialStateAccount: TAccountState = {
  listAchievements: [],
  memberAchievements: [],
  infoAccount: {
    created_at: 0,
    grade: "",
    city: ""
  },
  isLoading: false,
  diamondHistory: {
    diamond_histories: []
  },
  notify: {
    message: "",
    type: ""
  },
  examHistory: {
    items: []
  },
  giftCode: {
    gift_code: "",
    congratulation: {
      success_message: "",
      congratulation_message: " ",
      reward_amount: 0,
      reward_type: ""
    }
  },
  listGiftCode: [],
  listMissions: [],
  postMissonsResponse: {
    is_completed: false,
    sku: "",
    congratulation: {
      success_message: "",
      congratulation_message: " ",
      reward_amount: 0,
      reward_type: ""
    }
  },
  refCode: {
    ref_code: ""
  },
  goals: {
    learning_per_day: 0,
    correct_quiz_rate: 0,
    is_updated: false
  },
  isLoadingAchievement: false,
  practiceHistory: {
    items: []
  },
  activitiesInfo: {
    daily_activities: {
      exp_point: 0,
      star_history: {
        total_received: 0,
        total_spent: 0
      },
      diamond_history: {
        total_received: 0,
        total_spent: 0
      },
      learning_information: []
    },
    gift_codes: [],
    learning_progress: {
      books: []
    },
    my_friend: {
      list_friend: null,
      list_following: null
    },
    my_information: {
      created_at: 0,
      city: "",
      grade: ""
    },
    my_room: {
      rooms: null,
      subjects: null
    },
    statistic: null
  },
  teacherInfo: null,
  isLoadingGetTeacherInfo: true,
  groups: {},
  groupDetail: {}
};

export const accountReducer = (state = initialStateAccount, action) => {
  switch (action?.type) {
    case ACTION_TYPES.GET_ACHIEVEMENTS_SUCCESS: {
      return {
        ...state,
        listAchievements: action?.params?.achievements,
        memberAchievements: action?.params?.member_achievements,
        isLoadingAchievement: false
      };
    }
    case ACTION_TYPES.GET_INFO_ACCOUNT_SUCCESS: {
      return {
        ...state,
        infoAccount: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_INFO_ACCOUNT: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_INFO_ACCOUNT_FAILED: {
      return {
        ...state,
        notify: {
          type: "GET_INFO_ACCOUNT_FAILED",
          isOpenDialog: true,
          isOpenNotify: false,
          message: action?.params?.message,
          errorCode: action?.params?.code,
          description: action?.params?.description
        }
      };
    }
    case ACTION_TYPES.GET_DIAMOND_HISTORY: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_DIAMOND_HISTORY_SUCCESS: {
      return {
        ...state,
        diamondHistory: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.UPDATE_INFO_ACCOUNT_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "UPDATE_INFO_ACCOUNT_SUCCESS",
          message: action?.params?.success_message,
          isOpenNotify: true,
          isOpenDialog: false
        }
      };
    }
    case ACTION_TYPES.UPDATE_INFO_ACCOUNT_FAILED: {
      return {
        ...state,
        notify: {
          type: "UPDATE_INFO_ACCOUNT_FAILED",
          isOpenDialog: true,
          isOpenNotify: false,
          message: action?.params?.message,
          errorCode: action?.params?.code,
          description: action?.params?.description
        }
      };
    }
    case ACTION_TYPES.CLEAR_NOTIFY: {
      return {
        ...state,
        notify: {
          isOpenDialog: false,
          isOpenNotify: false,
          message: "",
          errorCode: "",
          description: ""
        }
      };
    }
    case ACTION_TYPES.GET_EXAM_HISTORY_SUCCESS: {
      return {
        ...state,
        examHistory: {
          items: action?.params?.exams?.items
        },
        practiceHistory: {
          items: action?.params?.practices?.items
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_EXAM_HISTORY: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.POST_GIFT_CODE_SUCCESS: {
      return {
        ...state,
        giftCode: {
          gift_code: action?.params?.gift_code,
          congratulation: action?.params?.congratulation
        },
        activitiesInfo: {
          ...state.activitiesInfo,
          gift_codes: state.activitiesInfo.gift_codes.filter((giftCode) => giftCode.code !== action.params.gift_code)
        },
        notify: {
          isOpenNotify: false,
          type: "POST_GIFT_CODE_SUCCESS",
          isOpenDialog: true,
          message: action?.params?.congratulation?.success_message,
          congratulation: action?.params?.congratulation
        }
      };
    }
    case ACTION_TYPES.POST_GIFT_CODE_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          message: action?.params?.message,
          type: "POST_GIFT_CODE_FAILED",
          description: action?.params?.description,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.GET_LIST_GIFT_CODE_SUCCESS: {
      return {
        ...state,
        listGiftCode: action?.params?.gift_codes
      };
    }
    case ACTION_TYPES.GET_LIST_MISSIONS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_LIST_MISSIONS_SUCCESS: {
      return {
        ...state,
        listMissions: action?.params?.items,
        isLoading: false
      };
    }
    case ACTION_TYPES.POST_MISSIONS_SUCCESS: {
      return {
        ...state,
        postMissonsResponse: action?.params,
        notify: {
          isOpenNotify: false,
          type: "POST_MISSIONS_SUCCESS",
          isOpenDialog: true,
          congratulation: action?.params?.congratulation
        }
      };
    }
    case ACTION_TYPES.POST_MISSIONS_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          message: action?.params?.message,
          type: "POST_MISSIONS_FAILED",
          description: action?.params?.description,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.POST_REF_CODE_SUCCESS: {
      return {
        ...state,
        notify: {
          isOpenNotify: false,
          type: "POST_REF_CODE_SUCCESS",
          isOpenDialog: true,
          congratulation: action?.params?.congratulation
        },
        refCode: {
          ref_code: action?.params?.ref_code
        }
      };
    }
    case ACTION_TYPES.POST_REF_CODE_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          message: action?.params?.message,
          type: "POST_REF_CODE_FAILED",
          description: action?.params?.description,
          errorCode: action?.params?.code
        },
        refCode: {
          ref_code: ""
        }
      };
    }
    case ACTION_TYPES.CHANGE_REF_CODE: {
      return {
        ...state,
        refCode: {
          ref_code: action?.params
        }
      };
    }
    case ACTION_TYPES.POST_CONFIG_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notify: {
          message: action?.params?.success_message,
          type: "POST_CONFIG_NOTIFICATIONS_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false
        }
      };
    }
    case ACTION_TYPES.POST_CONFIG_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        notify: {
          message: action?.params?.message,
          errorCode: action?.params?.code,
          description: action?.params?.description
        }
      };
    }
    case ACTION_TYPES.GET_GOALS: {
      return {
        ...state
        // isLoading: true,
      };
    }
    case ACTION_TYPES.GET_GOALS_SUCCESS: {
      return {
        ...state,
        goals: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.CHANGE_TARGET: {
      return {
        ...state,
        goals: {
          ...state.goals,
          [action?.params.name]: action?.params?.value
        }
      };
    }
    case ACTION_TYPES.POST_GOALS_SUCCESS: {
      return {
        ...state,
        notify: {
          message: action?.params?.success_message,
          type: "POST_GOALS_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_DETAIL_ACCOUNT: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_DETAIL_ACCOUNT_FAILED: {
      return {
        ...state,
        isLoading: false,
        notify: {
          isOpenDialog: true,
          message: action?.params?.message,
          type: "GET_DETAIL_ACCOUNT_FAILED",
          description: action?.params?.description,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.GET_DETAIL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        infoAccount: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_EXTRA_INFO_SUCCESS: {
      return {
        ...state,
        extraInfo: { ...action?.params, memberHid: action?.memberHid },
        isLoadingAchievement: false
      };
    }
    case ACTION_TYPES.GET_EXTRA_INFO: {
      return {
        ...state,
        isLoadingAchievement: true
      };
    }
    case ACTION_TYPES.GIFT_DIAMOND_SUCCESS: {
      return {
        ...state,
        notify: {
          message: action?.params?.success_message,
          type: "GIFT_DIAMOND_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false
        }
      };
    }
    case ACTION_TYPES.GIFT_DIAMOND_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          isOpenNotify: false,
          message: action?.params?.message,
          errorCode: action?.params?.code,
          description: action?.params?.description,
          type: "GIFT_DIAMOND_FAILED"
        }
      };
    }
    case ACTION_TYPES.CLEAR_DATA_ACCOUNT_INFO: {
      return {
        ...state,
        infoAccount: {}
      };
    }
    case ACTION_TYPES.GET_ACHIEVEMENTS_FAILED: {
      return {
        ...state,
        notify: {
          message: action?.params?.message || null,
          errorCode: action?.params?.code || null,
          type: "GET_LIST_FRIEND_FAILED",
          isOpenDialog: true,
          isOpenNotify: false
        },
        isLoadingAchievement: false
      };
    }
    case ACTION_TYPES.GET_ACHIEVEMENTS: {
      return {
        ...state,
        isLoadingAchievement: true
      };
    }
    case ACTION_TYPES.CLEAN_ACCOUNT_STATE: {
      return {
        ...initialStateAccount
      };
    }
    case ACTION_TYPES.GET_ACTIVITIES_INFO_SUCCESS: {
      return {
        ...state,
        activitiesInfo: action?.params
      };
    }
    case ACTION_TYPES.GET_ACTIVITIES_INFO_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: false,
          message: action?.params?.message,
          type: "GET_ACTIVITIES_INFO_FAILED",
          description: action?.params?.description,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.GET_TEACHER_INFO_SUCCESS: {
      return {
        ...state,
        teacherInfo: action?.params,
        isLoadingGetTeacherInfo: false
      };
    }
    case ACTION_TYPES.GET_TEACHER_INFO_FAILED: {
      return {
        ...state,
        isLoadingGetTeacherInfo: false
      };
    }
    case ACTION_TYPES.CREATE_TEACHER_INFO_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          isOpenNotify: false,
          message: action?.params?.message,
          errorCode: action?.params?.code,
          description: action?.params?.description,
          type: "CREATE_TEACHER_INFO_FAILED"
        }
      };
    }
    case ACTION_TYPES.GET_GROUP_OF_MEMBER_SUCCESS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          groupOfMember: action?.params
        }
      };
    }
    case ACTION_TYPES.GET_GROUP_OF_TEACHER_SUCCESS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          groupOfTeacher: action?.params
        }
      };
    }
    case ACTION_TYPES.GET_GROUP_DETAIL_SUCCESS: {
      return {
        ...state,
        groupDetail: action?.params
      };
    }
    case ACTION_TYPES.GET_GROUP_DETAIL_FAILED: {
      return {
        ...state,
        notify: {
          type: "GET_GROUP_DETAIL_FAILED",
          isOpenDialog: true,
          isOpenNotify: false,
          message: action?.params?.message,
          errorCode: action?.params?.code,
          description: action?.params?.description
        }
      };
    }
    case ACTION_TYPES.JOIN_GROUP_FAILED: {
      return {
        ...state,
        notify: {
          type: "JOIN_GROUP_FAILED",
          isOpenDialog: true,
          isOpenNotify: false,
          message: action?.params?.message,
          errorCode: action?.params?.code,
          description: action?.params?.description
        }
      };
    }
    case HYDRATE: {
      const serverState = action?.payload?.account;
      return {
        ...state,
        infoAccount: serverState?.infoAccount || null,
        listAchievements: serverState.listAchievements || null,
        listMissions: serverState?.listMissions || null,
        diamondHistory: serverState?.diamondHistory || null,
        memberAchievements: serverState?.memberAchievements || null,
        notify: !isEmpty(serverState.notify?.message) ? serverState?.notify : state?.notify || null,
        activitiesInfo: serverState?.activitiesInfo || null,
        groups: serverState?.groups || null,
        groupDetail: serverState?.groupDetail || null,
        teacherInfo: serverState?.teacherInfo || state.teacherInfo || null
      };
    }
    default:
      return state;
  }
};
export default accountReducer;
