import { fork } from "redux-saga/effects";
import newsSaga from "../components/features/news/redux/saga";
import giftItemSaga from "../components/features/giftItem/redux/saga";
import friendSagas from "../components/features/friends/redux/sagas";
import homeSagas from "../components/features/Homepage/redux/saga";
import { searchSaga } from "../components/features/search/redux/saga";
import { rotationSaga } from "../components/features/rotationlucky/redux/saga";
import commonSaga from "../components/features/common/redux/sagas";
import arenaSagas from "../components/features/arena/redux/sagas";
import accountSagas from "../components/features/account/redux/sagas";
import changePointSaga from "../components/features/Chagrepoint/redux/saga";
import quizSagas from "../components/features/topics/redux/sagas";
import subjectSaga from "../components/features/subjects/redux/sagas";
import questionAndAnswerSaga from "../components/features/questionAndAnswer/redux/sagas";
import courseSaga from "../components/features/course/redux/sagas";
import searchScoreSaga from "../components/features/searchScore/redux/sagas";

function* rootSaga() {
  yield fork(commonSaga);
  yield fork(arenaSagas);
  yield fork(accountSagas);
  yield fork(changePointSaga);
  yield fork(quizSagas);
  yield fork(subjectSaga);
  yield fork(questionAndAnswerSaga);
  yield fork(newsSaga);
  yield fork(friendSagas);
  yield fork(giftItemSaga);
  yield fork(homeSagas);
  yield fork(searchSaga);
  yield fork(rotationSaga);
  yield fork(courseSaga);
  yield fork(searchScoreSaga);
}

export default rootSaga;
