import { call, debounce, put, takeEvery } from "redux-saga/effects";
import { ACTION_ROTATION } from "./actionTypes";
import { rotationServices } from "./services";

function* getRotationData() {
  try {
    const res = yield call(rotationServices.getRotationData);
    if (res) {
      yield put({ type: ACTION_ROTATION.GET_DATA_ROTATION_SUCCESS, params: res?.data?.data });
    }
  } catch (e) {
    yield put({ type: ACTION_ROTATION.GET_DATA_ROTATION_FAILED, params: e?.data?.error });
  }
}
function* handleStartWheel(action) {
  try {
    const res = yield call(rotationServices.startWheel, action?.params);
    if (res) {
      yield put({ type: ACTION_ROTATION.START_WHEEL_SUCCESS, params: res?.data?.data });
    }
  } catch (err) {
    yield put({ type: ACTION_ROTATION.START_WHEEL_FAILED, params: err?.data?.error });
  }
}

export function* rotationSaga() {
  yield takeEvery(ACTION_ROTATION.GET_DATA_ROTATION, getRotationData);
  yield debounce(1000, ACTION_ROTATION.START_WHEEL, handleStartWheel);
}
