const ACTION_TYPES = {
  GET_INVITATION_FRIEND: "GET_INVITATION_FRIEND",
  GET_INVITATION_FRIEND_SUCCESS: "GET_INVITATION_FRIEND_SUCCESS",
  GET_INVITATION_FRIEND_FAILED: "GET_INVITATION_FRIEND_FAILED",
  SEARCH_FRIEND: "SEARCH_FRIEND",
  SEARCH_FRIEND_SUCCESS: "SEARCH_FRIEND_SUCCESS",
  SEARCH_FRIEND_FAILED: "SEARCH_FRIEND_FAILED",
  CLEAN_LIST_FRIEND_SEARCH: "CLEAN_LIST_FRIEND_SEARCH",
  INVITE_FRIEND: "INVITE_FRIEND",
  INVITE_FRIEND_SUCCESS: "INVITE_FRIEND_SUCCESS",
  INVITE_FRIEND_FAILED: "INVITE_FRIEND_FAILED",
  DELETE_INVITATION: "DELETE_INVITATION",
  DELETE_INVITATION_SUCCESS: "DELETE_INVITATION_SUCCESS",
  DELETE_INVITATION_FAILED: "DELETE_INVITATION_FAILED",
  ACCEPT_FRIEND: "ACCEPT_FRIEND",
  ACCEPT_FRIEND_SUCCESS: "ACCEPT_FRIEND_SUCCESS",
  ACCEPT_FRIEND_FAILED: "ACCEPT_FRIEND_FAILED",
  REMOVE_FRIEND: "REMOVE_FRIEND",
  REMOVE_FRIEND_SUCCESS: "REMOVE_FRIEND_SUCCESS",
  REMOVE_FRIEND_FAILED: "REMOVE_FRIEND_FAILED",
  FOLLOW_FRIEND: "FOLLOW_FRIEND",
  FOLLOW_FRIEND_SUCCESS: "FOLLOW_FRIEND_SUCCESS",
  FOLLOW_FRIEND_FAILED: "FOLLOW_FRIEND_FAILED",
  DELETE_FOLLOW: "DELETE_FOLLOW",
  DELETE_FOLLOW_SUCCESS: "DELETE_FOLLOW_SUCCESS",
  DELETE_FOLLOW_FAILED: "DELETE_FOLLOW_FAILED",
  DECLINE_FRIEND_REQUEST: "DECLINE_FRIEND_REQUEST",
  DECLINE_FRIEND_REQUEST_SUCCESS: "DECLINE_FRIEND_REQUEST_SUCCESS",
  DECLINE_FRIEND_REQUEST_FAILED: "DECLINE_FRIEND_REQUEST_FAILED",
  GET_LIST_FRIEND: "GET_LIST_FRIEND",
  GET_LIST_FRIEND_SUCCESS: "GET_LIST_FRIEND_SUCCESS",
  GET_LIST_FRIEND_FAILED: "GET_LIST_FRIEND_FAILED",
  CLEAN_LIST_FRIEND: "CLEAN_LIST_FRIEND",
  CLEAR_NOTIFY: "CLEAR_NOTIFY",
  SET_SEARCH_KEY_FRIEND: "SET_SEARCH_KEY_FRIEND",
  SET_IS_LOADING: "SET_IS_LOADING"
};
export default ACTION_TYPES;
