/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import isEmpty from "lodash/isEmpty";
import { HYDRATE } from "next-redux-wrapper";
import { IQuestionAndAnswer } from "../utils/types";
import { ACTION_TYPES } from "./actionTypes";

/* eslint-disable default-param-last */
export const initialStateQA: IQuestionAndAnswer = {
  listPost: [],
  postsPinned: [],
  // vì listPost & postsPinned dùng để ssr nên không thể dùng để update status post
  // biến này clone từ listPost và postsPinned dùng để update status ngay lập tức tại client
  postsCloneToHandleFavoriteAndSave: [],
  packages: [],
  isLoadingGetListPost: false,
  category: "all",
  listPostType: [],
  listSubjectAndGrade: [],
  listGradeChoose: [],
  listSubjectChoose: [],
  notification: {
    type: "",
    message: "",
    errorCode: "",
    isOpenDialog: false,
    isOpenNotify: false,
    postId: ""
  },
  creationInfoPost: {
    packages: [],
    fee: [0],
    reward_rate: 0,
    reward_accept_rate: 0,
    list_suggested_text: [],
    time: []
  },
  isLoading: false,
  subscribeSubjects: [],
  creationInfoSubscribe: [],
  postDetail: {},
  commentId: 0,
  isOpenModalEdit: false,
  parentSubjectId: 0,
  openModalEarnDiamond: false,
  openMenuAction: false,
  page: "thao-luan",
  timeIdChoose: 0,
  listPostTypeOld: [],
  listGradeChooseOld: [],
  listSubjectChooseOld: [],
  timeIdOld: 0,
  isCleanFilter: false,
  memberRanking: {
    member_hid: "",
    fir_uid: "",
    photo_url: "",
    name: "",
    display_name: "",
    ranking: 0,
    ask_point: 0,
    num_of_vote: 0,
    num_of_accept: 0
  },
  listRanking: [],
  isFilterListPost: false
};
const initErrorNotify = (action) => {
  return {
    status: "error",
    message: action?.params?.message,
    errorCode: action?.params?.code || null,
    isOpenNotify: false,
    isOpenDialog: true
  };
};

const questionAndAnswerReducer = (state = initialStateQA, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_POST:
      return {
        ...state,
        isLoadingGetListPost: state.listPost?.length === 0
      };
    case ACTION_TYPES.GET_LIST_POST_SUCCESS:
      return {
        ...state,
        listPost: action?.params?.posts || null,
        postsPinned: action?.params?.posts_pinned || null,
        // clone data cần thiết trong việc update status post từ listPost và postsPinned
        postsCloneToHandleFavoriteAndSave: [
          ...(action?.params?.posts.map((post) => ({
            post_id: post.post_id,
            num_of_vote: post.num_of_vote,
            is_saved: post.is_saved,
            is_favorite: post.is_favorite
          })) || []),
          ...(action?.params?.posts_pinned.map((post) => ({
            post_id: post.post_id,
            num_of_vote: post.num_of_vote,
            is_saved: post.is_saved,
            is_favorite: post.is_favorite
          })) || [])
        ],
        packages: action?.params?.subject_types || null,
        isLoadingGetListPost: false
      };
    case ACTION_TYPES.GET_LIST_POST_FAILED:
      return {
        ...state,
        isLoadingGetListPost: false
      };
    case ACTION_TYPES.HANDLE_CHANGE_TAB:
      return {
        ...state,
        listPost: [],
        // isLoadingGetListPost: true,
        category: action?.params
      };
    case ACTION_TYPES.HANDLE_VOTE: {
      return {
        ...state,
        postDetail: {
          ...state.postDetail,
          post_info: {
            ...state.postDetail.post_info,
            is_favorite: !state?.postDetail?.post_info?.is_favorite,
            num_of_vote: state?.postDetail?.post_info?.is_favorite
              ? state?.postDetail?.post_info?.num_of_vote - 1
              : state?.postDetail?.post_info?.num_of_vote + 1
          }
        },
        postsCloneToHandleFavoriteAndSave: state.postsCloneToHandleFavoriteAndSave.map((item) => {
          if (item?.post_id === action?.params?.object_id) {
            return {
              ...item,
              is_favorite: !item.is_favorite,
              num_of_vote: item.is_favorite ? item.num_of_vote - 1 : item.num_of_vote + 1
            };
          }
          return item;
        })
      };
    }
    case ACTION_TYPES.HANDLE_SAVE_POST: {
      return {
        ...state,
        postsCloneToHandleFavoriteAndSave: state.postsCloneToHandleFavoriteAndSave.map((item) => {
          if (item?.post_id === action?.params?.post_id) {
            return {
              ...item,
              is_saved: !item.is_saved
            };
          }
          return item;
        })
      };
    }
    case ACTION_TYPES.HANDLE_VOTE_SUCCESS: {
      return {
        ...state,
        listPost: state.listPost.map((item) => {
          if (item.post_id === action?.params?.object_id) {
            return {
              ...item,
              is_favorite: !item.is_favorite,
              num_of_vote: item.is_favorite ? item.num_of_vote - 1 : item.num_of_vote + 1
            };
          }
          return item;
        }),
        notification: {
          type: "HANDLE_VOTE_SUCCESS",
          message: action?.params?.success_message,
          errorCode: "",
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }
    case ACTION_TYPES.CHOOSE_POST_TYPE: {
      return {
        ...state,
        listPostType: action?.params,
        isCleanFilter: false
      };
    }
    case ACTION_TYPES.GET_CREATION_INFO_POST_SUCCESS: {
      return {
        ...state,
        listSubjectAndGrade: action?.params?.packages,
        creationInfoPost: action?.params
      };
    }
    case ACTION_TYPES.CHOOSE_GRADE_FILTER: {
      return {
        ...state,
        listGradeChoose: action?.params,
        isCleanFilter: false
      };
    }
    case ACTION_TYPES.CHOOSE_SUBJECT: {
      return {
        ...state,
        listSubjectChoose: action?.params,
        isCleanFilter: false
      };
    }
    case ACTION_TYPES.UPDATE_DEFAULT_VALUE_LIST_GRADE_CHOOSE: {
      return {
        ...state,
        listGradeChoose: state?.listGradeChooseOld?.length > 0 ? state?.listGradeChooseOld : [action?.params.value],
        listGradeChooseOld: state?.listGradeChooseOld?.length > 0 ? state?.listGradeChooseOld : [action?.params.value],
        parentSubjectId: action?.params?.parentId
      };
    }
    case ACTION_TYPES.HANDLE_SAVE_IS_FILTER: {
      return {
        ...state,
        isFilterListPost: action?.params
      };
    }
    case ACTION_TYPES.CLEAN_FILTER_WHEN_DIRECT_PAGE: {
      return {
        ...state,
        listSubjectChoose: [],
        listPostType: [],
        timeIdChoose: 0,
        timeIdOld: 0,
        listPostTypeOld: [],
        listGradeChooseOld: [],
        listSubjectChooseOld: []
      };
    }
    case ACTION_TYPES.CLEAN_CHOOSE_FILTER: {
      return {
        ...state,
        listGradeChoose: [],
        listSubjectChoose: [],
        listPostType: [],
        timeIdChoose: 0,
        isCleanFilter: true
      };
    }
    case ACTION_TYPES.UNSET_CLEAN_FILTER: {
      return {
        ...state,
        isCleanFilter: false
      };
    }
    case ACTION_TYPES.HANDLE_SAVE_OLD_FILTER: {
      return {
        ...state,
        listGradeChooseOld: state.listGradeChoose,
        listSubjectChooseOld: state.listSubjectChoose,
        listPostTypeOld: state.listPostType,
        timeIdOld: state.timeIdChoose
      };
    }
    case ACTION_TYPES.HANDLE_CLOSE_POST_FAILED: {
      return {
        ...state,
        notification: {
          type: "HANDLE_CLOSE_POST_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.HANDLE_CLOSE_POST_SUCCESS: {
      return {
        ...state,
        notification: {
          type: "HANDLE_CLOSE_POST_SUCCESS",
          message: action?.params?.success_message,
          errorCode: "",
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }
    case ACTION_TYPES.HANDLE_DELETE_POST_FAILED: {
      return {
        ...state,
        notification: {
          type: "HANDLE_DELETE_POST_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }

    case ACTION_TYPES.HANDLE_DELETE_POST_SUCCESS: {
      return {
        ...state,
        notification: {
          type: "HANDLE_DELETE_POST_SUCCESS",
          message: action?.params?.success_message,
          errorCode: "",
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }
    case ACTION_TYPES.CLEAR_FILTER: {
      return {
        ...state,
        listPostType: [],
        listGradeChoose: [],
        listSubjectChoose: []
      };
    }
    case ACTION_TYPES.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notification: {
          type: "CLEAR_NOTIFICATION",
          message: "",
          errorCode: "",
          isOpenDialog: false,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.HANDLE_VOTE_FAILED: {
      return {
        ...state,
        notification: {
          type: "HANDLE_VOTE_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.HANDLE_SAVE_POST_FAILED: {
      return {
        ...state,
        notification: {
          type: "HANDLE_SAVE_POST_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.HANDLE_COMPLAIN_FAILED: {
      return {
        ...state,
        notification: {
          type: "HANDLE_COMPLAIN_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.HANDLE_COMPLAIN_SUCCESS: {
      return {
        ...state,
        notification: {
          type: "HANDLE_COMPLAIN_SUCCESS",
          message: action?.params?.success_message,
          errorCode: "",
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }
    case ACTION_TYPES.HANDLE_SAVE_POST_SUCCESS: {
      return {
        ...state,
        notification: {
          type: "HANDLE_SAVE_POST_SUCCESS",
          message: action?.params?.success_message,
          errorCode: "",
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }
    case ACTION_TYPES.GET_QA_RANKINGS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_QA_RANKINGS_SUCCESS: {
      return {
        ...state,
        listRanking: action?.params?.rankings || null,
        memberRanking: action?.params?.member || null,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_QA_RANKINGS_FAILED: {
      return {
        ...state,
        isLoading: false,
        notification: {
          ...initErrorNotify(action)
        }
      };
    }
    case ACTION_TYPES.SAVE_SUBSCRIBE_SUBJECTS: {
      return {
        ...state,
        subscribeSubjects: action?.params
      };
    }
    case ACTION_TYPES.GET_CREATION_INFO_SUBSCRIBE_SUCCESS: {
      return {
        ...state,
        creationInfoSubscribe: action?.params?.items
      };
    }
    case ACTION_TYPES.POST_SUBSCRIBE_TOPIC: {
      return {
        ...state,
        action: "POST_SUBSCRIBE_TOPIC"
      };
    }
    case ACTION_TYPES.POST_SUBSCRIBE_TOPIC_SUCCESS: {
      return {
        ...state,
        notification: {
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true,
          type: "POST_SUBSCRIBE_TOPIC_SUCCESS"
        },
        action: "POST_SUBSCRIBE_TOPIC_SUCCESS"
      };
    }
    case ACTION_TYPES.POST_SUBSCRIBE_TOPIC_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action)
        },
        action: "POST_SUBSCRIBE_TOPIC_FAILED"
      };
    }
    case ACTION_TYPES.CREATE_POST: {
      return {
        ...state,
        action: "CREATE_POST"
      };
    }
    case ACTION_TYPES.CREATE_POST_SUCCESS: {
      return {
        ...state,
        notification: {
          message: action?.params?.success_message,
          postId: action?.params?.post_id,
          isOpenDialog: false,
          isOpenNotify: true,
          type: "CREATE_POST_SUCCESS"
        },
        action: "CREATE_POST_SUCCESS"
      };
    }
    case ACTION_TYPES.CREATE_POST_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action),
          type: "CREATE_POST_FAILED"
        },
        action: "CREATE_POST_FAILED"
      };
    }
    case ACTION_TYPES.EDIT_POST: {
      return {
        ...state,
        action: "EDIT_POST"
      };
    }
    case ACTION_TYPES.EDIT_POST_SUCCESS: {
      return {
        ...state,
        notification: {
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true,
          type: "EDIT_POST_SUCCESS"
        },
        action: "EDIT_POST_SUCCESS"
      };
    }
    case ACTION_TYPES.EDIT_POST_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action),
          type: "EDIT_POST_FAILED"
        },
        action: "EDIT_POST_FAILED"
      };
    }
    case ACTION_TYPES.GET_DETAIL_POST_SUCCESS: {
      return {
        ...state,
        postDetail: {
          ...action?.params,
          // biến này clone từ postDetail.comments dùng để update status ngay lập tức tại client (mục đích sử dụng giống với postsCloneToHandleFavoriteAndSave)
          commentsCloneToHandleFavorite: [
            ...action?.params?.comments?.map((comment) => ({
              comment_id: comment.comment_id,
              num_of_vote: comment.num_of_vote,
              is_favorite: comment.is_favorite
            }))
          ]
        },
        isLoading: false,
        postsCloneToHandleFavoriteAndSave: [
          {
            post_id: action?.params?.post_info?.post_id,
            num_of_vote: action?.params?.post_info?.num_of_vote,
            is_saved: action?.params?.post_info?.is_saved,
            is_favorite: action?.params?.post_info?.is_favorite
          }
        ]
      };
    }
    case ACTION_TYPES.HANDLE_VOTE_COMMENT: {
      return {
        ...state,
        postDetail: {
          ...state.postDetail,
          commentsCloneToHandleFavorite: state.postDetail.commentsCloneToHandleFavorite.map((comment) => {
            if (comment?.comment_id === action?.params?.object_id) {
              return {
                ...comment,
                is_favorite: !comment.is_favorite,
                num_of_vote: comment.is_favorite ? comment.num_of_vote - 1 : comment.num_of_vote + 1
              };
            }
            return comment;
          })
        },
        commentId: action?.params?.object_id
      };
    }
    case ACTION_TYPES.HANDLE_VOTE_COMMENT_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action)
        },
        postDetail: {
          ...state.postDetail,
          comments: state.postDetail.comments.map((comment) => {
            if (comment?.comment_id === state?.commentId) {
              return {
                ...comment,
                is_favorite: !comment.is_favorite,
                num_of_vote: comment.is_favorite ? comment.num_of_vote - 1 : comment.num_of_vote + 1
              };
            }
            return comment;
          })
        }
      };
    }
    case ACTION_TYPES.HANDLE_COMMENT_SUCCESS: {
      return {
        ...state,
        notification: {
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true,
          type: "HANDLE_COMMENT_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.HANDLE_COMMENT_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action),
          type: "HANDLE_COMMENT_FAILED"
        }
      };
    }
    case ACTION_TYPES.DELETE_COMMENT_SUCCESS: {
      return {
        ...state,
        notification: {
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true,
          type: "DELETE_COMMENT_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.DELETE_COMMENT_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action),
          type: "DELETE_COMMENT_FAILED"
        }
      };
    }
    case ACTION_TYPES.EDIT_COMMENT_SUCCESS: {
      return {
        ...state,
        notification: {
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true,
          type: "EDIT_COMMENT_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.EDIT_COMMENT_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action),
          type: "EDIT_COMMENT_FAILED"
        }
      };
    }
    case ACTION_TYPES.GET_DETAIL_POST: {
      return {
        ...state,
        isLoading: !(state?.postDetail?.post_info?.post_id > 0)
      };
    }
    case ACTION_TYPES.GET_DETAIL_POST_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action),
          type: "GET_DETAIL_POST_FAILED"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.ACCEPT_COMMENT_SUCCESS: {
      return {
        ...state,
        notification: {
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true,
          type: "ACCEPT_COMMENT_SUCCESS"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.ACCEPT_COMMENT_FAILED: {
      return {
        ...state,
        notification: {
          ...initErrorNotify(action),
          type: "ACCEPT_COMMENT_FAILED"
        }
      };
    }
    case ACTION_TYPES.HANDLE_OPEN_MODAL_EDIT_POST: {
      return {
        ...state,
        isOpenModalEdit: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_CHOOSE_PARENT_SUBJECT_ID: {
      return {
        ...state,
        parentSubjectId: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND: {
      return {
        ...state,
        openModalEarnDiamond: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_OPEN_MENU_ACTION: {
      return {
        ...state,
        isOpenMenuAction: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_CHANGE_PAGE: {
      return {
        ...state,
        page: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_CLEAR_PAGE: {
      return {
        ...state,
        category: "all",
        page: "thao-luan"
      };
    }
    case ACTION_TYPES.HANDLE_CHOOSE_TIME: {
      return {
        ...state,
        timeIdChoose: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_CLEAR_OLD_FILTER: {
      return {
        ...state,
        timeIdOld: 0,
        listGradeChooseOld: [],
        listSubjectChooseOld: [],
        listPostTypeOld: []
      };
    }
    case HYDRATE: {
      const serverState = action?.payload?.questionAndAnswer;
      return {
        ...state,
        listRanking: serverState?.listRanking,
        listPost: serverState?.listPost,
        memberRanking: serverState?.memberRanking || null,
        postDetail: serverState?.postDetail || null,
        postsPinned: serverState?.postsPinned,
        postsCloneToHandleFavoriteAndSave: serverState?.postsCloneToHandleFavoriteAndSave,
        packages: serverState?.packages || null,
        notification: !isEmpty(serverState?.notification) ? serverState?.notification : state.notification,
        isLoadingGetListPost: serverState?.isLoadingGetListPost || false
      };
    }
    default:
      return state;
  }
};

export default questionAndAnswerReducer;
