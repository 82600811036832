import React from "react";

export default function IconLop4() {
  return (
    <svg
      width="52"
      height="55"
      viewBox="0 0 52 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5 33.5099V21.2755C51.4991 18.6259 50.805 16.0232 49.4876 13.7296C48.1702 11.4361 46.2761 9.53259 43.9959 8.21087L33.476 2.09368C31.1989 0.768814 28.6156 0.0712891 25.9859 0.0712891C23.3562 0.0712891 20.7729 0.768814 18.4959 2.09368L7.99005 8.21087C5.71248 9.53439 3.82115 11.4386 2.50631 13.7321C1.19147 16.0255 0.499496 18.6272 0.5 21.2755V33.5099C0.499496 36.1582 1.19147 38.7599 2.50631 41.0533C3.82115 43.3468 5.71248 45.251 7.99005 46.5746L18.5099 52.6917C20.787 54.0166 23.3703 54.7141 26 54.7141C28.6297 54.7141 31.213 54.0166 33.4901 52.6917L43.9959 46.5746C46.2761 45.2528 48.1702 43.3494 49.4876 41.0558C50.805 38.7622 51.4991 36.1596 51.5 33.5099V33.5099Z"
        fill="url(#paint0_linear_2_31856)"
      />
      <g style={{ mixBlendMode: "screen" }} opacity="0.4">
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M13.7676 4.854L7.98978 8.21065C7.14359 8.70225 6.34815 9.27754 5.61523 9.928L42.7414 47.3195L44.0097 46.6098C46.3361 45.2555 48.2567 43.2942 49.5691 40.9326L13.7676 4.854Z"
          fill="url(#paint1_linear_2_31856)"
        />
        <path
          style={{ mixBlendMode: "screen" }}
          opacity="0.4"
          d="M18.354 2.18555L15.7188 3.71129L50.5479 38.7893C50.9501 37.7081 51.2291 36.5844 51.3793 35.4398L18.354 2.18555Z"
          fill="url(#paint2_linear_2_31856)"
        />
      </g>
      <path
        d="M25.9999 42.2171C34.1331 42.2171 40.7263 35.5767 40.7263 27.3854C40.7263 19.1941 34.1331 12.5537 25.9999 12.5537C17.8667 12.5537 11.2734 19.1941 11.2734 27.3854C11.2734 35.5767 17.8667 42.2171 25.9999 42.2171Z"
        fill="white"
      />
      <path
        d="M22.1341 30.3176V29.0841L27.5757 20.4744H28.4705V22.385H27.8659L23.7545 28.8907V28.9874H31.0824V30.3176H22.1341ZM27.9626 32.8569V29.9427V29.3683V20.4744H29.3895V32.8569H27.9626Z"
        fill="#606060"
      />
      <g style={{ mixBlendMode: "screen" }}>
        <path
          style={{ mixBlendMode: "screen" }}
          d="M26.0011 51.5134C23.9293 51.5151 21.8934 50.9694 20.0964 49.9309L9.57654 43.8066C7.7832 42.7613 6.2939 41.26 5.25766 39.453C4.22141 37.6459 3.67458 35.5964 3.67188 33.5096V21.2752C3.68073 19.1944 4.23048 17.1522 5.26642 15.3519C6.30236 13.5516 7.78835 12.0559 9.57654 11.0137L20.0964 4.88937C21.9 3.87053 23.9332 3.33545 26.0011 3.33545C28.069 3.33545 30.1022 3.87053 31.9058 4.88937L42.4257 11.0137C44.219 12.0589 45.7083 13.5602 46.7446 15.3673C47.7808 17.1744 48.3276 19.2239 48.3303 21.3107V33.5096C48.3276 35.5964 47.7808 37.6459 46.7446 39.453C45.7083 41.26 44.219 42.7613 42.4257 43.8066L31.9058 49.9309C30.1096 50.9713 28.0731 51.5171 26.0011 51.5134V51.5134ZM26.0011 3.96683C24.0526 3.96436 22.1377 4.47832 20.4487 5.45709L9.92884 11.5743C8.24296 12.5587 6.84299 13.9713 5.86869 15.6711C4.89439 17.3709 4.37987 19.2983 4.37649 21.261V33.5096C4.37987 35.4722 4.89439 37.3997 5.86869 39.0995C6.84299 40.7992 8.24296 42.2119 9.92884 43.1963L20.4487 49.3348C22.1376 50.3143 24.0523 50.8299 26.0011 50.8299C27.95 50.8299 29.8646 50.3143 31.5535 49.3348L42.0734 43.2176C43.7593 42.2331 45.1592 40.8205 46.1335 39.1207C47.1078 37.421 47.6223 35.4935 47.6257 33.5309V21.2752C47.6223 19.3125 47.1078 17.3851 46.1335 15.6853C45.1592 13.9855 43.7593 12.5729 42.0734 11.5885L31.5535 5.47129C29.8649 4.49158 27.9498 3.97755 26.0011 3.98102V3.96683Z"
          fill="url(#paint3_linear_2_31856)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_31856"
          x1="7.10928"
          y1="8.36699"
          x2="45.1598"
          y2="46.1475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9BF3E3" />
          <stop offset="1" stopColor="#4AC29A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_31856"
          x1="5962.68"
          y1="2223.25"
          x2="8704.53"
          y2="2223.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AC29A" />
          <stop offset="1" stopColor="#BDFFF3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_31856"
          x1="5360.16"
          y1="1625.56"
          x2="7164.94"
          y2="1625.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AC29A" />
          <stop offset="1" stopColor="#BDFFF3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_31856"
          x1="5932.98"
          y1="2609.76"
          x2="8763.43"
          y2="2609.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4AC29A" />
          <stop offset="1" stopColor="#BDFFF3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
