/* eslint-disable func-names */
/* eslint-disable no-bitwise */
import CryptoJS from "crypto-js";
import { DebuggerMessage } from "../constants/constants";

const has = Object.prototype.hasOwnProperty;

export const isEmpty = (prop: any) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, "length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  );
};

export const hexToRgbA = (hex: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    if (c.length === 6) {
      c = ["F", "F", c[0], c[1], c[2], c[3], c[4], c[5]];
    }
    c = `0x${c.join("")}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255, ((c >> 24) & 255) / 255.0].join(",")})`;
  }
  throw new Error("Bad format");
};

export const convertAnswer = (ix: number) => {
  switch (ix) {
    case 0:
      return "A.";
    case 1:
      return "B.";
    case 2:
      return "C.";
    case 3:
      return "D.";
    case 4:
      return "E.";
    case 5:
      return "F.";
    case 6:
      return "G.";
    case 7:
      return "H.";
    case 8:
      return "I.";
    case 9:
      return "K.";
    default:
      return "L.";
  }
};

export const formatTime = (timer: number | string) => {
  const tempTimer = Number(timer);
  const getSeconds = `0${tempTimer % 60}`.slice(-2);
  const minutes = `${Math.floor(tempTimer / 60)}`;
  const getMinutes = `0${Number(minutes) % 60}`.slice(-2);
  const getHours = `0${Math.floor(tempTimer / 3600)}`.slice(-2);

  return `${getHours}:${getMinutes}:${getSeconds}`;
};

export const getNonce = () => {
  return Math.random().toString(36).slice(2, 13);
};

export const getRoutePath = (location: any, params: any) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export const genSignature = (data: any, clientKey = "", secretKey = "", ignoreKeys: string[] = []) => {
  const dictData = { ...data };
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in dictData) {
    if (ignoreKeys.includes(key)) {
      delete dictData[key];
    }
    // trường hợp trong dict có 1 key có value là dạng object/list thì phải chuyển về dạng string trước khi tạo sinature
    if (key === "answers") {
      // trường hợp gửi kết quả bài quiz thì cần sắp xếp lại thứ tự trả lời theo question_id trước khi tạo signature
      const answers = dictData[key];
      answers.sort((first: any, second: any) => {
        return first.question_id > second.question_id ? 1 : -1;
      });

      dictData[key] = JSON.stringify(answers);
    }
  }
  dictData.client_key = clientKey || process.env.NEXT_PUBLIC_APPLICATION_CLIENT_KEY;

  // sắp xếp dictionary key theo thứ tự
  const sortedDict = Object.keys(dictData)
    .sort() // eslint-disable-next-line
    .reduce((r: any, k) => ((r[k] = dictData[k]), r), {});

  // nối các cặp {key: value, key2: value2} trong dict thành dạng key=value,key2=value2
  const dataStr = Object.entries(sortedDict)
    .map(([k, v]) => (Array?.isArray(v) && v.length > 1 ? `${k}=[${v?.toString()?.replace(",", ", ")}]` : `${k}=${v}`))
    .join();

  // tạo signature
  const signature = CryptoJS.HmacSHA256(dataStr, secretKey || process.env.NEXT_PUBLIC_APPLICATION_SECRET_KEY || "")
    .toString(CryptoJS.enc.Hex)
    .toUpperCase();

  DebuggerMessage(`params: ${dataStr}; \n Signature: ${signature}`);
  return signature;
};

export const getDirectLinkExam = (directLink: any) => {
  return directLink?.replace("/lop/", "/");
};

export const isInitialPageRequest = (req) => {
  return !req.headers["x-nextjs-data"];
};
