/* eslint-disable react/require-default-props */
import React from "react";

// eslint-disable-next-line no-unused-vars
type IconChatsCircle = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export default function IconChatsCircle({
  className,
  fill,
  width,
  height
}: IconChatsCircle) {
  return (
    <svg
      className={className}
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25894 17.5936C3.14076 15.7086 2.74916 13.4802 3.15768 11.3269C3.56621 9.17365 4.74675 7.24355 6.47764 5.89908C8.20853 4.55461 10.3707 3.88826 12.5581 4.02514C14.7455 4.16203 16.8078 5.09273 18.3575 6.6425C19.9073 8.19226 20.838 10.2545 20.9749 12.4419C21.1118 14.6293 20.4455 16.7915 19.101 18.5224C17.7565 20.2533 15.8264 21.4338 13.6731 21.8424C11.5199 22.2509 9.29149 21.8593 7.40648 20.7411L7.40651 20.741L4.29808 21.6292C4.16947 21.6659 4.03338 21.6676 3.90391 21.634C3.77443 21.6005 3.65628 21.5329 3.56171 21.4383C3.46713 21.3438 3.39957 21.2256 3.36602 21.0961C3.33247 20.9667 3.33415 20.8306 3.37089 20.702L4.25902 17.5935L4.25894 17.5936Z"
        stroke="#666666"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5073 21.9865C11.9498 23.2425 12.6652 24.3848 13.6022 25.331C14.5392 26.2772 15.6744 27.0039 16.926 27.4586C18.1776 27.9133 19.5144 28.0849 20.8403 27.9608C22.1662 27.8367 23.448 27.4201 24.5935 26.7411L24.5935 26.741L27.7019 27.6291C27.8305 27.6658 27.9666 27.6675 28.0961 27.634C28.2255 27.6004 28.3437 27.5328 28.4383 27.4383C28.5328 27.3437 28.6004 27.2255 28.6339 27.0961C28.6675 26.9666 28.6658 26.8305 28.6291 26.7019L27.7409 23.5935L27.741 23.5935C28.5301 22.2627 28.9629 20.751 28.9977 19.2042C29.0324 17.6574 28.6679 16.1278 27.9394 14.7629C27.2108 13.3979 26.1428 12.2438 24.8383 11.4119C23.5339 10.5799 22.037 10.0982 20.4922 10.0132"
        stroke="#666666"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
