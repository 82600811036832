import api from "../../../utils/helpers/api";

const getListGiftCodes = () => {
  return api({
    method: "get",
    url: "list-gift-codes"
  });
};
const takeGiftCode = (data) => {
  return api({
    method: "post",
    url: "take-gift-code",
    data
  });
};

export const homeServices = {
  getListGiftCodes,
  takeGiftCode
};
