/* eslint-disable no-nested-ternary */
/* eslint-disable default-param-last */
// import { persistReducer } from "redux-persist";
import { HYDRATE } from "next-redux-wrapper";
import moment from "moment";
import { TCommonState } from "../utils/type";
import { ACTION_TYPES } from "./actionTypes";
import { isEmpty } from "../../../utils/helpers/helpers";
// import storage from "../../../../app/sync_storage";

export const initialStateCommon: TCommonState = {
  listGrades: [],
  listTops: {
    grade: "",
    promotions: [],
    event_countdown: {
      ordinal: 0,
      title: "",
      text_before: "",
      text_after: "",
      date: 0
    },
    config_list: []
  },
  arraySubject: [],
  staticData: {
    goals: [],
    timeline: [],
    subject_types: []
  },
  grade: "lop-12",
  isLoading: false,
  providerId: "",
  idToken: "",
  accessToken: "",
  refreshToken: "",
  ad_web_banner_top_header: "",
  congratulation: {},
  action: {
    type: ""
  },
  showDirect: false,
  locationPermission: -1, // -1:chưa show dialog xin quyền, 0:đã show nhưng từ chối, 1: đồng ý
  notify: {
    isOpenDialog: false,
    isOpenNotify: false,
    message: "",
    errorCode: "",
    description: "",
    type: ""
  },
  suggestedFriends: [],
  isLoadingTop: false,
  bookId: 0,
  subject: {},
  modalLogin: {
    isOpen: false,
    isRequired: false
  },
  learningHistory: {},
  openModalConfirmLogin: false,
  directUrl: "",
  authCode: "",
  openModalConfirmPremium: false,
  listNews: [],
  openModalChangeImage: false,
  tab: "find",
  timeServer: {
    status: "",
    version: "",
    timestamp: 0
  },
  userNotifications: {},
  isUpdateInfo: false,
  listKeywordSearched: [],
  textSearch: "",
  isNewMember: false,
  isEmptyName: false,
  userInfos: {
    device: {},
    token: {}
  },
  fireBaseInfos: {},
  isShowAdsDialogFirstTime: true,
  adsInHouses: [],
  topExtra: {},
  diffTimeSeconds: 0,
  survey: {},
  routerHistory: [],
  openDialogAdsInHouse: false,
  hideDialogSurvey: false,
  timeShowSurvey: {},
  dailyReport: {},
  isOpenTeacherAccountInfoDialog: false,
  isOpenTeacherBenefitsDialog: false,
  isOpenTeacherAccountFormDialog: false,
  openClassesDialog: false
};

const commonReducer = (state = initialStateCommon, action: any) => {
  switch (action.type) {
    case ACTION_TYPES.GET_GRADE_SUCCESS: {
      return {
        ...state,
        listGrades: action?.params
      };
    }
    case ACTION_TYPES.GET_TOP_SUCCESS: {
      return {
        ...state,
        listTops: action.params,
        action: {
          type: "GET_TOP_SUCCESS"
        },
        isLoadingTop: false
      };
    }
    case ACTION_TYPES.GET_TOP_EXTRA: {
      return {
        ...state,
        action: {
          type: "GET_TOP_EXTRA"
        }
      };
    }
    case ACTION_TYPES.GET_TOP_EXTRA_SUCCESS: {
      return {
        ...state,
        topExtra: action.params,
        action: {
          type: "GET_TOP_EXTRA_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.GET_STATIC_DATA_SUCCESS: {
      return {
        ...state,
        staticData: action?.params?.data?.data
      };
    }
    case ACTION_TYPES.COMPLETE_MISSION_SUCCESS: {
      return {
        ...state,
        congratulation: {
          type: "COMPLETE_MISSION_SUCCESS",
          congratulation: action?.params?.congratulation,
          isOpenCongratulation: true
        }
      };
    }
    case ACTION_TYPES.COMPLETE_MISSION_FAILED: {
      return {
        ...state,
        notify: {
          type: "COMPLETE_MISSION_FAILED",
          errorCode: action?.params?.code,
          message: action?.params?.message,
          isOpenDialog: true
        }
      };
    }
    case ACTION_TYPES.DELETE_CONGRATULATION: {
      return {
        ...state,
        congratulation: {
          type: "",
          congratulation: "",
          isOpenCongratulation: false
        }
      };
    }
    case ACTION_TYPES.DELETE_NOTIFY: {
      return {
        ...state,
        notify: {
          type: "",
          errorCode: "",
          message: "",
          isOpenDialog: false,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.CHOOSE_GRADE: {
      return {
        ...state,
        grade: action?.params,
        modalLogin: {
          isOpen: false,
          isRequired: false
        }
      };
    }
    case ACTION_TYPES.GET_SUBJECTS_SUCCESS: {
      return {
        ...state,
        arraySubject: action?.params?.subjects,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_SUBJECTS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.SAVE_ACCESS_TOKEN: {
      return {
        ...state,
        accessToken: action.params,
        action: {
          type: "SAVE_ACCESS_TOKEN"
        }
      };
    }
    case ACTION_TYPES.SAVE_ID_TOKEN: {
      return {
        ...state,
        idToken: action.params
      };
    }
    case ACTION_TYPES.SAVE_REFRESH_TOKEN: {
      return {
        ...state,
        refreshToken: action.params
      };
    }
    case ACTION_TYPES.SAVE_PROVIDER_ID: {
      return {
        ...state,
        providerId: action.params
      };
    }
    case ACTION_TYPES.SAVE_ADS_FIREBASE_REMOTE: {
      return {
        ...state,
        ad_web_banner_top_header: action.params
      };
    }
    case ACTION_TYPES.LOGIN_SUCCESS: {
      return {
        ...state,
        action: {
          type: "LOGIN_SUCCESS"
        },
        suggestedFriends: []
      };
    }
    case ACTION_TYPES.CLEAN_ACTION: {
      return {
        ...state,
        action: {
          type: ""
        }
      };
    }
    case ACTION_TYPES.GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        userNotifications: action.params
      };
    }
    case ACTION_TYPES.SHOW_DIRECT_APP: {
      return {
        ...state,
        showDirect: action?.params
      };
    }
    case ACTION_TYPES.SAVE_LOCATION_PERMISSION: {
      return {
        ...state,
        locationPermission: action?.params
      };
    }
    case ACTION_TYPES.UPDATE_LOCATION_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "UPDATE_LOCATION_SUCCESS",
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }
    case ACTION_TYPES.GET_SUGGESTED_FRIEND_SUCCESS: {
      return {
        ...state,
        suggestedFriends: action?.params?.items
      };
    }
    case ACTION_TYPES.GET_TOP: {
      return {
        ...state,
        isLoadingTop: true
      };
    }
    case ACTION_TYPES.SERVER_ERROR: {
      return {
        ...state,
        notify: {
          type: "SERVER_ERROR",
          errorCode: action?.params?.code,
          message: action?.params?.message,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.CLEAN_INVALID_TOKEN: {
      return {
        ...state,
        accessToken: "",
        userInfos: {},
        providerId: "",
        idToken: "",
        refreshToken: "",
        fireBaseInfos: {}
      };
    }
    case ACTION_TYPES.CHOOSE_BOOK: {
      return {
        ...state,
        bookId: action?.params
      };
    }
    case ACTION_TYPES.CHANGE_SUBJECT: {
      return {
        ...state,
        subject: action?.params
      };
    }
    case ACTION_TYPES.OPEN_MODAL_LOGIN: {
      return {
        ...state,
        modalLogin: { ...action?.params }
      };
    }
    case ACTION_TYPES.GET_LEARNING_HISTORY_SUCCESS: {
      return {
        ...state,
        learningHistory: action?.params
      };
    }
    case ACTION_TYPES.OPEN_MODAL_CONFIRM_LOGIN: {
      return {
        ...state,
        openModalConfirmLogin: action?.params
      };
    }
    case ACTION_TYPES.OPEN_MODAL_CONFIRM_PREMIUM: {
      return {
        ...state,
        openModalConfirmPremium: action?.params
      };
    }
    case ACTION_TYPES.GET_NEWS_SUCCESS: {
      return {
        ...state,
        listNews: action?.params?.posts || null
      };
    }
    case ACTION_TYPES.HANDLE_DIRECT_URL: {
      return {
        ...state,
        directUrl: action?.params
      };
    }
    case ACTION_TYPES.SAVE_AUTH_CODE: {
      return {
        ...state,
        authCode: action?.params
      };
    }
    case ACTION_TYPES.SAVE_CONGRATULATION: {
      return {
        ...state,
        congratulation: {
          congratulation: action?.params,
          isOpenCongratulation: !isEmpty(action?.params)
        }
      };
    }
    case ACTION_TYPES.SAVE_NOTIFY: {
      return {
        ...state,
        notify: action?.params
      };
    }
    case ACTION_TYPES.OPEN_MODAL_CHANGE_AVATAR: {
      return {
        ...state,
        openModalChangeImage: action?.params
      };
    }
    case ACTION_TYPES.CHANGE_TAB: {
      return {
        ...state,
        tab: action?.params
      };
    }
    case ACTION_TYPES.GET_TIME_SERVER_SUCCESS: {
      return {
        ...state,
        timeServer: action?.params || null,
        diffTimeSeconds: moment().diff(moment.unix(action?.params?.timestamp), "seconds")
      };
    }
    case ACTION_TYPES.SAVE_IS_UPDATE_INFO: {
      return {
        ...state,
        isUpdateInfo: action?.params
      };
    }
    case ACTION_TYPES.ADD_KEYWORD: {
      return {
        ...state,
        listKeywordSearched:
          action?.params !== "" && !state?.listKeywordSearched.includes(action?.params)
            ? [action?.params, ...state.listKeywordSearched]
            : [...state.listKeywordSearched]
      };
    }
    case ACTION_TYPES.DELETE_KEYWORD: {
      return {
        ...state,
        listKeywordSearched: state?.listKeywordSearched.filter((item) => item !== action?.params)
      };
    }
    case ACTION_TYPES.SET_IS_NEW_MEMBER: {
      return {
        ...state,
        isNewMember: action?.params
      };
    }
    case ACTION_TYPES.SET_IS_EMPTY_NAME: {
      return {
        ...state,
        isEmptyName: action?.params
      };
    }
    case ACTION_TYPES.SEARCH: {
      return {
        ...state,
        textSearch: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_LOGOUT: {
      return {
        ...state,
        fireBaseInfos: {},
        userInfos: {
          ...state?.userInfos,
          member: null
        },
        idToken: "",
        providerId: "",
        authCode: "",
        modalLogin: {
          isOpen: false,
          isRequired: false
        },
        notify: {
          type: "LOGOUT_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.ADD_USER_INFOS: {
      return {
        ...state,
        userInfos: action?.params,
        accessToken: action?.params?.token?.access_token,
        refreshToken: action?.params?.token?.ref_token,
        congratulation: {
          congratulation: action?.params?.congratulation || state.congratulation?.congratulation || {},
          isOpenCongratulation:
            Boolean(action?.params?.congratulation) || state.congratulation?.isOpenCongratulation || false
        },
        isNewMember: action?.params?.is_new_member || state.isNewMember || false,
        grade: action?.params?.grade?.slug || state?.grade
      };
    }
    case ACTION_TYPES.ADD_FIRE_BASE_INFOS: {
      return {
        ...state,
        fireBaseInfos: action?.params
      };
    }
    case "CLEAR_TOKEN": {
      return initialStateCommon;
    }
    case ACTION_TYPES.SAVE_IS_SHOW_DIALOG_ADS_IN_HOUSE_FIRST_TIME: {
      return {
        ...state,
        isShowAdsDialogFirstTime: action?.params
      };
    }
    case ACTION_TYPES.GET_ADS_IN_HOUSE_SUCCESS: {
      return {
        ...state,
        adsInHouses: action?.params?.items
      };
    }
    case ACTION_TYPES.CLEAR_ADS_IN_HOUSE: {
      return {
        ...state,
        adsInHouses: []
      };
    }
    case ACTION_TYPES.CALCULATE_DIFF_TIME_SECONDS: {
      return {
        ...state,
        diffTimeSeconds: moment().diff(moment.unix(action?.params), "seconds")
      };
    }
    case ACTION_TYPES.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        userInfos: { ...state.userInfos, member: action?.params },
        isUpdateInfo: false
      };
    }
    case ACTION_TYPES.GET_SURVEY_SUCCESS: {
      // gán index cho items survey cùng vị trí
      const newItems: any[] = [];
      action?.params?.items?.forEach((item) => {
        let itemSameProperty: any[] = [];
        if (item?.location_survey?.dialog) {
          itemSameProperty = newItems.filter(
            (e: any) => e.screen === item.screen && e.location_survey.dialog === item.location_survey.dialog
          );
        } else {
          itemSameProperty = newItems.filter(
            (e: any) => e.screen === item.screen && e.location_survey.native === item.location_survey.native
          );
        }

        if (itemSameProperty.length > 0) {
          newItems.push({ ...item, index: itemSameProperty.length + 1 });
        } else {
          newItems.push({ ...item, index: 1 });
        }
      });
      return {
        ...state,
        survey: { ...action.params, items: newItems }
      };
    }
    case ACTION_TYPES.SET_ROUTER_HISTORY: {
      return {
        ...state,
        routerHistory: action?.params
      };
    }
    case ACTION_TYPES.SUBMIT_SURVEY_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "SUBMIT_SURVEY_SUCCESS",
          message: action?.params?.success_message,
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }
    case ACTION_TYPES.SUBMIT_SURVEY_FAILED: {
      return {
        ...state,
        notify: {
          type: "SUBMIT_SURVEY_FAILED",
          errorCode: action?.params?.code,
          message: action?.params?.message,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.OPEN_DIALOG_ADS_IN_HOUSE: {
      return {
        ...state,
        openDialogAdsInHouse: action.params
      };
    }
    case ACTION_TYPES.HIDE_DIALOG_SURVEY: {
      return {
        ...state,
        hideDialogSurvey: action.params
      };
    }
    case ACTION_TYPES.SAVE_TIME_SHOW_SURVEY: {
      return {
        ...state,
        timeShowSurvey: { ...state.timeShowSurvey, ...action?.params }
      };
    }
    case ACTION_TYPES.GET_DAILY_REPORT_SUCCESS: {
      return {
        ...state,
        dailyReport: { ...state.dailyReport, ...action?.params }
      };
    }
    case ACTION_TYPES.CLOSE_DAILY_REPORT_DIALOG: {
      return {
        ...state,
        dailyReport: { ...state.dailyReport, is_displayed: false, data_report: undefined }
      };
    }
    case ACTION_TYPES.SET_IS_OPEN_TEACHER_ACCOUNT_INFO_DIALOG: {
      return {
        ...state,
        isOpenTeacherAccountInfoDialog: action.params
      };
    }
    case ACTION_TYPES.SET_IS_OPEN_TEACHER_BENEFITS_DIALOG: {
      return {
        ...state,
        isOpenTeacherBenefitsDialog: action.params
      };
    }
    case ACTION_TYPES.SET_OPEN_CLASSES_DIALOG: {
      return {
        ...state,
        openClassesDialog: action.params
      };
    }
    case ACTION_TYPES.SET_IS_OPEN_TEACHER_ACCOUNT_FORM_DIALOG: {
      return {
        ...state,
        isOpenTeacherAccountFormDialog: action.params
      };
    }
    case HYDRATE: {
      const serverState = action?.payload.commonNew;
      return {
        ...state,
        listTops: serverState.listTops,
        // topExtra: serverState.topExtra,
        listNews: !isEmpty(serverState.listNews) ? serverState.listNews : state?.listNews,
        // accessToken: !isEmpty(state?.accessToken) ? state?.accessToken : serverState.accessToken,
        accessToken: serverState.accessToken || null,
        grade: state?.grade,
        // timeServer: !isEmpty(serverState.timeServer.version) ? serverState.timeServer : state.timeServer,
        // diffTimeSeconds: serverState.diffTimeSeconds > 0 ? serverState.diffTimeSeconds : state.diffTimeSeconds,
        staticData: !isEmpty(
          state.staticData?.goals?.concat(state.staticData?.timeline)?.concat(state.staticData?.subject_types)
        )
          ? state.staticData
          : serverState?.staticData,
        learningHistory: !isEmpty(state.learningHistory) ? state.learningHistory : serverState.learningHistory,
        refreshToken: serverState.refreshToken || state.refreshToken,
        authCode: serverState.authCode || state?.authCode,
        arraySubject: state?.arraySubject,
        notify: !isEmpty(serverState.notify?.errorCode) ? serverState?.notify : state?.notify
      };
    }
    default:
      return state;
  }
};

// const persistConfig = {
//   key: "commonNew",
//   storage,
//   whitelist: [
//     "grade",
//     "accessToken",
//     "refreshToken",
//     "idToken",
//     "providerId",
//     "ad_web_banner_top_header",
//     "showDirect",
//     "locationPermission",
//     "listKeywordSearched",
//     "userInfos",
//     "fireBaseInfos",
//     "isShowAdsDialogFirstTime",
//     "diffTimeSeconds"
//   ]
// };

export default commonReducer;
