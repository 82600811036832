/* eslint-disable default-param-last */
import { HYDRATE } from "next-redux-wrapper";
import isEmpty from "lodash/isEmpty";
import { ACTION_TYPES } from "./actionTypes";
import { TypeInitSubjectReducer } from "../utils/type";

export const initialStateSubject: TypeInitSubjectReducer = {
  notify: {
    status: "error",
    message: "",
    errorCode: "",
    isOpenNotify: false,
    isOpenDialog: false
  },
  action: {
    type: ""
  },
  lesson: {
    items: [],
    root_tree_id: 0,
    type: "",
    view_type: ""
  },
  exams: {
    items: [],
    root_tree_id: 0,
    type: "",
    view_type: ""
  },
  practice: { items: [], root_tree_id: 0, type: "", view_type: "" },
  listSubject: [],
  isLoading: true,
  openModalEarnDiamond: false,
  subjectPageTitle: "",
  dataTopics: []
};
const initErrorNotify = (action: any) => {
  return {
    status: "error",
    message: action?.params?.message || null,
    errorCode: action?.params?.code || null,
    isOpenNotify: false,
    isOpenDialog: true
  };
};
const subjectReducer = (state = initialStateSubject, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_TOPIC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        lesson: action?.params.latest_data?.type === "lesson" ? action?.params.latest_data : [],
        dataTopics: action?.params?.topics,
        listSubject: action?.params?.package_topics?.items,
        subjectPageTitle: action?.params?.package_topics?.title,
        action: {
          type: "GET_LIST_TOPIC_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.GET_LIST_TOPIC_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action)
        },
        lesson: [],
        exams: [],
        practice: [],
        isLoading: false,
        action: {
          type: "GET_LIST_TOPIC_FAILED"
        }
      };
    }
    case ACTION_TYPES.GET_LIST_TOPIC: {
      return {
        ...state,
        isLoading: true,
        lesson: [],
        exams: [],
        practice: [],
        action: {
          type: ""
        }
      };
    }
    case ACTION_TYPES.GET_LIST_SUBJECTS: {
      return {
        ...state,
        isLoading: true,
        action: {
          type: ""
        },
        listSubject: [],
        subjectPageTitle: ""
      };
    }
    case ACTION_TYPES.GET_LIST_SUBJECTS_SUCCESS: {
      return {
        ...state,
        listSubject: action?.params?.items,
        subjectPageTitle: action?.params?.title,
        action: {
          type: "GET_LIST_SUBJECTS_SUCCESS"
        },
        notify: initialStateSubject.notify
      };
    }
    case ACTION_TYPES.GET_LIST_SUBJECTS_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action)
        }
      };
    }
    case ACTION_TYPES.CLEAN_NOTIFY: {
      return {
        ...state,
        notify: initialStateSubject.notify
      };
    }
    case ACTION_TYPES.HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND: {
      return {
        ...state,
        openModalEarnDiamond: action?.params
      };
    }
    case HYDRATE: {
      const serverState = action?.payload?.subject;
      return {
        ...state,
        listSubject: serverState?.listSubject,
        notify: { ...serverState?.notify } || { ...initialStateSubject?.notify },
        subjectPageTitle: serverState?.subjectPageTitle || null,
        lesson: state?.lesson || null,
        exams: state?.exams || null,
        practice: state?.practice || null,
        isLoading: serverState?.isLoading,
        action: !isEmpty(serverState?.action?.type) ? serverState?.action : state?.action
      };
    }
    default:
      return state;
  }
};

export default subjectReducer;
