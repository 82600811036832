import { HYDRATE } from "next-redux-wrapper";
import isEmpty from "lodash/isEmpty";
import { API_STATUS_ERROR, API_STATUS_SUCCESS } from "../utils/constants";
import { getUniqueItems, getUniqueRoom } from "../utils/helper";
import { TArenaState } from "../utils/types";
import { ACTION_TYPES } from "./actionTypes";

export const initialStateArena: TArenaState = {
  roomDetail: {
    room_hid: "",
    title: "",
    start_time: 0,
    end_time: 0,
    type: "",
    num_of_members: 0,
    password: "",
    max_slot: 0,
    subject_id: 0,
    joined: false,
    invitee_only: false,
    direct_link: "",
    time: 0,
    num_of_questions: 0,
    started_at: 0,
    submitted_at: 0,
    room_type: {
      type: "",
      title: ""
    },
    is_notification: false,
    is_created_by_admin: false,
    star: 0,
    activate_times_bonus_star: 0,
    top_bonus_star: 0,
    subject_title: "",
    edited_times: 0,
    delay_submit_time: 0,
    quiz_level: 0,
    fee_join: 0,
    is_locked: false
  },
  password: "",
  member: {
    member_hid: "",
    joined_type: "",
    fir_uid: "",
    is_bonus_star: false
  },
  highScores: [],
  memberList: [],
  notify: {
    isOpenNotify: false,
    isOpenDialog: false,
    status: "",
    message: "",
    type: "",
    errorCode: ""
  },
  memberId: "",
  roomList: [],
  subjects: [],
  isLoading: false,
  creationInfo: {},
  filterDate: "lifetime",
  subjectChooseArena: 0,
  listRanking: [],
  memberInRanking: {
    member_hid: "",
    fir_uid: "",
    ranking: 0,
    fame_point: 0,
    exp_point: 0,
    time_rate: 0
  },
  sortType: "",
  sortToSubjects: "",
  listRoomsHistory: {
    rooms: [],
    subjects: {
      id: 0,
      title: "",
      reference: ""
    },
    roomMember: {
      score: 0,
      ranking: 0
    }
  },
  answerTotal: [],
  analysisData: {
    progress: {
      finished_quiz: 0,
      correct_quiz: 0,
      ignored_quiz: 0,
      skipped_quiz: 0,
      spent_time: 0,
      time_rate: 0
    },
    items: []
  },
  isLoadingMember: false,
  quizInfoSolution: {},
  quizInfoStart: {},
  quizInfoSubmit: {},
  progress: {},
  action: {},
  latestData: {},
  isOpenModalCreatePost: false,
  memberHidAddToRoom: "",
  timeSever: {
    timestamp: 0,
    status: "",
    version: ""
  },
  openModalConfirmJoinRoom: false,
  listSubjectArena: [],
  openDialogGiftDiamond: false,
  listSubjectChooseFilter: [],
  giftItems: [],
  successMessage: {
    status: "",
    message: "",
    roomHid: ""
  },
  questionIdScroll: "",
  page: "phong-thi",
  tab: "waiting-room"
};

const initErrorNotify = (action) => {
  return {
    status: "error",
    message: action?.params?.message,
    errorCode: action?.params?.code,
    isOpenNotify: false,
    isOpenDialog: true
  };
};
// eslint-disable-next-line default-param-last
const arenaReducer = (state = initialStateArena, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_DETAIL_ROOM_SUCCESS: {
      return {
        ...state,
        roomDetail: action?.params?.room_info,
        member: action?.params?.member || null,
        highScores: action?.params?.high_scores || null,
        giftItems: action?.params?.gift_items || null,
        isLoading: false,
        action: {
          type: "GET_DETAIL_ROOM_SUCCESS"
        },
        notify: {
          type: "GET_DETAIL_ROOM_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.GET_DETAIL_ROOM_FAILED: {
      return {
        ...state,
        notify: {
          type: "GET_DETAIL_ROOM_FAILED",
          errorCode: action?.params?.code,
          message: action?.params?.message,
          isOpenDialog: true,
          isOpenNotify: false
        },
        action: {
          type: "GET_DETAIL_ROOM_FAILED"
        },
        roomDetail: initialStateArena.roomDetail,
        member: initialStateArena.member,
        highScores: initialStateArena.highScores,
        giftItems: initialStateArena.giftItems,
        isLoading: false
      };
    }
    case ACTION_TYPES.CLEAN_DETAIL_ROOM: {
      return {
        ...state,
        roomDetail: initialStateArena.roomDetail,
        member: initialStateArena.member,
        highScores: initialStateArena.highScores,
        giftItems: initialStateArena.giftItems
      };
    }
    case ACTION_TYPES.CHANGE_PASSWORD: {
      return {
        ...state,
        password: action?.params
      };
    }
    case ACTION_TYPES.GET_MEMBER_SUCCESS: {
      return {
        ...state,
        memberList: action?.params?.last_hid
          ? [...state.memberList, ...(action?.params?.members as any)]
          : action?.params?.members,
        isLoadingMember: false
      };
    }
    case ACTION_TYPES.LEAVE_ROOM_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "LEAVE_ROOM_SUCCESS",
          // isOpenNotify: true,
          message: action?.params,
          status: API_STATUS_SUCCESS
        }
      };
    }
    case ACTION_TYPES.JOIN_ROOM_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "JOIN_ROOM_SUCCESS",
          // isOpenNotify: true,
          message: action?.params.success_message,
          status: API_STATUS_SUCCESS,
          roomHid: action?.params?.room_hid
        }
      };
    }
    case ACTION_TYPES.CLOSE_NOTIFICATION: {
      return {
        ...state,
        notify: {
          type: "CLOSE_NOTIFYCATION",
          isOpenNotify: false,
          status: "",
          message: ""
        }
      };
    }
    case ACTION_TYPES.LEAVE_ROOM_FAILED: {
      return {
        ...state,
        notify: {
          status: API_STATUS_ERROR,
          type: "LEAVE_ROOM_FAILED",
          isOpenDialog: true,
          message: action?.params.message,
          errorCode: action?.params.code
        }
      };
    }
    case ACTION_TYPES.JOIN_ROOM_FAILED: {
      return {
        ...state,
        notify: {
          status: API_STATUS_ERROR,
          isOpenDialog: true,
          type: "JOIN_ROOM_FAILED",
          message: action.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.CHANGE_MEMBER_ID: {
      return {
        ...state,
        memberId: action?.params
      };
    }
    case ACTION_TYPES.ADD_MEMBER_SUCCESS: {
      return {
        ...state,
        notify: {
          status: API_STATUS_SUCCESS,
          // isOpenNotify: true,
          type: "ADD_MEMBER_SUCCESS",
          message: action?.params
        }
      };
    }
    case ACTION_TYPES.ADD_MEMBER_FAILED: {
      return {
        ...state,
        notify: {
          status: API_STATUS_ERROR,
          type: "ADD_MEMBER_FAILED",
          isOpenDialog: true,
          message: action?.params?.message,
          isOpenNotify: false,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.DELETE_NOTIFY: {
      return {
        ...state,
        notify: {
          isOpenNotify: false,
          type: "",
          status: "",
          message: "",
          isOpenDialog: false
        }
      };
    }
    case ACTION_TYPES.GET_CREATION_INFO_SUCCESS: {
      return {
        ...state,
        creationInfo: action?.params
      };
    }
    case ACTION_TYPES.UPDATE_ROOM_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "UPDATE_ROOM_SUCCESS",
          // isOpenNotify: true,
          status: "success",
          message: action?.params
        }
      };
    }
    case ACTION_TYPES.UPDATE_ROOM_FAILED: {
      return {
        ...state,
        notify: {
          type: "UPDATE_ROOM_FAILED",
          isOpenDialog: true,
          status: "error",
          message: action?.params.message,
          errorCode: action?.params.code
        }
      };
    }
    case ACTION_TYPES.DELETE_ROOM_FAILED: {
      return {
        ...state,
        notify: {
          type: "DELETE_ROOM_FAILED",
          isOpenDialog: true,
          status: "error",
          message: action?.params.message,
          errorCode: action?.params.code,
          isOpenNotify: false
        }
      };
    }
    case ACTION_TYPES.DELETE_ROOM_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "DELETE_ROOM_SUCCESS",
          isOpenNotify: true,
          isOpenDialog: false,
          status: "success",
          message: action?.params?.success_message
        }
      };
    }
    case ACTION_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        notify: {
          type: "",
          isOpenDialog: false
        }
      };
    }
    case ACTION_TYPES.DELETE_MEMBER_SUCCESS: {
      return {
        ...state,
        notify: {
          type: "DELETE_MEMBER_SUCCESS",
          // isOpenNotify: true,
          status: "success",
          message: action?.params,
          isOpenDialog: false
        }
      };
    }
    case ACTION_TYPES.GET_LIST_ROOM_SUCCESS: {
      return {
        ...state,
        roomList:
          action?.roomHid && state.roomList
            ? state.roomList.concat(getUniqueRoom(state.roomList, action?.params?.rooms))
            : action?.params?.rooms || null,
        subjects:
          action?.roomHid && state.subjects
            ? state.subjects.concat(getUniqueItems(state.subjects, action?.params?.subjects))
            : action?.params?.subjects || null,
        isLoading: false,
        notify: initialStateArena.notify
      };
    }
    case ACTION_TYPES.GET_LIST_ROOM: {
      return {
        ...state,
        isLoading: state.roomList?.length === 0
      };
    }
    case ACTION_TYPES.CREATE_ROOM_SUCCESS: {
      return {
        ...state,
        successMessage: {
          status: "CREATE_ROOM_SUCCESS",
          message: action?.params?.success_message,
          roomHid: action?.params?.room_hid
        },
        notify: {
          type: "CREATE_ROOM_SUCCESS",
          status: "success",
          message: action?.params?.success_message,
          // isOpenNotify: true,
          // isOpenDialog: false,
          roomHid: action?.params?.room_hid
        }
      };
    }
    case ACTION_TYPES.CREATE_ROOM_FAILED: {
      return {
        ...state,
        errorMessage: {
          status: "CREATE_ROOM_FAILED",
          message: action?.params
        },
        notify: {
          type: "CREATE_ROOM_FAILED",
          status: "error",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenNotify: false,
          isOpenDialog: true
        }
      };
    }
    case ACTION_TYPES.CHOOSE_DATE: {
      return {
        ...state,
        filterDate: action?.params
      };
    }
    case ACTION_TYPES.CHOOSE_SUBJECT_ARENA: {
      return {
        ...state,
        // Logic filter more Subject
        // subjectChoose: state.subjectChoose.includes(action?.params)
        //   ? state.subjectChoose.filter((item) => item !== action?.params)
        //   : [...state.subjectChoose, action.params],
        // Logic filter 1 subject
        subjectChooseArena: state.subjectChooseArena === action.params ? "" : action.params
      };
    }
    case ACTION_TYPES.GET_GOLD_BOARD_SUCCESS: {
      return {
        ...state,
        listRanking: action?.params?.rankings || null,
        memberInRanking: action?.params?.member || initialStateArena.memberInRanking,
        isLoading: false,
        action: {
          type: "GET_GOLD_BOARD_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.GET_GOLD_BOARD: {
      return {
        ...state,
        isLoading: true,
        action: {
          type: "GET_GOLD_BOARD"
        }
      };
    }
    case ACTION_TYPES.SORT_ROOM_HISTORY: {
      return {
        ...state,
        sortType: action?.params
      };
    }
    case ACTION_TYPES.SORT_TO_SUBJECT: {
      return {
        ...state,
        sortToSubjects: action?.params
      };
    }
    case ACTION_TYPES.GET_HISTORY_ROOM: {
      return {
        ...state,
        isLoading: true,
        action: {
          type: "GET_HISTORY_ROOM"
        }
      };
    }
    case ACTION_TYPES.GET_HISTORY_ROOM_SUCCESS: {
      return {
        ...state,
        listRoomsHistory: {
          rooms: action?.params?.room,
          roomMember: action?.params?.room_member,
          subjects: action?.params?.subject || null
        },
        isLoading: false,
        action: {
          type: "GET_HISTORY_ROOM_SUCCESS"
        }
      };
    }
    case ACTION_TYPES.GET_QUIZ_QUESTIONS: {
      return {
        ...state,
        action: {
          type: "GET_QUIZ_QUESTIONS"
        },
        progress: [],
        quizInfoSubmit: [],
        quizInfoSolution: [],
        isLoading: true,
        answerTotal: []
      };
    }
    case ACTION_TYPES.GET_QUIZ_QUESTIONS_SUCCESS: {
      return {
        ...state,
        latestData: action?.params?.latest_data || null,
        progress: action?.params?.progress || null,
        notify: {
          type: "GET_QUIZ_QUESTIONS_SUCCESS"
        },
        action: ""
      };
    }
    case ACTION_TYPES.GET_QUIZ_QUESTIONS_FAILED: {
      return {
        ...state,
        notify: {
          type: "GET_QUIZ_QUESTIONS_FAILED",
          status: "error",
          message: action?.params?.message || null,
          errorCode: action?.params?.code || null,
          isOpenNotify: false,
          isOpenDialog: true
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.POST_QUIZ_START: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.POST_QUIZ_START_SUCCESS: {
      return {
        ...state,
        quizInfoStart: action?.params,
        quizInfoSolution: null,
        action: {
          type: "POST_QUIZ_START_SUCCESS"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.POST_QUIZ_START_FAILED: {
      return {
        ...state,
        notify: {
          type: "POST_QUIZ_START_FAILED",
          status: "error",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenNotify: false,
          isOpenDialog: true
        },
        quizInfoStart: null,
        latestData: null,
        isLoading: false
      };
    }
    case ACTION_TYPES.PATCH_QUIZ_START_SUCCESS: {
      return {
        ...state,
        quizInfoStart: action?.params
      };
    }
    case ACTION_TYPES.PATCH_QUIZ_START_FAILED: {
      return {
        ...state,
        notify: {
          type: "PATCH_QUIZ_START_FAILED",
          status: "error",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenNotify: false,
          isOpenDialog: true
        }
      };
    }
    case ACTION_TYPES.SUBMIT_QUIZ: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.SUBMIT_QUIZ_SUCCESS: {
      return {
        ...state,
        quizSubmitInfo: action?.params,
        notify: {
          type: "SUBMIT_QUIZ_SUCCESS"
        },
        answerTotal: [],
        isLoading: false
      };
    }
    case ACTION_TYPES.SUBMIT_QUIZ_FAILED: {
      return {
        ...state,
        notify: {
          ...initErrorNotify(action),
          type: "SUBMIT_QUIZ_FAILED"
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_QUIZ_SOLUTIONS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_QUIZ_SOLUTIONS_SUCCESS: {
      return {
        ...state,
        quizInfoSolution: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_QUIZ_SOLUTIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        notify: {
          ...initErrorNotify(action),
          type: "GET_QUIZ_SOLUTIONS_FAILED"
        }
      };
    }
    case ACTION_TYPES.DELETE_QUIZ_INFO_SOLUTIONS: {
      return {
        ...state,
        quizInfoSolution: null
      };
    }
    case ACTION_TYPES.UPDATE_QUIZ_SOLUTION_ARENA: {
      return {
        ...state,
        quizInfoSolution: action?.params
      };
    }
    case ACTION_TYPES.GET_ANALYSIS: {
      return {
        ...state,
        isLoading: true,
        analysisData: null
      };
    }
    case ACTION_TYPES.GET_ANALYSIS_SUCCESS: {
      return {
        ...state,
        analysisData: action.params
      };
    }
    case ACTION_TYPES.GET_ANALYSIS_FAILED: {
      return {
        ...initErrorNotify(action),
        type: "GET_ANALYSIS_FAILED"
      };
    }
    case ACTION_TYPES.GET_DETAIL_ROOM: {
      return {
        ...state,
        isLoading: true,
        roomDetail: {},
        memberList: [],
        memberList2: [],
        action: {
          type: "GET_DETAIL_ROOM"
        }
      };
    }
    case ACTION_TYPES.DELETE_QUIZ_INFO_TOKEN: {
      return {
        ...state,
        latestData: "",
        progress: null
      };
    }
    case ACTION_TYPES.SAVE_ANSWER_TOTAL: {
      return {
        ...state,
        answerTotal: action.params || []
      };
    }
    case ACTION_TYPES.DELETE_MEMBER_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          isOpenNotify: false,
          type: "DELETE_MEMBER_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.GET_MEMBER: {
      return {
        ...state,
        isLoadingMember: state?.memberList?.length === 0
      };
    }
    case ACTION_TYPES.SHARE_ROOM_TO_ASK: {
      return {
        ...state,
        roomShareData: action.params
      };
    }
    case ACTION_TYPES.OPEN_MODAL_CREATE_POST: {
      return {
        ...state,
        isOpenModalCreatePost: !isEmpty(action?.params),
        memberHidAddToRoom: action?.params
      };
    }
    case ACTION_TYPES.OPEN_MODAL_CONFIRM_JOIN_ROOM: {
      return {
        ...state,
        openModalConfirmJoinRoom: action?.params
      };
    }
    case ACTION_TYPES.GET_LIST_SUBJECT_ARENA_SUCCESS: {
      return {
        ...state,
        listSubjectArena: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_OPEN_MODAL_GIFT_DIAMOND: {
      return {
        ...state,
        openDialogGiftDiamond: action?.params
      };
    }
    case ACTION_TYPES.GET_LIST_ROOM_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          isOpenNotify: false,
          type: "GET_LIST_ROOM_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code || null
        },
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_HISTORY_ROOM_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          isOpenNotify: false,
          type: "GET_HISTORY_ROOM_FAILED",
          message: action?.params?.message || null,
          errorCode: action?.params?.code || null
        }
      };
    }
    case ACTION_TYPES.GET_CREATION_INFO_FAILED: {
      return {
        ...state,
        notify: {
          isOpenDialog: true,
          isOpenNotify: false,
          type: "GET_CREATION_INFO_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code
        }
      };
    }
    case ACTION_TYPES.HANDLE_CHOOSE_SUBJECT_FILTER: {
      return {
        ...state,
        listSubjectChooseFilter: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_DELETE_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessage: {}
      };
    }
    case ACTION_TYPES.SAVE_QUESTION_ID_SCROLL: {
      return {
        ...state,
        questionIdScroll: action.params,
        notify: {
          type: "SAVE_QUESTION_ID_SCROLL"
        }
      };
    }
    case ACTION_TYPES.CLEAR_QUESTION_ID_SCROLL: {
      return {
        ...state,
        questionIdScroll: "",
        notify: {
          type: "CLEAR_QUESTION_ID_SCROLL"
        }
      };
    }
    case ACTION_TYPES.HANDLE_CHANGE_PAGE: {
      return {
        ...state,
        page: action?.params
      };
    }
    case ACTION_TYPES.HANDLE_CLEAR_PAGE: {
      return {
        ...state,
        page: "phong-thi"
      };
    }
    case ACTION_TYPES.HANDLE_CHANGE_TAB: {
      return {
        ...state,
        tab: action.params
      };
    }
    case ACTION_TYPES.SEARCH_ARENA_ROOM_SUCCESS: {
      return {
        ...state,
        roomList:
          action?.roomHid && state.roomList
            ? state.roomList.concat(getUniqueRoom(state.roomList, action?.params?.rooms))
            : action?.params?.rooms || null,
        subjects:
          action?.roomHid && state.subjects
            ? state.subjects.concat(getUniqueItems(state.subjects, action?.params?.subjects))
            : action?.params?.subjects || null,
        isLoading: false,
        notify: initialStateArena.notify
      };
    }
    case HYDRATE: {
      const serverState = action.payload.arenaRoom;
      return {
        ...state,
        roomList: serverState?.roomList || null,
        listRanking: !isEmpty(serverState?.listRanking) ? serverState?.listRanking : state?.listRanking,
        listSubjectArena: serverState?.listSubjectArena,
        listRoomsHistory: serverState?.listRoomsHistory || null,
        memberInRanking: serverState.memberInRanking || null,
        roomDetail: !isEmpty(serverState.roomDetail?.room_hid) ? serverState.roomDetail : state?.roomDetail,
        member: serverState.member || null,
        // memberList: serverState.memberList,
        highScores: serverState.highScores || null,
        giftItems: serverState.giftItems || null,
        analysisData: serverState.analysisData || null,
        latestData: serverState.latestData || null,
        progress: serverState.progress || null,
        subjects: serverState.subjects || null,
        notify: !isEmpty(serverState.notify?.type) ? serverState.notify : state?.notify
      };
    }
    default:
      return state;
  }
};
export default arenaReducer;
