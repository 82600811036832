import { TRoutes } from "./type";

export const ROUTES: TRoutes = {
  login: "login",
  home: {
    path: "/",
    index: "/"
  },
  subjects: {
    path: "/[grade]/[subjectsId]",
    index: "/[grade]/[subjectsId]"
  },
  friend: {
    path: "/ban-be",
    index: "/ban-be"
  },
  thongke: {
    path: "/thong-ke",
    index: "/thong-ke"
  },
  topics: {
    lythuyet: {
      path: "/[grade]/[subjectsId]/ly-thuyet/[topicId]",
      index: "/[grade]/[subjectsId]/ly-thuyet/[topicId]"
    },
    dethi: {
      path: "/[grade]/[subjectsId]/de-thi/[topicId]",
      index: "/[grade]/[subjectsId]/de-thi/[topicId]"
    },
    baitap: {
      path: "/[grade]/[subjectsId]/bai-tap/[topicId]",
      index: "/[grade]/[subjectsId]/bai-tap/[topicId]"
    }
  },
  chagrepoint: {
    path: "/kim-cuong",
    index: "/kim-cuong"
  },
  taikhoan: {
    path: "/tai-khoan",
    index: "/tai-khoan",
    edit: {
      path: "/tai-khoan/edit",
      index: "/tai-khoan/edit"
    },
    diamondhistory: {
      path: "/kim-cuong/lich-su-giao-dich",
      index: "/kim-cuong/lich-su-giao-dich"
    },
    baidalam: {
      path: "/tai-khoan/bai-da-lam",
      index: "/tai-khoan/bai-da-lam"
    },
    badgestotal: {
      path: "/tai-khoan/thanh-tich-dat-duoc",
      index: "/tai-khoan/thanh-tich-dat-duoc"
    },
    myGroups: {
      path: "/tai-khoan/lop-cua-toi",
      index: "/tai-khoan/lop-cua-toi",
      groupDetail: {
        path: "/tai-khoan/lop-cua-toi/[groupHid]",
        index: "/tai-khoan/lop-cua-toi/[groupHid]"
      }
    },
    chedoxem: {
      path: "/tai-khoan/[memberHid]",
      index: "/tai-khoan/[memberHid]"
    }
  },
  thionline: {
    path: "/[grade]/thi-online",
    index: "/[grade]/thi-online",
    phongdau: {
      path: "/[grade]/thi-online/phong-thi",
      index: "/[grade]/thi-online/phong-thi",
      create: {
        path: "/[grade]/thi-online/phong-thi/tao-phong",
        index: "/[grade]/thi-online/phong-thi/tao-phong"
      },
      edit: {
        path: "/[grade]/thi-online/phong-thi/[roomHid]/sua-phong",
        index: "/[grade]/thi-online/phong-thi/[roomHid]/sua-phong"
      },
      detailRoom: {
        path: "/[grade]/thi-online/phong-thi/[roomHid]",
        index: "/[grade]/thi-online/phong-thi/[roomHid]"
      },
      result: {
        path: "/[grade]/thi-online/phong-thi/[roomHid]/ket-qua/[memberHid]",
        index: "/[grade]/thi-online/phong-thi/[roomHid]/ket-qua/[memberHid]"
      },
      members: {
        path: "/[grade]/thi-online/phong-thi/[roomHid]/thanh-vien",
        index: "/[grade]/thi-online/phong-thi/[roomHid]/thanh-vien"
      },
      thidau: {
        path: "/[grade]/thi-online/phong-thi/[roomHid]/de-thi",
        index: "/[grade]/thi-online/phong-thi/[roomHid]/de-thi"
      },
      ketqua: {
        path: "/[grade]/thi-online/phong-thi/[roomHid]/ket-qua/[memberHid]",
        index: "/[grade]/thi-online/phong-thi/[roomHid]/ket-qua/[memberHid]"
      }
    }
  },
  hoidap: {
    path: "/hoi-dap?tab=thao-luan",
    index: "/hoi-dap",
    detail: {
      path: "/hoi-dap/[postId]",
      index: "/hoi-dap/[postId]"
    }
  },
  giftItem: {
    path: "/qua-tang",
    index: "/qua-tang",
    detail: {
      path: "/qua-tang/[giftId]",
      index: "/qua-tang/[giftId]"
    },
    quacuatoi: {
      path: "/qua-tang/qua-cua-toi",
      index: "/qua-tang/qua-cua-toi"
    }
  },
  tintuc: {
    path: "/tin-tuc",
    index: "/tin-tuc",
    detail: {
      path: "/tin-tuc/[newsId]",
      index: "/tin-tuc/[newsId]"
    }
  },
  kimcuong: {
    path: "/kim-cuong",
    index: "/kim-cuong",
    napkimcuong: {
      path: "/kim-cuong/nap-kim-cuong",
      index: "/kim-cuong/nap-kim-cuong"
    },
    nangcapvip: {
      path: "/kim-cuong/nang-cap-vip",
      index: "/kim-cuong/nang-cap-vip"
    },
    lichsugiaodich: {
      path: "/kim-cuong/lich-su-giao-dich",
      index: "/kim-cuong/lich-su-giao-dich"
    }
  },
  timkiem: {
    path: "/tim-kiem",
    index: "/tim-kiem"
  },
  course: {
    path: "/khoa-hoc",
    index: "/khoa-hoc"
  }
};

export const PUBLIC_LAYOUT = [
  {
    path: "chinh-sach-bao-mat",
    url: "chinh-sach-bao-mat",
    // element: <TemplateHtml htmlFile="privacy_policy.html" />,
    title: "Privacy Policy"
  },
  {
    path: "dieu-khoan-su-dung",
    url: "dieu-khoan-su-dung",
    // element: <TemplateHtml htmlFile="term_config.html" />,
    title: "Terms"
  },
  {
    path: "hinh-thuc-thanh-toan",
    url: "payments",
    // element: <TemplateHtml htmlFile="term_config.html" />,
    title: "Payments"
  },
  {
    path: "chinh-sach-doi-tra",
    url: "refund",
    // element: <TemplateHtml htmlFile="term_config.html" />,
    title: "refund"
  },
  {
    path: "phuong-thuc-giao-nhan",
    url: "phuong-thuc-giao-nhan",
    // element: <TemplateHtml htmlFile="privacy_policy.html" />,
    title: "Delivery method"
  },
  {
    path: "404",
    url: "404",
    // element: <TemplateHtml htmlFile="privacy_policy.html" />,
    title: "Not Found"
  }
];
