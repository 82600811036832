import api from "../../../utils/helpers/api";

const getListPost = (params) => {
  return api(
    {
      method: "get",
      url: `asks/posts`,
      params
    },
    "repeat"
  );
};
const getDetailPost = (params) => {
  return api({
    method: "get",
    url: `asks/post/${params}`,
    params
  });
};
const votePost = (data) => {
  return api({
    method: "post",
    url: `asks/vote/${data.object_id}`,
    data
  });
};
const savePost = (data) => {
  return api({
    method: "post",
    url: `asks/${data.post_id}/save`,
    data
  });
};
const getCreationInfo = () => {
  return api({
    method: "get",
    url: `/asks/post/creation-info`
  });
};
const getQARankings = (params) => {
  return api({
    method: "get",
    url: "asks/ranks",
    params
  });
};
const getCreationInfoSubscribe = (params) => {
  return api({
    method: "get",
    url: "notifications/topics",
    params
  });
};
const postSubscribeTopic = (data) => {
  return api({
    method: "post",
    url: "notifications/subscribe-topics",
    data
  });
};
const closePost = (data) => {
  return api({
    method: "post",
    url: `asks/${data?.post_id}/close`,
    data
  });
};
const deletePost = (data) => {
  return api({
    method: "delete",
    url: `asks/post/${data?.post_id}`,
    data
  });
};
const complainPost = (data) => {
  return api({
    method: "post",
    url: `asks/report/${data.object_id}`,
    data
  });
};
const createPost = (data) => {
  return api({
    method: "post",
    url: "asks/post",
    data
  });
};
const comment = (data, id) => {
  return api({
    method: "post",
    url: `asks/${id}/comment`,
    data
  });
};
const editPost = (data, postId) => {
  return api({
    method: "patch",
    url: `asks/post/${postId}`,
    data
  });
};
const deleteComment = (data) => {
  return api({
    method: "delete",
    url: `asks/comment/${data.comment_id}`,
    data
  });
};
const editComment = (data, id) => {
  return api({
    method: "patch",
    url: `asks/comment/${id}`,
    data
  });
};
const acceptComment = (data) => {
  return api({
    method: "post",
    url: `asks/${data?.post_id}/accept/${data.comment_id}`,
    data
  });
};
export const questionAndAnswerServices = {
  getListPost,
  getDetailPost,
  votePost,
  savePost,
  getCreationInfo,
  getQARankings,
  getCreationInfoSubscribe,
  postSubscribeTopic,
  closePost,
  deletePost,
  complainPost,
  createPost,
  comment,
  editPost,
  deleteComment,
  editComment,
  acceptComment
};
