/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery, takeLatest, debounce } from "redux-saga/effects";
import { toastSuccess } from "../../common/components/toastify";
import { getListRoom } from "../utils/arenaAPI";
import { NUMBER_DEBOUNCE } from "../utils/constants";
import { ACTION_TYPES } from "./actionTypes";
import { arenaRoomServices } from "./services";

function* handleGetDetailRoom(action) {
  try {
    const response = yield call(arenaRoomServices.getRoomByRoomHid, action?.params);
    if (response?.data?.data) {
      yield put({
        type: ACTION_TYPES.GET_DETAIL_ROOM_SUCCESS,
        params: response?.data?.data
      });
    }
    action.successCallback(response?.data?.data);
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_DETAIL_ROOM_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetMemberOfRoom(action) {
  try {
    const response = yield call(arenaRoomServices.getMember, action.params);
    if (response?.data) {
      yield put({
        type: ACTION_TYPES.GET_MEMBER_SUCCESS,
        params: { ...response?.data?.data, last_hid: action.params?.last_hid }
      });
    }
  } catch (err) {}
}
function* handleLeaveRoom(action) {
  try {
    const res = yield call(arenaRoomServices.leaveRoom, action.params);
    if (res?.data?.data?.success_message) {
      yield put({
        type: ACTION_TYPES.LEAVE_ROOM_SUCCESS,
        params: res?.data?.data?.success_message
      });
    }
    toastSuccess(res?.data?.data?.success_message);
  } catch (err) {
    yield put({
      type: ACTION_TYPES.LEAVE_ROOM_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleJoinRoom(action) {
  try {
    const res = yield call(arenaRoomServices.joinRoom, action.params);
    if (res?.data?.data?.success_message) {
      yield put({
        type: ACTION_TYPES.JOIN_ROOM_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (err) {
    action.errorCallback(err?.data?.error);
  }
}
function* handleAddMember(action) {
  try {
    const res = yield call(arenaRoomServices.addMember, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.ADD_MEMBER_SUCCESS,
        params: res?.data?.data?.success_message
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.ADD_MEMBER_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetListRoom(action) {
  try {
    const res = yield call(getListRoom, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_LIST_ROOM_SUCCESS,
        params: res?.data?.data,
        roomHid: action.params.last_hids
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_LIST_ROOM_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleCreateRoom(action) {
  try {
    const res = yield call(arenaRoomServices.createRoom, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.CREATE_ROOM_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.CREATE_ROOM_FAILED,
      params: error?.data?.error
    });
  }
}

function* handleGetCreationInfo(action) {
  try {
    const res = yield call(arenaRoomServices.getCreationInfo, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_CREATION_INFO_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_CREATION_INFO_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleUpdateRoom(action) {
  try {
    const res = yield call(arenaRoomServices.updateRoom, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.UPDATE_ROOM_SUCCESS,
        params: res?.data?.data?.success_message
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_ROOM_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleDeleteRoom(action) {
  try {
    const res = yield call(arenaRoomServices.deleteRoom, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.DELETE_ROOM_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.DELETE_ROOM_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleDeleteMember(action) {
  try {
    const res = yield call(arenaRoomServices.deleteMember, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.DELETE_MEMBER_SUCCESS,
        params: res?.data?.data?.success_message
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.DELETE_MEMBER_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetRankings(action) {
  try {
    const res = yield call(arenaRoomServices.getRanking, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_GOLD_BOARD_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* handleGetHistory(action) {
  try {
    const res = yield call(arenaRoomServices.getHistory, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_HISTORY_ROOM_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_HISTORY_ROOM_FAILED,
      params: err?.data?.error || err
    });
  }
}
function* handleGetQuizQuestions(action) {
  try {
    const res = yield call(arenaRoomServices.getQuizQuestions, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_QUIZ_QUESTIONS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.GET_QUIZ_QUESTIONS_FAILED,
      params: error?.data?.error
    });
  }
}
function* handlePostQuizStart(action) {
  try {
    const res = yield call(arenaRoomServices.postQuizStart, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.POST_QUIZ_START_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.POST_QUIZ_START_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleSubmitQuiz(action) {
  try {
    const res = yield call(arenaRoomServices.submitQuiz, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.SUBMIT_QUIZ_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.SUBMIT_QUIZ_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleGetQuizSolutions(action) {
  try {
    const res = yield call(arenaRoomServices.getQuizSolutions, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_QUIZ_SOLUTIONS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.GET_QUIZ_SOLUTIONS_FAILED,
      params: error?.data?.error
    });
  }
}
function* handlePatchQuizStart(action) {
  try {
    const res = yield call(arenaRoomServices.patchQuizStart, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.PATCH_QUIZ_START_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.PATCH_QUIZ_START_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleGetAnalysis(action) {
  try {
    const res = yield call(arenaRoomServices.getAnalysis, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_ANALYSIS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.GET_ANALYSIS_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleGetListSubjectArena(action) {
  try {
    const res = yield call(arenaRoomServices.getListSubject, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_LIST_SUBJECT_ARENA_SUCCESS,
        params: res?.data?.data?.subjects
      });
    }
  } catch (err) {}
}

function* handleSearchRoom(action) {
  try {
    const res = yield call(arenaRoomServices.searchArenaRoom, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.SEARCH_ARENA_ROOM_SUCCESS,
        params: res?.data?.data,
        roomHid: action.params.last_hids
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.SEARCH_ARENA_ROOM_FAILED,
      params: error?.data?.error
    });
  }
}

function* arenaSagas() {
  yield takeLatest(ACTION_TYPES.GET_DETAIL_ROOM, handleGetDetailRoom);
  yield takeEvery(ACTION_TYPES.GET_MEMBER, handleGetMemberOfRoom);
  yield takeEvery(ACTION_TYPES.LEAVE_ROOM, handleLeaveRoom);
  yield takeEvery(ACTION_TYPES.JOIN_ROOM, handleJoinRoom);
  yield takeEvery(ACTION_TYPES.ADD_MEMBER, handleAddMember);
  yield takeEvery(ACTION_TYPES.GET_CREATION_INFO, handleGetCreationInfo);
  yield takeEvery(ACTION_TYPES.UPDATE_ROOM, handleUpdateRoom);
  yield takeEvery(ACTION_TYPES.DELETE_ROOM, handleDeleteRoom);
  yield takeEvery(ACTION_TYPES.DELETE_MEMBER, handleDeleteMember);
  yield takeLatest(ACTION_TYPES.GET_LIST_ROOM, handleGetListRoom);
  yield takeLatest(ACTION_TYPES.CREATE_ROOM, handleCreateRoom);
  yield takeEvery(ACTION_TYPES.GET_GOLD_BOARD, handleGetRankings);
  yield takeEvery(ACTION_TYPES.GET_HISTORY_ROOM, handleGetHistory);
  yield debounce(NUMBER_DEBOUNCE, ACTION_TYPES.GET_QUIZ_QUESTIONS, handleGetQuizQuestions);
  yield takeEvery(ACTION_TYPES.POST_QUIZ_START, handlePostQuizStart);
  yield debounce(NUMBER_DEBOUNCE, ACTION_TYPES.GET_QUIZ_SOLUTIONS, handleGetQuizSolutions);
  yield takeLatest(ACTION_TYPES.SUBMIT_QUIZ, handleSubmitQuiz);
  yield takeEvery(ACTION_TYPES.PATCH_QUIZ_START, handlePatchQuizStart);
  yield takeEvery(ACTION_TYPES.GET_ANALYSIS, handleGetAnalysis);
  yield takeLatest(ACTION_TYPES.GET_LIST_SUBJECT_ARENA, handleGetListSubjectArena);
  yield takeLatest(ACTION_TYPES.SEARCH_ARENA_ROOM, handleSearchRoom);
}
export default arenaSagas;
