// import * as locales from "@mui/material/locale";
// import { createTheme } from "@mui/material/styles";
// import { LOCALES } from "../constants/constants";

export type TTheme = {
  components: any;
  overrides: any;
  palette: {
    primary: {
      main: string;
      green1: string;
      greenBland1: string;
      light: string;
      orangeGradient: string;
      greenGradient: string;
      gray: string;
      gray1: string;
      gray2: string;
      gray3: string;
      gray4: string;
      gray5: string;
      gray400: string;
      black1: string;
      background: string;
      red: string;
      yellow: string;
    };
    secondary: {
      main: string;
      light: string;
    };
    text: {
      primary: string;
      secondary: string;
    };
    success: {
      main: string;
    };
    info: {
      main: string;
    };
  };
  typography: any;
  allVariants: any;
  answerOptions: {
    correct: string;
    inCorrect: string;
    skipped: string;
    gray: string;
    black: string;
    purple: string;
  };
};

export const theme: TTheme = {
  components: {
    // MuiUseMediaQuery: {
    //   defaultProps: {
    //       noSsr: true,
    //   },
    // },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "white",
          borderColor: "#38B000"
        },
        "&:before": {
          borderColor: "#38B000"
        },
        "&:after": {
          borderColor: "#38B000"
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#38B000",
      green1: "#12B76A",
      greenBland1: "#E7F5E4",
      light: "#C7E6BC",
      orangeGradient: "linear-gradient(180deg, #FEDF3F 0%, #F7871D 100%)",
      greenGradient: "linear-gradient(180deg, #9EF01A 0%, #007200 100%)",
      gray: "#E5E5E5",
      gray1: "#9E9E9E",
      gray2: "#757575",
      gray3: "#9D9D9D",
      gray4: "#475467",
      gray5: "#e5e5e5",
      gray400: "#666666",
      black1: "#212121",
      background: "#F7F7F7",
      red: "#B00020",
      yellow: "#FEDF3F"
    },
    secondary: {
      main: "#FBB02D",
      light: "#FFFEE8"
    },
    text: {
      primary: "#000000",
      secondary: "#000000"
    },
    success: {
      main: "#5ABC3D"
    },
    info: {
      main: "#2F4D9C"
    }
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    h1: { fontSize: 96, lineHeight: "128px", fontWeight: "lighter" },
    h2: { fontSize: 60, lineHeight: "80px", fontWeight: "lighter" },
    h3: { fontSize: 48, lineHeight: "68px", fontWeight: "lighter" },
    h4: { fontSize: 36, lineHeight: "48px", fontWeight: "bold" },
    h5: { fontSize: 24, lineHeight: "34px", fontWeight: "bold" },
    h6: { fontSize: 20, lineHeight: "28px", fontWeight: "bold" },
    body1: { fontSize: 16, lineHeight: "25px", fontWeight: "normal" },
    body2: { fontSize: 14, lineHeight: "20px", fontWeight: "normal" },
    subtitle1: { fontSize: 15, lineHeight: "24px", fontWeight: "bold" },
    subtitle2: { fontSize: 14, lineHeight: "20px", fontWeight: "bold" },
    button: {
      fontSize: 16,
      textTransform: "none",
      lineHeight: "auto",
      fontWeight: "normal"
    },
    fontSize: 14,
    htmlFontSize: 15,
    caption: { fontSize: "12px", lineHeight: "18px" }
  },
  allVariants: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(",")
  },
  answerOptions: {
    correct: "#12B76A",
    inCorrect: "#B00020",
    skipped: "#F79009",
    gray: "#E5E5E5",
    black: "#212121",
    purple: "#9C00BD"
  }
};

// const Mobi

// export const THEME = createTheme(theme, locales[LOCALES]);
