/* eslint-disable default-param-last */
import { HYDRATE } from "next-redux-wrapper";
import { TChangePointState } from "../utils/types";
import { ACTION_TYPES } from "./actionTypes";

export const initialStateChargePoint: TChangePointState = {
  listMemberShips: [],
  notify: {
    isOpenNotify: false,
    isOpenDialog: false,
    message: "",
    type: ""
  },
  purchasesList: []
};

const changePointReducer = (state = initialStateChargePoint, action: any) => {
  switch (action?.type) {
    case ACTION_TYPES.GET_MEMBER_SHIP_SUCCESS: {
      return {
        ...state,
        listMemberShips: action.params?.items
      };
    }
    case ACTION_TYPES.GET_VIP_SUCCESS: {
      return {
        ...state,
        notify: {
          message: action?.params?.congratulation_message,
          type: "GET_VIP_SUCCESS",
          isOpenDialog: true,
          isOpenNotify: false,
          congratulation: action?.params?.congratulation
        }
      };
    }
    case ACTION_TYPES.GET_VIP_FAILED: {
      return {
        ...state,
        notify: {
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenDialog: true,
          description: action?.params?.description,
          type: "GET_VIP_FAILED"
        }
      };
    }
    case ACTION_TYPES.CLEAR_NOTIFY: {
      return {
        ...state,
        notify: {
          isOpenNotify: false,
          isOpenDialog: false,
          message: "",
          type: ""
        }
      };
    }
    case ACTION_TYPES.GET_PURCHASES_SUCCESS: {
      return {
        ...state,
        purchasesList: action?.params?.items
      };
    }
    case HYDRATE: {
      return {
        ...state,
        purchasesList: action?.payload?.changePoint?.purchasesList || null,
        listMemberShips: action?.payload?.changePoint?.listMemberShips || null
      };
    }
    default:
      return state;
  }
};
export default changePointReducer;
