/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";

const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const toastSuccess = (mess: string, handleClose?: Function, handleOpen?: Function, closeTime?: number) => {
  return toast.success(mess, {
    autoClose: closeTime,
    hideProgressBar: true,
    onClose: (props) => handleClose?.(),
    onOpen: (props) => handleOpen?.(),
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
    toastId: generateRandomString(5)
  });
};
