/* eslint-disable no-param-reassign */
import * as React from "react";
import { PixelCrop } from "react-image-crop";
import Lottie from "lottie-react";
import IconHome from "../../../../public/assets/icons/IconHome";
import IconLop1 from "../../../../public/assets/icons/IconLop1";
import IconLop2 from "../../../../public/assets/icons/IconLop2";
import IconLop3 from "../../../../public/assets/icons/IconLop3";
import IconLop4 from "../../../../public/assets/icons/IconLop4";
import IconLop5 from "../../../../public/assets/icons/IconLop5";
import IconLop6 from "../../../../public/assets/icons/IconLop6";
import IconLop7 from "../../../../public/assets/icons/IconLop7";
import IconLop8 from "../../../../public/assets/icons/IconLop8";
import IconLop9 from "../../../../public/assets/icons/IconLop9";
import IconLop10 from "../../../../public/assets/icons/IconLop10";
import IconLop11 from "../../../../public/assets/icons/IconLop11";
import IconLop12 from "../../../../public/assets/icons/IconLop12";
import IconOnThi10 from "../../../../public/assets/icons/IconOnThi10";
import IconOnThiTHPT from "../../../../public/assets/icons/IconOnThiTHPT";
import IconStatistic from "../../../../public/assets/icons/IconSatistic";
import IconExamsOnline from "../../../../public/assets/icons/IconExamsOnline";
import IconChatsCircle from "../../../../public/assets/icons/IconChatsCircle";
import IconExamsOnlineActive from "../../../../public/assets/icons/IconExamsOnlineActive";
import { TListMenu, TListClasses } from "./type";
import IconHomeActive from "../../../../public/assets/icons/IconHomeActive";
import IconChatsCircleActive from "../../../../public/assets/icons/IconChatsCircleActive";
import IconStatisticActive from "../../../../public/assets/icons/IconSatisticActive";
// import IconCourse from "../../../../public/assets/icons/IconCourse";
// import IconCourseActive from "../../../../public/assets/icons/IconCourseActive";
import DiamondIcon from "../../../../public/assets/jsons/DiamondIcon.json";

export const DRAWER_WIDTH = 300;

export const LIST_MENU: TListMenu = [
  {
    key: "common.bottombar.home",
    link: "/",
    id: "header_home",
    iconActive: <IconHomeActive />,
    icon: <IconHome />,
    iconMobile: <IconHome width="24" height="24" />,
    iconMobileActive: <IconHomeActive width="24" height="24" className="svgFillAll" fill="#38B000" />
  },
  {
    key: "Question_and_Answer",
    link: "/hoi-dap?tab=thao-luan",
    id: "header_ask",
    iconActive: <IconChatsCircleActive />,
    icon: <IconChatsCircle />,
    iconMobile: <IconChatsCircle width="24" height="24" />,
    iconMobileActive: <IconChatsCircleActive width="24" height="24" className="svgFillAll" fill="#38B000" />
  },
  {
    key: "common.bottombar.arena",
    link: "/lop/:grade/thi-online?tab=phong-thi",
    id: "header_arena",
    iconActive: <IconExamsOnlineActive />,
    icon: <IconExamsOnline />,
    iconMobile: <IconExamsOnline width="24" height="24" />,
    iconMobileActive: <IconExamsOnlineActive width="24" height="24" className="svgFillAll" fill="#38B000" />
  },

  {
    key: "common.bottombar.statistics",
    link: "/thong-ke",
    id: "header_statistics",
    iconActive: <IconStatisticActive />,
    icon: <IconStatistic />,
    iconMobile: <IconStatistic width="24" height="24" />,
    iconMobileActive: <IconStatisticActive />
  },

  // {
  //   key: "common.bottombar.course",
  //   link: "/khoa-hoc",
  //   id: "header_course",
  //   iconActive: <IconCourseActive />,
  //   icon: <IconCourse />,
  //   iconMobile: <IconCourse width={24} height={24} />,
  //   iconMobileActive: <IconCourseActive width={24} height={24} />
  // }

  {
    key: "common.bottombar.diamond",
    link: "/kim-cuong",
    id: "header_diamond",
    iconActive: <Lottie className="w-[32px] h-[32px]" animationData={DiamondIcon} loop />,
    icon: <Lottie className="w-[32px] h-[32px]" animationData={DiamondIcon} loop />,
    iconMobile: <Lottie className="w-[24px] h-[24px]" animationData={DiamondIcon} loop />,
    iconMobileActive: <Lottie className="w-[24px] h-[24px]" animationData={DiamondIcon} loop />
  }
];
export const SPECIAL_CLASSES: TListClasses = [
  {
    shortName: "OTĐH",
    name: "OT ĐH",
    icon: <IconOnThiTHPT />,
    value: 117,
    slug: "on-thi-dai-hoc"
  },
  {
    shortName: "OT10",
    name: "OT 10",
    icon: <IconOnThi10 />,
    value: 118,
    slug: "on-thi-vao-10"
  }
];
export const LIST_CLASSES: TListClasses = [
  {
    shortName: "12",
    name: "Lớp 12",
    icon: <IconLop12 />,
    value: 1,
    slug: "lop-12"
  },
  {
    shortName: "11",
    name: "Lớp 11",
    icon: <IconLop11 />,
    value: 2,
    slug: "lop-11"
  },
  {
    shortName: "10",
    name: "Lớp 10",
    icon: <IconLop10 />,
    value: 3,
    slug: "lop-10"
  },
  {
    shortName: "9",
    name: "Lớp 9",
    icon: <IconLop9 />,
    value: 4,
    slug: "lop-9"
  },
  {
    shortName: "8",
    name: "Lớp 8",
    icon: <IconLop8 />,
    value: 5,
    slug: "lop-8"
  },
  {
    shortName: "7",
    name: "Lớp 7",
    icon: <IconLop7 />,
    value: 6,
    slug: "lop-7"
  },
  {
    shortName: "6",
    name: "Lớp 6",
    icon: <IconLop6 />,
    value: 7,
    slug: "lop-6"
  },
  {
    shortName: "5",
    name: "Lớp 5",
    icon: <IconLop5 />,
    value: 8,
    slug: "lop-5"
  },
  {
    shortName: "4",
    name: "Lớp 4",
    icon: <IconLop4 />,
    value: 9,
    slug: "lop-4"
  },
  {
    shortName: "3",
    name: "Lớp 3",
    icon: <IconLop3 />,
    value: 10,
    slug: "lop-3"
  },
  {
    shortName: "2",
    name: "Lớp 2",
    icon: <IconLop2 />,
    value: 11,
    slug: "lop-2"
  },
  {
    shortName: "1",
    name: "Lớp 1",
    icon: <IconLop1 />,
    value: 12,
    slug: "lop-1"
  }
];

export const USER_CLASS = "USER_CLASS";

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

  ctx.restore();
}

export const LIST_FORMAT_URL_IMAGE = ["jpg", "jpeg", "png"];
export const LIST_ERROR_CODE_INVALID_TOKEN = ["ETKINI", "ETKINO"];
export const PATH_NAME = {
  homePage: "/",
  arenaQuizPage: "/[grade]/thi-online/phong-thi/[roomHid]/de-thi",
  examQuizPage: "/[grade]/[subjectsId]/de-thi/[topicId]",
  practiceQuizPage: "/[grade]/[subjectsId]/bai-tap/[topicId]"
};

export const LIST_PAGE_HIDE_BOTTOM_FLOATING_GOOGLE_ADS = [
  PATH_NAME.homePage,
  PATH_NAME.arenaQuizPage,
  PATH_NAME.examQuizPage,
  PATH_NAME.practiceQuizPage
];

export const CODE_NOT_ACCEPTABLE_REGISTERED_ACCOUNT_ASSOCIATE = "ENARAA";
export const CODE_NOT_ACCEPTABLE_REGISTERED_PHONE_NUMBER = "ENAPRA";
export const CODE_GROUP_ADD_MEMBER_STATUS_CLOSED = "ENAGSA";
export const CODE_NOT_FOUND_GROUP = "ENFGRO";
export const CODE_NOT_FOUND_ROOM = "ENFARO";

export const NUMBER_OF_CLASS_AVAILABLE = 7;
