import api from "../../../utils/helpers/api";

const getCandidateScore = (params) => {
  return api({
    method: "get",
    url: "/candidate/score",
    params
  });
};

export const searchScoreServices = {
  getCandidateScore
};
