import { ACTION_TYPES } from "./actionTypes";

const handleGetMemberShip = () => {
  return {
    type: ACTION_TYPES.GET_MEMBER_SHIP,
  };
};
const handleGetVip = (params: any) => {
  return {
    type: ACTION_TYPES.GET_VIP,
    params,
  };
};
const handleClearNotify = () => {
  return {
    type: ACTION_TYPES.CLEAR_NOTIFY,
  };
};
const handleGetPurchases = () => {
  return {
    type: ACTION_TYPES.GET_PURCHASES
  }
}
const handleGetPurchasesSuccess = (params) => {
  return {
    type: ACTION_TYPES.GET_PURCHASES_SUCCESS,
    params
  }
}

export const changePointAction = {
  handleGetMemberShip,
  handleGetVip,
  handleClearNotify,
  handleGetPurchases,
  handleGetPurchasesSuccess
};
