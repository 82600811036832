import { ACTION_TYPES } from "./actionTypes";

const handleGetListPost = (data: any) => {
  return {
    type: ACTION_TYPES.GET_LIST_POST,
    params: data
  };
};
const handleGetListPostSuccess = (data: any) => {
  return {
    type: ACTION_TYPES.GET_LIST_POST_SUCCESS,
    params: data
  };
};
const handleGetListPostFailed = (data: any) => {
  return {
    type: ACTION_TYPES.GET_LIST_POST_FAILED,
    params: data
  };
};
const handleGetDetailPost = (data: any) => {
  return {
    type: ACTION_TYPES.GET_DETAIL_POST,
    params: data
  };
};
const handleGetDetailPostSuccess = (data: any) => {
  return {
    type: ACTION_TYPES.GET_DETAIL_POST_SUCCESS,
    params: data
  };
};
const handleGetDetailPostFailed = (data: any) => {
  return {
    type: ACTION_TYPES.GET_DETAIL_POST_FAILED,
    params: data
  };
};
const handleChangeTab = (data: any) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_TAB,
    params: data
  };
};
const handleVote = (data: any) => {
  return {
    type: ACTION_TYPES.HANDLE_VOTE,
    params: data
  };
};
const handleVoteSuccess = (data: any) => {
  return {
    type: ACTION_TYPES.HANDLE_VOTE_SUCCESS,
    params: data
  };
};
const handleVoteFailed = (data: any) => {
  return {
    type: ACTION_TYPES.HANDLE_VOTE_FAILED,
    params: data
  };
};
const handleSavePost = (data: any) => {
  return {
    type: ACTION_TYPES.HANDLE_SAVE_POST,
    params: data
  };
};
const handleSavePostSuccess = (data: any) => {
  return {
    type: ACTION_TYPES.HANDLE_SAVE_POST_SUCCESS,
    params: data
  };
};
const handleSavePostFailed = (data: any) => {
  return {
    type: ACTION_TYPES.HANDLE_SAVE_POST_FAILED,
    params: data
  };
};
const handleGetCreationInfo = () => {
  return {
    type: ACTION_TYPES.GET_CREATION_INFO_POST
  };
};
const handleChoosePostType = (data: any) => {
  return {
    type: ACTION_TYPES.CHOOSE_POST_TYPE,
    params: data
  };
};
const handleGetCreationInfoSuccess = (data: any) => {
  return {
    type: ACTION_TYPES.GET_CREATION_INFO_POST_SUCCESS,
    params: data
  };
};
const handleGetCreationInfoFailed = (data: any) => {
  return {
    type: ACTION_TYPES.GET_CREATION_INFO_POST_FAILED,
    params: data
  };
};
const handleChooseGrade = (data: any) => {
  return {
    type: ACTION_TYPES.CHOOSE_GRADE_FILTER,
    params: data
  };
};
const handleChooseSubject = (data: any) => {
  return {
    type: ACTION_TYPES.CHOOSE_SUBJECT,
    params: data
  };
};
const handleUpdateDefaultValueListGradeChoose = (data: any) => {
  return {
    type: ACTION_TYPES.UPDATE_DEFAULT_VALUE_LIST_GRADE_CHOOSE,
    params: data
  };
};
const handleClearFilter = () => {
  return {
    type: ACTION_TYPES.CLEAR_FILTER
  };
};
const handleGetQARanking = (params: any) => {
  return {
    type: ACTION_TYPES.GET_QA_RANKINGS,
    params
  };
};
const handleSaveSubscribeSubjects = (params: any) => {
  return {
    type: ACTION_TYPES.SAVE_SUBSCRIBE_SUBJECTS,
    params
  };
};
const handleGetCreationInfoSubscribe = (params: any) => {
  return {
    type: ACTION_TYPES.GET_CREATION_INFO_SUBSCRIBE,
    params
  };
};
const handleSubscribeTopics = (params: any) => {
  return {
    type: ACTION_TYPES.POST_SUBSCRIBE_TOPIC,
    params
  };
};
const handleClosePost = (params, callback = () => {}) => {
  return {
    type: ACTION_TYPES.HANDLE_CLOSE_POST,
    params,
    callback
  };
};
const handleDeletePost = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_DELETE_POST,
    params
  };
};
const handleCloseNotify = () => {
  return {
    type: ACTION_TYPES.CLEAR_NOTIFICATION
  };
};
const handleComplain = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_COMPLAIN,
    params
  };
};
const handleCreatePost = (params) => {
  return {
    type: ACTION_TYPES.CREATE_POST,
    params
  };
};
const handleVoteComment = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_VOTE_COMMENT,
    params
  };
};
const handleComment = (params, id, callback = () => {}) => {
  return {
    type: ACTION_TYPES.HANDLE_COMMENT,
    params,
    id,
    callback
  };
};
const handleEditPost = (params, postId, callback = () => {}) => {
  return {
    type: ACTION_TYPES.EDIT_POST,
    params,
    postId,
    callback
  };
};
const handleDeleteComment = (params, callback = () => {}) => {
  return {
    type: ACTION_TYPES.DELETE_COMMENT,
    params,
    callback
  };
};
const handleEditComment = (params, id, callback = () => {}) => {
  return {
    type: ACTION_TYPES.EDIT_COMMENT,
    params,
    id,
    callback
  };
};
const handleCleanChooseFilter = () => {
  return {
    type: ACTION_TYPES.CLEAN_CHOOSE_FILTER
  };
};
const handleAcceptComment = (params, callback = () => {}) => {
  return {
    type: ACTION_TYPES.ACCEPT_COMMENT,
    params,
    callback
  };
};
const handleOpenModalEditPost = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_OPEN_MODAL_EDIT_POST,
    params
  };
};
const handleSaveOldFilter = () => {
  return {
    type: ACTION_TYPES.HANDLE_SAVE_OLD_FILTER
  };
};
const handleChooseParentSubjectId = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_CHOOSE_PARENT_SUBJECT_ID,
    params
  };
};
const handleOpenModalEarnDiamond = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND,
    params
  };
};
const handleOpenMenuAction = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_OPEN_MENU_ACTION,
    params
  };
};
const handleChangePage = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_PAGE,
    params
  };
};
const handleClearPage = () => {
  return {
    type: ACTION_TYPES.HANDLE_CLEAR_PAGE
  };
};
const handleChooseTime = (timeId) => {
  return {
    type: ACTION_TYPES.HANDLE_CHOOSE_TIME,
    params: timeId
  };
};
const handleClearOldFilter = () => {
  return {
    type: ACTION_TYPES.HANDLE_CLEAR_OLD_FILTER
  };
};
const handleUnSetCleanFilter = () => {
  return {
    type: ACTION_TYPES.UNSET_CLEAN_FILTER
  };
};
const handleSaveIsFilter = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_SAVE_IS_FILTER,
    params
  };
};
const handleCleanFilterWhenDirectPage = () => {
  return {
    type: ACTION_TYPES.CLEAN_FILTER_WHEN_DIRECT_PAGE
  };
};
export const questionAndAnswerAction = {
  handleGetListPost,
  handleGetListPostSuccess,
  handleGetListPostFailed,
  handleGetDetailPost,
  handleGetDetailPostSuccess,
  handleGetDetailPostFailed,
  handleChangeTab,
  handleVote,
  handleSavePost,
  handleVoteSuccess,
  handleVoteFailed,
  handleSavePostSuccess,
  handleSavePostFailed,
  handleGetCreationInfo,
  handleChoosePostType,
  handleGetCreationInfoSuccess,
  handleGetCreationInfoFailed,
  handleChooseGrade,
  handleChooseSubject,
  handleUpdateDefaultValueListGradeChoose,
  handleClearFilter,
  handleGetQARanking,
  handleSaveSubscribeSubjects,
  handleGetCreationInfoSubscribe,
  handleSubscribeTopics,
  handleClosePost,
  handleDeletePost,
  handleCloseNotify,
  handleComplain,
  handleCreatePost,
  handleEditPost,
  handleVoteComment,
  handleComment,
  handleDeleteComment,
  handleEditComment,
  handleAcceptComment,
  handleCleanChooseFilter,
  handleOpenModalEditPost,
  handleSaveOldFilter,
  handleChooseParentSubjectId,
  handleOpenModalEarnDiamond,
  handleOpenMenuAction,
  handleChangePage,
  handleClearPage,
  handleChooseTime,
  handleClearOldFilter,
  handleUnSetCleanFilter,
  handleSaveIsFilter,
  handleCleanFilterWhenDirectPage
};
