/* eslint-disable no-param-reassign */
import { isEmpty } from "lodash";
import { removeNullAttributeFromObject } from "../../common/utils/constant";
import { ANSWER_OPTIONS } from "./constants";

export const getUniqueItems = (array1, array2) => {
  const newArray: any[] = [];
  array2.forEach((element) => {
    if (!array1.find((ele) => ele.id === element.id)) {
      newArray.push(element);
    }
  });
  return newArray;
};

export const getUniqueRoom = (array1: any, array2: any) => {
  const newArray: any[] = [];
  array2.forEach((element: any) => {
    if (!array1.find((ele) => ele.room_hid === element.room_hid)) {
      newArray.push(element);
    }
  });
  return newArray;
};

export const transformQuestionSolutionToJsonRaw = (dataFromApi) => {
  return dataFromApi?.map((question) => {
    const correctOption = question.options.find((option) => option.correct);

    const solutions = question.solution.reduce((rs, current) => {
      if (current.content_type === "html") {
        rs.html = {
          ordinal: current.ordinal,
          content: current.content
        };
      } else if (current.content_type === "image") {
        rs.image = {
          ordinal: current.ordinal,
          content: current.content,
          file: {},
          previewUrl: current.content,
          uid: current.uid
        };
      }

      return rs;
    }, {});

    return removeNullAttributeFromObject({
      ordinal: question.ordinal,
      options: {
        answerKey: correctOption.answer_key,
        ordinal: correctOption.ordinal - 1
      },
      solutions: JSON.stringify(solutions) === "{}" ? null : solutions
    });
  });
};

export const transformJsonRawToQuestionSolution = (dataFromState) => {
  return dataFromState?.map((ele) => {
    const cleanSolution = removeNullAttributeFromObject(ele?.solutions || {}) as object;
    return {
      ordinal: ele.ordinal,
      answer_type: 0,
      options: ANSWER_OPTIONS.map((op, ix) => {
        return {
          answer_key: op,
          ordinal: ix + 1,
          correct: ele?.options?.answerKey === op ? 1 : 0
        };
      }),
      solutions: !isEmpty(cleanSolution)
        ? Object.keys(cleanSolution)
            ?.filter((el) => !!cleanSolution[el]?.content)
            .map((e: any) => {
              return { ...cleanSolution[e], content_type: e };
            })
        : []
    };
  });
};
