import apiAdsInHouse from "../../../utils/helpers/apiAdsInHouse";

const viewCourseDetail = (payload) => {
  return apiAdsInHouse({
    method: "post",
    url: `course/${payload.activityUid}/action`,
    data: payload.dataViewCourseDetail
  });
};

export const courseServices = {
  viewCourseDetail
};
