import { put, takeEvery, call, takeLatest, debounce } from "redux-saga/effects";
import { toastSuccess } from "../../common/components/toastify";
import ACTION_TYPES from "./actionType";
import { ACTION_TYPES as COMMON_ACTION_TYPES } from "../../common/redux/actionTypes";
import { accountServices } from "./services";

function* handleGetAchievements(action) {
  try {
    const response = yield call(accountServices.getAchievements, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_ACHIEVEMENTS_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_ACHIEVEMENTS_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetInfoAccount() {
  try {
    const res = yield call(accountServices.getInformationAccount);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_INFO_ACCOUNT_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* handleGetdiamondHistory(action) {
  try {
    const res = yield call(accountServices.getDiamondHistory, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_DIAMOND_HISTORY_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* handleUpdateInfoAccount(action) {
  try {
    const res = yield call(accountServices.updateProfile, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.UPDATE_INFO_ACCOUNT_SUCCESS,
        params: res?.data?.data
      });
    }
    toastSuccess(res?.data?.data?.success_message);
    action?.callback();
    if (res?.data?.data?.congratulation) {
      yield put({
        type: COMMON_ACTION_TYPES.SAVE_CONGRATULATION,
        params: res?.data?.data?.congratulation
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.UPDATE_INFO_ACCOUNT_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetExamHistory() {
  try {
    const res = yield call(accountServices.getExamHistory);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_EXAM_HISTORY_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_EXAM_HISTORY_FAILED,
      params: err?.data?.error
    });
  }
}
function* handlePostGiftCode(action) {
  try {
    const res = yield call(accountServices.postGiftcode, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.POST_GIFT_CODE_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.POST_GIFT_CODE_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetListGiftCode() {
  try {
    const res = yield call(accountServices.getGiftcode);

    if (res) {
      yield put({
        type: ACTION_TYPES.GET_LIST_GIFT_CODE_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_LIST_GIFT_CODE_FAILED,
      params: err?.data?.err
    });
  }
}
function* handleGetListMissions() {
  try {
    const res = yield call(accountServices.getMissions);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_LIST_MISSIONS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* handlePostMissions(action) {
  try {
    const res = yield call(accountServices.postMissionCompleted, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.POST_MISSIONS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.POST_MISSIONS_FAILED,
      params: err?.data?.error
    });
  }
}
function* handlePostRefCode(action) {
  try {
    const res = yield call(accountServices.postRefcode, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.POST_REF_CODE_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.POST_REF_CODE_FAILED,
      params: err?.data?.error
    });
  }
}
function* handlePostConfigNotifications(action) {
  try {
    const res = yield call(accountServices.postNotificationConfig, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.POST_CONFIG_NOTIFICATIONS_SUCCESS,
        params: res?.data?.data
      });
    }
    toastSuccess(res?.data?.data?.success_message);
  } catch (err) {
    yield put({
      type: ACTION_TYPES.POST_CONFIG_NOTIFICATIONS_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetGoals(action) {
  try {
    const res = yield call(accountServices.getGoals, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_GOALS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* handlePostGoals(action) {
  try {
    const res = yield call(accountServices.postGoals, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.POST_GOALS_SUCCESS,
        params: res?.data?.data
      });
    }
    toastSuccess(res?.data?.data?.success_message);
    if (res?.data?.data?.congratulation) {
      yield put({
        type: COMMON_ACTION_TYPES.SAVE_CONGRATULATION,
        params: res?.data?.data?.congratulation
      });
      yield put({
        type: ACTION_TYPES.GET_LIST_MISSIONS
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.POST_GOALS_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetDetailAccount(action) {
  try {
    const res = yield call(accountServices.getInfo, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_DETAIL_ACCOUNT_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_DETAIL_ACCOUNT_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetExtraInfo(action) {
  try {
    const res = yield call(accountServices.getUserExtraInfo, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_EXTRA_INFO_SUCCESS,
        params: res?.data?.data,
        memberHid: action?.params
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_EXTRA_INFO_FAILED,
      params: err.data?.error
    });
  }
}
function* handleGiftDiamond(action) {
  try {
    const res = yield call(accountServices.giftDiamond, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GIFT_DIAMOND_SUCCESS,
        params: res?.data?.data
      });
    }
    toastSuccess(res?.data?.data?.success_message);
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GIFT_DIAMOND_FAILED,
      params: err?.data?.error
    });
  }
}

function* handleCreateTeacherInfo(action) {
  try {
    const res = yield call(accountServices.createTeacherInfo, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.CREATE_TEACHER_INFO_SUCCESS,
        params: res?.data?.data
      });
    }
    toastSuccess(res?.data?.data?.success_message);
    action.callback();
  } catch (err) {
    yield put({
      type: ACTION_TYPES.CREATE_TEACHER_INFO_FAILED,
      params: err?.data?.error
    });
  }
}

function* handleGetTeacherInfo(action) {
  try {
    const res = yield call(accountServices.getTeacherInfo);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_TEACHER_INFO_SUCCESS,
        params: res?.data?.data
      });
      action.successCallback();
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_TEACHER_INFO_FAILED,
      params: err?.data?.error
    });
    action.errorCallback();
  }
}

function* handleUpdateGroup(action) {
  try {
    const response = yield call(accountServices.updateGroup, action.params);
    if (response) {
      toastSuccess(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.UPDATE_GROUP_FAILED,
      params: error?.data?.error
    });
  }
}

function* handleGetGroupOfTeacher() {
  try {
    const response = yield call(accountServices.getGroupOfTeacher);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_GROUP_OF_TEACHER_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (error: any) {
    yield put({
      type: ACTION_TYPES.GET_GROUP_OF_TEACHER_FAILED,
      params: error?.data?.error
    });
  }
}

function* handleJoinGroup(action) {
  try {
    const response = yield call(accountServices.joinGroup, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.JOIN_GROUP_SUCCESS,
        params: response?.data?.data
      });
      toastSuccess(response?.data?.data?.success_message);
      action.successCallback();
    }
  } catch (error: any) {
    action.errorCallback(error?.data?.error);
  }
}

function* accountSagas() {
  yield takeEvery(ACTION_TYPES.GET_ACHIEVEMENTS, handleGetAchievements);
  yield takeLatest(ACTION_TYPES.GET_INFO_ACCOUNT, handleGetInfoAccount);
  yield takeEvery(ACTION_TYPES.GET_DIAMOND_HISTORY, handleGetdiamondHistory);
  yield takeEvery(ACTION_TYPES.UPDATE_INFO_ACCOUNT, handleUpdateInfoAccount);
  yield takeEvery(ACTION_TYPES.GET_EXAM_HISTORY, handleGetExamHistory);
  yield takeEvery(ACTION_TYPES.POST_GIFT_CODE, handlePostGiftCode);
  yield takeEvery(ACTION_TYPES.GET_LIST_GIFT_CODE, handleGetListGiftCode);
  yield takeEvery(ACTION_TYPES.GET_LIST_MISSIONS, handleGetListMissions);
  yield takeEvery(ACTION_TYPES.POST_MISSIONS, handlePostMissions);
  yield takeEvery(ACTION_TYPES.POST_REF_CODE, handlePostRefCode);
  yield takeEvery(ACTION_TYPES.POST_CONFIG_NOTIFICATIONS, handlePostConfigNotifications);
  yield takeEvery(ACTION_TYPES.GET_GOALS, handleGetGoals);
  yield takeEvery(ACTION_TYPES.POST_GOALS, handlePostGoals);
  yield takeEvery(ACTION_TYPES.GET_DETAIL_ACCOUNT, handleGetDetailAccount);
  yield debounce(300, ACTION_TYPES.GET_EXTRA_INFO, handleGetExtraInfo);
  yield takeLatest(ACTION_TYPES.GIFT_DIAMOND, handleGiftDiamond);
  yield takeLatest(ACTION_TYPES.CREATE_TEACHER_INFO, handleCreateTeacherInfo);
  yield takeLatest(ACTION_TYPES.GET_TEACHER_INFO, handleGetTeacherInfo);
  yield takeEvery(ACTION_TYPES.UPDATE_GROUP, handleUpdateGroup);
  yield takeLatest(ACTION_TYPES.GET_GROUP_OF_TEACHER, handleGetGroupOfTeacher);
  yield takeLatest(ACTION_TYPES.JOIN_GROUP, handleJoinGroup);
}
export default accountSagas;
