import React from "react";
/* eslint-disable react/require-default-props */
type TypeIconHomeActive = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};
export default function IconHomeActive({
  className,
  fill,
  width,
  height
}: TypeIconHomeActive) {
  return (
    <svg
      className={className}
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.719 9.09358L19.039 3.72024C16.9457 2.25358 13.7323 2.33358 11.719 3.89358L5.03901 9.10691C3.70568 10.1469 2.65234 12.2802 2.65234 13.9602V23.1602C2.65234 26.5602 5.41234 29.3336 8.81234 29.3336H23.1857C26.5857 29.3336 29.3457 26.5736 29.3457 23.1736V14.1336C29.3457 12.3336 28.1857 10.1202 26.719 9.09358ZM16.999 24.0002C16.999 24.5469 16.5457 25.0002 15.999 25.0002C15.4523 25.0002 14.999 24.5469 14.999 24.0002V20.0002C14.999 19.4536 15.4523 19.0002 15.999 19.0002C16.5457 19.0002 16.999 19.4536 16.999 20.0002V24.0002Z"
        fill="#38B000"
      />
    </svg>
  );
}
