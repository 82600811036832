/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-else-return */

import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { Store } from "@reduxjs/toolkit";
import createSagaMiddleware, { Task } from "redux-saga";
import { createLogger } from "redux-logger";
import { getPersistConfig } from "redux-deep-persist";
import { createWrapper } from "next-redux-wrapper";
import { nodeEnv } from "../components/utils/constants/env";
import { rootReducers } from "./reducers";
import rootSaga from "./sagas";

const { composeWithDevTools } = require("redux-devtools-extension");

export interface SagaStore extends Store {
  sagaTask?: Task;
  __persistor?: any;
}
const getEnhancer = () => {
  const sagaMiddleware = createSagaMiddleware();
  const logger = createLogger();
  const middleware = nodeEnv === "development" ? [sagaMiddleware, logger] : [sagaMiddleware];
  const enhancer =
    nodeEnv !== "production" ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware);

  return { enhancer, sagaMiddleware };
};

const makeStore = () => {
  const isServer = typeof window === "undefined";
  const { enhancer, sagaMiddleware } = getEnhancer();

  if (isServer) {
    const store: any = createStore(rootReducers, enhancer);
    store.sagaTask = sagaMiddleware.run(rootSaga);
    return store;
  } else {
    // we need it only on client side
    const { persistStore, persistReducer } = require("redux-persist");
    const storage = require("redux-persist/lib/storage").default;
    const persistConfig = getPersistConfig({
      key: "root",
      storage,
      whitelist: [
        "system",
        "commonNew.grade",
        "commonNew.idToken",
        "commonNew.userInfos",
        "commonNew.accessToken",
        "commonNew.refreshToken",
        "commonNew.providerId",
        "commonNew.ad_web_banner_top_header",
        "commonNew.locationPermission",
        "commonNew.showDirect",
        // "commonNew.listGrades",
        "commonNew.listKeywordSearched",
        "commonNew.fireBaseInfos",
        "commonNew.isShowAdsDialogFirstTime",
        "commonNew.diffTimeSeconds",
        "commonNew.arraySubject",
        "commonNew.timeShowSurvey",
        "questionAndAnswer.listPostType",
        "questionAndAnswer.listSubjectAndGrade",
        "questionAndAnswer.listGradeChoose",
        "questionAndAnswer.listSubjectChoose",
        "questionAndAnswer.timeIdChoose",
        "questionAndAnswer.listPostTypeOld",
        "questionAndAnswer.listGradeChooseOld",
        "questionAndAnswer.listSubjectChooseOld",
        "questionAndAnswer.timeIdOld",
        "questionAndAnswer.isCleanFilter",
      ],
      rootReducer: rootReducers
    });
    const store: any = createStore(persistReducer(persistConfig, rootReducers), enhancer);
    store.__persistor = persistStore(store); // Nasty hack
    (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);
    return store;
  }
};
export const store: any = makeStore();
export const wrapper = createWrapper(makeStore, { debug: nodeEnv !== "production" });
export type AppStore = ReturnType<typeof makeStore>;
